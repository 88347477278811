import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTitle,
  startLoading,
  failLoading,
  createErrorObject,
  endLoading,
} from 'modules/commonModule';
import ContentsArea from 'components/Admin/common/ContentsArea';
import { Box, Grid } from '@material-ui/core';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import ActionButton from 'components/Admin/common/ActionButton';
import { UserInfoDto } from 'api/users/users.dto';
import { getUsers } from 'api/users/users.api';
import { workProcesses } from 'app/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RootState } from 'app/rootReducer';
import { getUserInfoList } from 'modules/mastersModule';
import CustomTextField from '../common/CustomTextField';
import UsersTable from './UsersTable';
import CustomToggleButtons from '../common/CustomToggleButtons';

// ToggleButton用 データリスト
const workProcesDataList = [
  {
    value: WorkProcess.Inspection,
    buttonLabel: workProcesses[WorkProcess.Inspection].title,
    dataCy: 'inspection-button',
  },
  {
    value: WorkProcess.Repair,
    buttonLabel: workProcesses[WorkProcess.Repair].title,
    dataCy: 'repair-button',
  },
  {
    value: WorkProcess.StainRemoval,
    buttonLabel: workProcesses[WorkProcess.StainRemoval].title,
    dataCy: 'stainRemoval-button',
  },
  {
    value: WorkProcess.Confirmation,
    buttonLabel: workProcesses[WorkProcess.Confirmation].title,
    dataCy: 'confirmation-button',
  },
  {
    value: WorkProcess.Admin,
    buttonLabel: workProcesses[WorkProcess.Admin].title,
    dataCy: 'admin-button',
  },
];

type RegisterUserForm = {
  /** 作業者ID */
  userId?: string;
  /** 作業者名 */
  selectedUser: UserInfoDto | null;
  /** 担当工程 */
  workProcess?: WorkProcess[];
};

const RegisterUserPage: FC<{}> = () => {
  const dispatch = useDispatch();
  const { userInfoList } = useSelector((state: RootState) => state.masters);
  const [userInfoListDto, setUserInfoListDto] = useState<UserInfoDto[]>([]);
  const [conditions, setConditions] = useState<RegisterUserForm>({
    selectedUser: null,
  });

  dispatch(setTitle('作業者(アプリユーザー)登録･変更･削除'));

  /* 検索フォーム操作系
  -------------------------------------------------------------- */
  /**
   * 検索条件（テキストフォーム）の選択値をstateにセット
   */
  const handleInput = useCallback(
    (name: string, value: string) => {
      setConditions({ ...conditions, [name]: value });
    },
    [conditions],
  );

  const handleInputUser = useCallback(
    (selectedUser: UserInfoDto | null) => {
      setConditions({ ...conditions, selectedUser });
    },
    [conditions],
  );

  /**
   * 検索条件（ボタンフォーム）の選択値をstateにセット */
  const handleWorkProcess = useCallback(
    (workProcess: WorkProcess[]) => {
      setConditions({ ...conditions, workProcess });
    },
    [conditions],
  );

  /**
   * 検索条件リセット
   */
  const conditionReset = useCallback(() => {
    setConditions({ selectedUser: null });
  }, []);

  /**
   * 作業者情報検索
   */
  const userSearch = useCallback(
    (userConditions?: RegisterUserForm) => {
      (async () => {
        try {
          dispatch(startLoading());

          const userList = await getUsers({
            userId: userConditions?.userId,
            userName: userConditions?.selectedUser?.userName,
            workProcess: userConditions?.workProcess,
          });

          setUserInfoListDto(userList);

          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('作業者情報を取得できませんでした。', e),
            ),
          );
        }
      })();
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getUserInfoList());
  }, [dispatch]);

  // 初回レンダリング時、作業者情報検索を実行
  useEffect(() => userSearch(), [userSearch]);

  return (
    <>
      <ContentsArea>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Box
              p={3}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={1}
              height="100%"
            >
              <Grid container spacing={3}>
                <Grid item xs={6} lg={4}>
                  <CustomTextField
                    name="userId"
                    label="作業者ID"
                    onChange={handleInput}
                    value={conditions.userId || ''}
                    inputProps={{
                      'data-cy': 'userId-input',
                    }}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Autocomplete
                    value={conditions.selectedUser}
                    options={userInfoList}
                    getOptionLabel={option => option.userName}
                    getOptionSelected={(option, value) => option === value}
                    size="small"
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      selectedValue: UserInfoDto | null,
                    ) => {
                      handleInputUser(selectedValue);
                    }}
                    renderInput={params => (
                      <CustomTextField
                        {...params}
                        label="作業者名"
                        name="userName"
                      />
                    )}
                    data-cy="userName-autocomplete"
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <CustomToggleButtons
                    label="担当工程"
                    dataList={workProcesDataList}
                    onChange={handleWorkProcess}
                    value={conditions.workProcess || []}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Box pr={2} mt={3}>
                    <ActionButton
                      color="delete"
                      data-cy="clear-button"
                      fullWidth
                      size="small"
                      onClick={conditionReset}
                    >
                      <Box component="span" width="100px">
                        条件クリア
                      </Box>
                    </ActionButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3}>
                    <ActionButton
                      color="primary"
                      data-cy="search-button"
                      fullWidth
                      size="small"
                      onClick={() => userSearch(conditions)}
                    >
                      <Box component="span" width="100px">
                        検索
                      </Box>
                    </ActionButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              p={2}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={1}
              height="100%"
            >
              <UsersTable
                userInfoList={userInfoListDto}
                userSearch={() => userSearch(conditions)}
              />
            </Box>
          </Grid>
        </Grid>
      </ContentsArea>
    </>
  );
};

export default RegisterUserPage;
