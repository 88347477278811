import React, { FC, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Theme,
  TextField,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setTitle,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import { grey } from '@material-ui/core/colors';
import { workTypes } from 'app/constants';
import { WorkType, WorkListDto, WorkSide } from 'api/works/works.dto';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getWorksByClothingId } from 'api/works/works.api';
import WorkDetail from 'components/common/WorkDetail';
import { getClothing } from 'api/clothings/clothings.api';
import { ClothingDto } from 'api/clothings/clothings.dto';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import OrderNoField from 'components/common/OrderNoField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: '50%',
      marginBottom: theme.spacing(3),
    },
    toggleGroup: {
      width: '100%',
    },
    item: {
      width: '20%',
      minWidth: '165px',
    },
    mediamedia: {
      height: 0,
      paddingTop: '100%',
      backgroundSize: '100% auto',
    },
    workListArea: {
      overflowY: 'scroll',
      paddingTop: '5px',
      paddingLeft: '5px',
      maxHeight: 'calc(48vh)',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
);

type LocationState = {
  workProcess: WorkProcess;
  orderId?: string;
  clothingId: string;
  workType: WorkType;
};

const WorkListPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation<LocationState>();
  const { workProcess, orderId, clothingId, workType } = location.state;
  const [clothingDto, setClothingDto] = useState<ClothingDto>();
  const [workListDto, setWorkListDto] = useState<WorkListDto[]>([]);

  const pageTitle = `作業内容明細 - ${workTypes[workType].title}`;
  dispatch(setTitle(pageTitle));

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());
        const clothing = await getClothing(clothingId);
        const works = await getWorksByClothingId(clothingId);
        setClothingDto(clothing);
        setWorkListDto(works);
        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('作業情報を取得できませんでした。', e, true),
          ),
        );
      }
    })();
  }, [clothingId, dispatch]);

  /**
   * 作業登録完了
   */
  const registClothing = () => {
    history.push('/inspection/registclothing', {
      workProcess,
      orderId,
      clothingId,
    });
  };

  /**
   * 作業追加、作業内容明細
   */
  const registWork = (_?: WorkType, workId?: string) => {
    history.push('/inspection/registwork', {
      workProcess,
      orderId,
      clothingId: workId ? undefined : clothingId,
      workId,
      workType,
    });
  };

  return (
    <>
      <WorkArea>
        <ContentsArea>
          <Grid container spacing={5}>
            <Grid container item xs={6} spacing={2}>
              <Grid item xs={8}>
                <OrderNoField orderNo={clothingDto?.orderNo} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="tag_no"
                  label="タグ番号"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={clothingDto?.tagNo ? clothingDto?.tagNo : ' '}
                  inputProps={{
                    'data-cy': 'tag-no-input',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography component="p" variant="h2">
                  <Box component="span" color={grey[600]} fontWeight={600}>
                    作業内容
                  </Box>
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Grid
                container
                item
                wrap="wrap"
                xs={12}
                spacing={2}
                className={classes.workListArea}
              >
                {workListDto
                  .filter(work => work.workType === workType)
                  .map(work => (
                    <Grid
                      item
                      className={classes.item}
                      key={work.workId}
                      data-cy="registered-list-area"
                    >
                      <WorkDetail
                        workId={work.workId}
                        workType={work.workType}
                        workContentName={work.workContentName}
                        workSide={work.workSide}
                        workLocation={work.workLocation}
                        clothingTypePicture={
                          work.workSide === WorkSide.Back
                            ? clothingDto?.clothingType?.clothingTypePictureBack
                            : clothingDto?.clothingType
                                ?.clothingTypePictureFront
                        }
                        workingResult={work.workingResult}
                        confirmationResult={work.confirmationResult}
                        onClick={registWork}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            <ActionButton
              onClick={registClothing}
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              data-cy="registered-button"
            >
              <Box component="span" fontSize="0.9rem">
                {`${workTypes[workType].title}登録完了`}
              </Box>
            </ActionButton>
            <ActionButton
              onClick={() => registWork()}
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              data-cy="add-work-button"
            >
              <Box component="span" fontSize="0.9rem">
                {`${workTypes[workType].title}追加`}
              </Box>
            </ActionButton>
          </Grid>
        </ButtonsArea>
      </WorkArea>
    </>
  );
};

export default WorkListPage;
