import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import {
  Grid,
  TextField,
  Box,
  Typography,
  Divider,
  Theme,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setTitle,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import { useForm } from 'react-hook-form';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  getClothingsByOrderId,
  getClothingByConditions,
} from 'api/clothings/clothings.api';
import { ClothingListDto } from 'api/clothings/clothings.dto';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { getOrder, getOrderByNo } from 'api/orders/orders.api';
import OrderNoField from 'components/common/OrderNoField';
import ClothingDetail from './ClothingDetail';

interface DataType {
  title: string;
  tagNo: string;
  imgPath: string;
  lintRemovalCount: number;
  repairCount: number;
  stainRemovallCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: '50%',
      marginBottom: theme.spacing(3),
    },
    item: {
      width: '16.5%',
    },
    clothingListArea: {
      overflowY: 'scroll',
      maxHeight: 'calc(60vh)',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
);

type FormData = {
  tagNo: string;
};

type LocationState = {
  workProcess: WorkProcess;
  orderId?: string;
  orderNo?: number;
};
const ReadTagPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { openDialog, dialogProps } = useDialog();
  const [clothingListDto, setClothingListDto] = useState<ClothingListDto[]>([]);
  const location = useLocation<LocationState>();
  const { workProcess, orderId, orderNo } = location.state;
  const { register, handleSubmit, errors, setError } = useForm<FormData>({
    reValidateMode: 'onSubmit',
  });
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  dispatch(setTitle('衣類タグ読取'));

  useEffect(() => {
    (async () => {
      if (orderId) {
        try {
          dispatch(startLoading());
          const dto = await getClothingsByOrderId(orderId);
          setClothingListDto(dto);
          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('衣類情報を取得できませんでした。', e, true),
            ),
          );
        }
      }
    })();
  }, [dispatch, history, openDialog, orderId]);

  const onSubmit = handleSubmit(async ({ tagNo }) => {
    try {
      dispatch(startLoading());
      const clothing = await getClothingByConditions({ tagNo });

      if (orderId) {
        if (clothing) {
          if (clothing.orderId) {
            if (clothing.orderId !== orderId) {
              setError([
                {
                  type: 'required',
                  name: 'tagNo',
                  message: `他の注文(受付番号:${clothing.orderNo})で登録済です。`,
                },
              ]);
              dispatch(endLoading());

              return;
            }
          } else {
            const order = await getOrder(orderId);

            if (clothing.orderNo !== order.orderNo) {
              setError([
                {
                  type: 'required',
                  name: 'tagNo',
                  message: `他の注文(受付番号:${clothing.orderNo})で登録済です。`,
                },
              ]);
              dispatch(endLoading());

              return;
            }
          }
        }
      } else if (!clothing) {
        setError([
          {
            type: 'required',
            name: 'tagNo',
            message: '衣類情報が登録されていません。',
          },
        ]);
        dispatch(endLoading());

        return;
      } else if (!clothing.orderId) {
        const orderByNo = await getOrderByNo(clothing.orderNo || 0);
        // orderByNo.orderIdがない(Orderなし)場合
        if (!orderByNo?.orderId) {
          // 検品担当者の場合、エラーメッセージを表示
          if (workProcess === WorkProcess.Inspection) {
            setError([
              {
                type: 'required',
                name: 'tagNo',
                message: '注文情報を登録してください。',
              },
            ]);
            dispatch(endLoading());

            return;
          }
          // orderByNo.orderNoがある(SyncOrderあり)場合、衣類情報登録へ遷移
          // 遷移先で衣類登録時、SyncClothingとSyncOrderのデータを基に、Orderのデータ登録も行う
          // ※検品工程で登録漏れのあったOrder（注文情報）を補完することが目的
          if (orderByNo?.orderNo) {
            history.push(`/${workProcess.toLowerCase()}/registclothing`, {
              workProcess,
              tagNo,
            });
          } else {
            setError([
              {
                type: 'required',
                name: 'tagNo',
                message: '衣類情報が登録されていません。',
              },
            ]);
            dispatch(endLoading());
          }
        }
      }

      if (workProcess === WorkProcess.Inspection) {
        history.push('/inspection/registclothing', {
          workProcess,
          orderId,
          tagNo,
        });
      } else {
        history.push(`/${workProcess.toLowerCase()}/registclothing`, {
          workProcess,
          tagNo,
        });
      }
    } catch (e) {
      dispatch(
        failLoading(createErrorObject('衣類情報を取得できませんでした。', e)),
      );
    }
  });

  const readLabel = () => {
    history.push('/inspection/readlabel', { workProcess });
  };

  const onBlurEvent = useCallback(() => {
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    (() => {
      // 入力エラー発生時は入力フォームをクリア
      if (errors.tagNo) {
        if (textFieldRef.current) {
          textFieldRef.current.value = '';
        }
      }
    })();
  }, [errors.tagNo]);

  return (
    <form onSubmit={onSubmit} noValidate>
      <CustomDialog {...dialogProps} />
      <WorkArea>
        <ContentsArea>
          <Grid container spacing={5}>
            <Grid container item xs={6} spacing={2}>
              {orderNo && (
                <Grid item xs={8}>
                  <OrderNoField orderNo={orderNo} />
                </Grid>
              )}
              <Grid item xs={8}>
                <TextField
                  name="tagNo"
                  label="タグ番号"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  onBlur={onBlurEvent}
                  required
                  autoComplete="off"
                  inputProps={{
                    inputMode: 'numeric',
                    maxLength: 8,
                    'data-cy': 'tag-no-input',
                  }}
                  inputRef={e => {
                    register(e, {
                      required: '衣類タグのバーコードを読み取ってください。',
                      pattern: {
                        value: /[0-9]{7}/,
                        message: '衣類タグのバーコードを読み取ってください。',
                      },
                      maxLength: {
                        value: 7,
                        message: '衣類タグのバーコードを読み取ってください。',
                      },
                    });
                    textFieldRef.current = e;
                  }}
                  helperText={errors.tagNo && errors.tagNo.message}
                  error={!!errors.tagNo}
                />
              </Grid>
            </Grid>

            {clothingListDto.length > 0 && (
              <Grid item xs={12} data-cy="clothing-list-area">
                <Box mb={2}>
                  <Typography component="p" variant="h2">
                    <Box component="span" fontWeight={600}>
                      衣類情報
                    </Box>
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Grid
                  container
                  wrap="wrap"
                  item
                  xs={12}
                  spacing={2}
                  className={classes.clothingListArea}
                  data-cy="clothing-list"
                >
                  {clothingListDto.map(clothing => (
                    <Grid
                      item
                      className={classes.item}
                      key={clothing.tagNo}
                      data-cy="clothing-detail"
                    >
                      <ClothingDetail
                        workProcess={workProcess}
                        orderId={orderId}
                        clothingId={clothing.clothingId}
                        clothingTypeName={clothing.clothingTypeName}
                        tagNo={clothing.tagNo}
                        clothingTypePicture={clothing.clothingTypePictureFront}
                        lintRemovalCount={clothing.lintRemovalCount}
                        repairCount={clothing.repairCount}
                        stainRemovalCount={clothing.stainRemovalCount}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            <ActionButton
              data-cy="regist-clothing-button"
              color="primary"
              type="submit"
              endIcon={<ArrowForwardIosIcon />}
            >
              衣類情報登録へ
            </ActionButton>
          </Grid>
          <Grid item>
            {workProcess === WorkProcess.Inspection && (
              <ActionButton
                data-cy="read-label-button"
                onClick={readLabel}
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
              >
                全衣類登録完了
              </ActionButton>
            )}
          </Grid>
        </ButtonsArea>
      </WorkArea>
    </form>
  );
};

export default ReadTagPage;
