import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Box,
  Typography,
  withStyles,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  Theme,
} from '@material-ui/core';
import { WorkContentDto } from 'api/workContents/workContents.dto';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  WorkType,
  WorkingResult,
  ConfirmationResult,
  WorkSide,
  UpdateWorkDto,
  WorkInfoDto,
  WorkDto,
} from 'api/works/works.dto';
import {
  WorkProcess,
  WorkHistoryDto,
} from 'api/workHistories/workHistories.dto';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
  openSnackbar,
} from 'modules/commonModule';
import Photo from 'components/common/Photo';
import ClothingTypeDialog from 'components/RegistClothing/ClothingTypeDialog';
import { ClothingTypeDto } from 'api/clothingTypes/clothingTypes.dto';
import { workTypes, workingResults, confirmationResults } from 'app/constants';
import WorkContentDialog from 'components/RegistWork/WorkContentDialog';
import ClothingImage from 'components/common/ClothingImage';
import { useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  uploadS3Base64Image,
  isBase64Image,
  removeS3Image,
} from 'utils/awsUtils';
import { getWork, updateWork, deleteWork } from 'api/works/works.api';
import {
  getWorkHistoriesByWorkId,
  createWorkHistory,
} from 'api/workHistories/workHistories.api';
import { UpdateClothingDto } from 'api/clothings/clothings.dto';
import { updateClothing } from 'api/clothings/clothings.api';
import { UserInfoDto } from 'api/users/users.dto';
import CustomTextField from 'components/Admin/common/CustomTextField';
import FileUplodeButton from 'components/Admin/common/FileUplodeButton';
import ActionButton from 'components/Admin/common/ActionButton';
import { RootState } from 'app/rootReducer';
import moment from 'moment';
import { getUserInfoList } from 'modules/mastersModule';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: 0,
    },
    cancelBtn: {
      width: '10%',
      minWidth: '120px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    toggleGroup: {
      width: '100%',
    },
    InputProps: {
      paddingRight: theme.spacing(1),
    },
    inputDisabled: {
      '& svg': {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'transparent',
      },
    },
  }),
);

/* material-uiコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const StyledIconButton = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(IconButton);

const StyledFormControl = withStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    '& label': {
      transform: 'translate(0, -14px) scale(0.75) !important',
      color: theme.palette.text.primary,
    },
    '& legend': {
      width: 0,
    },
  },
}))(FormControl);

const StyledSelect = withStyles(() => ({
  root: {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'transparent',
  },
}))(Select);

type FormData = {
  workId: string;
  clothingId: string;
  orderId: string;
  orderNo: string;
  tagNo: string;
  clothingType: ClothingTypeDto;
  clothingTypeId: string;
  workContent: WorkContentDto;
  workContentId: string;
  workType: WorkType;
  createdBy: string;
  workingResult: WorkingResult;
  workedBy: string;
  workedAt: Date;
  confirmationResult: ConfirmationResult;
  confirmedBy: string;
  confirmedAt: Date;
  beforePhoto: string;
  afterPhoto: string;
};
type PropsType = {
  open: boolean;
  handleClose: (reloadFlg?: boolean) => void;
  workId?: string;
};
const WorkEditDialog: FC<PropsType> = ({ open, handleClose, workId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfoList } = useSelector((state: RootState) => state.masters);
  const { register, handleSubmit, setValue, errors, setError } = useForm<
    FormData
  >();
  const { openDialog, dialogProps } = useDialog();
  const { workContentList } = useSelector((state: RootState) => state.masters);
  const [isOpenClothingTypeDialog, setIsOpenClothingTypeDialog] = useState(
    false,
  );
  const [isOpenWorkContentDialog, setIsOpenWorkContentDialog] = useState(false);
  const [workInfoDto, setWorkInfoDto] = useState<WorkInfoDto>();
  const [clothingType, setClothingType] = useState<ClothingTypeDto>();
  const [workType, setWorkType] = useState<WorkType>();
  const [workContent, setWorkContent] = useState<WorkContentDto>();
  const [
    workHistoryCreatedDto,
    setWorkHistoryCreatedDto,
  ] = useState<WorkHistoryDto | null>(null);
  const [
    workHistoryConfirmedDto,
    setWorkHistoryConfirmedDto,
  ] = useState<WorkHistoryDto | null>(null);
  const [
    workHistoryWorkedDto,
    setWorkHistoryWorkedDto,
  ] = useState<WorkHistoryDto | null>(null);
  const [createdUserDto, setCreatedUserDto] = useState<UserInfoDto | null>(
    null,
  );
  const [workedUserDto, setWorkedUserDto] = useState<UserInfoDto | null>(null);
  const [confirmedUserDto, setConfirmedUserDto] = useState<UserInfoDto | null>(
    null,
  );
  const [workSide, setWorkSide] = useState<WorkSide | null>();
  const [workLocation, setWorkLocation] = useState<string | null>();
  const [beforePhotoName, setBeforePhotoName] = useState('');
  const [beforePhoto, setBeforePhoto] = useState('');
  const [afterPhotoName, setAfterPhotoName] = useState('');
  const [afterPhoto, setAfterPhoto] = useState('');
  const [workingResult, setWorkingResult] = useState(WorkingResult.NotYet);
  const [confirmationResult, setConfirmationResult] = useState(
    ConfirmationResult.NotYet,
  );

  /* ダイアログ操作系
  -------------------------------------------------------------- */
  /**
   * 衣類種別選択ダイアログを表示
   */
  const openSelectClothingTypeDialog = useCallback(() => {
    setIsOpenClothingTypeDialog(true);
  }, []);

  /**
   * 衣類種別選択ダイアログを閉じる
   */
  const closeSelectClothingTypeDialog = useCallback(() => {
    setIsOpenClothingTypeDialog(false);
  }, []);

  /**
   * 衣類種別を選択
   * @param clothingTypeDto 衣類種別
   */
  const selectClothingType = useCallback((clothingTypeDto: ClothingTypeDto) => {
    setClothingType(clothingTypeDto);
    setIsOpenClothingTypeDialog(false);
  }, []);

  /**
   * 作業内容選択ダイアログを表示
   */
  const openWorkContentDialog = useCallback(() => {
    if (workType === WorkType.Repair) {
      setIsOpenWorkContentDialog(true);
    }
  }, [workType]);

  /**
   * 作業内容選択ダイアログを閉じる
   */
  const closeWorkContentDialog = useCallback(() => {
    setIsOpenWorkContentDialog(false);
  }, []);

  /**
   * 作業内容を選択
   * @param workContent 作業内容
   */
  const selectWorkContent = useCallback((workContentDto: WorkContentDto) => {
    setWorkContent(workContentDto);
    setIsOpenWorkContentDialog(false);
  }, []);

  /**
   * 作業前写真
   * ダイアログ（エクスプローラー）で選択した画像ファイルの情報をstateにセット
   */
  const setBeforePhotoInfo = useCallback(
    (fileName: string, base64Image: string) => {
      setBeforePhotoName(fileName);
      setBeforePhoto(base64Image);
    },
    [],
  );

  /**
   * 作業後写真
   * ダイアログ（エクスプローラー）で選択した画像ファイルの情報をstateにセット
   */
  const setAfterPhotoInfo = useCallback(
    (fileName: string, base64Image: string) => {
      setAfterPhotoName(fileName);
      setAfterPhoto(base64Image);
    },
    [],
  );

  /**
   * ダイアログを閉じる
   */
  const onClose = useCallback(
    (reloadFlg: boolean) => {
      setBeforePhotoInfo('', '');
      setAfterPhotoInfo('', '');
      handleClose(reloadFlg);

      // ダイアログをブラウザバックで閉じる対応
      history.goBack();
    },
    [handleClose, history, setAfterPhotoInfo, setBeforePhotoInfo],
  );

  // ブラウザバックでダイアログを閉じる
  useEffect(() => {
    if (open) {
      window.onpopstate = () => {
        handleClose(false);
      };
    }
  }, [open, handleClose]);

  /**
   * 作業前・作業後写真のアップロード
   */
  const uploadPhotos = useCallback(
    async props => {
      let photosDto: UpdateWorkDto | undefined;

      const beforeFileName = `${props.orderId}/${props.clothingId}/${props.workId}-before`;
      if (isBase64Image(beforePhoto)) {
        const beforePhotoKey = await uploadS3Base64Image(
          beforePhoto,
          beforeFileName,
        );
        if (beforePhotoKey) {
          photosDto = { beforePhoto: beforePhotoKey };
        }
      } else if (
        beforePhoto === '' &&
        beforePhoto !== (props.beforePhoto || '')
      ) {
        await removeS3Image(beforeFileName);
        photosDto = { beforePhoto: null };
      }

      const afterFileName = `${props.orderId}/${props.clothingId}/${props.workId}-after`;
      if (isBase64Image(afterPhoto)) {
        const afterPhotoKey = await uploadS3Base64Image(
          afterPhoto,
          afterFileName,
        );
        if (afterPhotoKey) {
          photosDto = { afterPhoto: afterPhotoKey, ...photosDto };
        }
      } else if (afterPhoto === '' && afterPhoto !== (props.afterPhoto || '')) {
        await removeS3Image(afterFileName);
        photosDto = { afterPhoto: null, ...photosDto };
      }

      return photosDto;
    },
    [afterPhoto, beforePhoto],
  );

  /**
   * 作業履歴情報登録
   * @param work 作業情報
   */
  const registerWorkHistory = useCallback(
    async (
      work: WorkDto,
      registerWorkHistoryDto: {
        workProcess: WorkProcess;
        createdByName?: string;
        createdBy?: string;
        createdAt?: Date;
      },
    ) => {
      await createWorkHistory(
        {
          workId: work.workId,
          workProcess: registerWorkHistoryDto.workProcess,
          workType: work.workType,
          workContentId: work.workContentId,
          workSide: work.workSide,
          workLocation: work.workLocation,
          beforePhoto: work.beforePhoto,
          afterPhoto: work.afterPhoto,
          workingResult: work.workingResult,
          workedAt: work.workedAt,
          confirmationResult: work.confirmationResult,
          confirmedAt: work.confirmedAt,
        },
        registerWorkHistoryDto.createdByName,
        registerWorkHistoryDto.createdBy,
        registerWorkHistoryDto.createdAt,
      );
    },
    [],
  );

  /**
   * 作業情報の更新 */
  const onSubmit = handleSubmit(async props => {
    try {
      if (workInfoDto) {
        let hasError = false;
        // 作業結果が未実施以外で作業実施者が未入力の場合のエラー
        if (props.workingResult !== WorkingResult.NotYet && !workedUserDto) {
          setError([
            {
              type: 'required',
              name: 'workedBy',
              message: '作業実施者を入力してください。',
            },
          ]);
          hasError = true;
        }
        // 検査結果が未実施以外で検査実施者が未入力の場合のエラー
        if (
          props.confirmationResult !== ConfirmationResult.NotYet &&
          !confirmedUserDto
        ) {
          setError([
            {
              type: 'required',
              name: 'confirmedBy',
              message: '検査実施者を入力してください。',
            },
          ]);
          hasError = true;
        }

        if (hasError) {
          return;
        }

        // 衣類種別が変更されている場合は衣類情報を更新;
        dispatch(startLoading());
        if (
          clothingType &&
          clothingType.clothingTypeId !== workInfoDto.clothingTypeId
        ) {
          const updateClothingDto: UpdateClothingDto = {
            clothingTypeId: clothingType.clothingTypeId,
          };
          await updateClothing(workInfoDto.clothingId, updateClothingDto);
        }

        // 作業情報を更新
        const updateWorkDto: UpdateWorkDto = {
          workType,
          workSide,
          workLocation,
          workContentId: workContent?.workContentId,
          workingResult: props.workingResult,
          confirmationResult: props.confirmationResult,
        };
        if (
          workInfoDto.workingResult === WorkingResult.NotYet &&
          props.workingResult !== WorkingResult.NotYet
        ) {
          updateWorkDto.workedAt = 'NOW()';
        } else if (
          workInfoDto.workingResult !== WorkingResult.NotYet &&
          props.workingResult === WorkingResult.NotYet
        ) {
          updateWorkDto.workedAt = null;
        }
        if (
          workInfoDto.confirmationResult === ConfirmationResult.NotYet &&
          props.confirmationResult !== ConfirmationResult.NotYet
        ) {
          updateWorkDto.confirmedAt = 'NOW()';
        } else if (
          workInfoDto.confirmationResult !== ConfirmationResult.NotYet &&
          props.confirmationResult === ConfirmationResult.NotYet
        ) {
          updateWorkDto.confirmedAt = null;
        }
        const uploadPhotosDto = await uploadPhotos(workInfoDto);
        const work = await updateWork(workInfoDto.workId, {
          ...updateWorkDto,
          ...uploadPhotosDto,
        });

        // 作業登録者が変更されている場合
        // 作業履歴の検品工程情報を登録
        if (
          createdUserDto &&
          workHistoryCreatedDto &&
          createdUserDto.userId !== workHistoryCreatedDto.createdBy
        ) {
          await registerWorkHistory(work, {
            workProcess: WorkProcess.Inspection,
            createdByName: createdUserDto.userName,
            createdBy: createdUserDto.userId,
            createdAt: moment(workHistoryCreatedDto.createdAt)
              .add(1, 'milliseconds')
              .toDate(), // ミリ秒に1を加算
          });
        }

        // 作業実施者、作業種別、作業内容、作業結果のいずれかが変更されている場合
        // 作業履歴の作業工程情報を登録
        if (
          workHistoryWorkedDto &&
          (workedUserDto?.userId !== workHistoryWorkedDto.createdBy ||
            work.workType !== workInfoDto.workType ||
            work.workContentId !== workInfoDto.workContentId ||
            work.workingResult !== workInfoDto.workingResult)
        ) {
          await registerWorkHistory(work, {
            workProcess: WorkProcess[work.workType],
            createdByName: workedUserDto?.userName,
            createdBy: workedUserDto?.userId,
            createdAt: moment(workHistoryWorkedDto.createdAt)
              .add(1, 'milliseconds')
              .toDate(), // ミリ秒に1を加算
          });
        } else if (
          // 作業結果が未実施から変更された場合
          // 作業履歴の作業工程情報を登録
          workInfoDto.workingResult === WorkingResult.NotYet &&
          work.workingResult !== WorkingResult.NotYet
        ) {
          await registerWorkHistory(work, {
            workProcess: WorkProcess[work.workType],
            createdByName: workedUserDto?.userName,
            createdBy: workedUserDto?.userId,
          });
        }

        // 検査実施者、検査結果のいずれかが変更されている場合
        // 作業履歴の検査工程情報を登録
        if (
          workHistoryConfirmedDto &&
          (confirmedUserDto?.userId !== workHistoryConfirmedDto.createdBy ||
            work.confirmationResult !== workInfoDto.confirmationResult)
        ) {
          await registerWorkHistory(work, {
            workProcess: WorkProcess.Confirmation,
            createdByName: confirmedUserDto?.userName,
            createdBy: confirmedUserDto?.userId,
            createdAt: moment(workHistoryConfirmedDto.createdAt)
              .add(1, 'milliseconds')
              .toDate(), // ミリ秒に1を加算
          });
        } else if (
          // 検査結果が未実施から変更された場合
          // 作業履歴の検査工程情報を登録
          workInfoDto.confirmationResult === ConfirmationResult.NotYet &&
          work.confirmationResult !== ConfirmationResult.NotYet
        ) {
          await registerWorkHistory(work, {
            workProcess: WorkProcess.Confirmation,
            createdByName: confirmedUserDto?.userName,
            createdBy: confirmedUserDto?.userId,
          });
        }
        dispatch(endLoading());
        dispatch(
          openSnackbar({
            text: '作業情報を更新しました。',
          }),
        );
      }

      // ダイアログを閉じる
      onClose(true);
    } catch (e) {
      dispatch(
        failLoading(
          createErrorObject('作業情報更新でエラーが発生しました。', e),
        ),
      );
    }
  });

  /**
   * 削除実行
   */
  const executeDelete = useCallback(
    async (targetWorkId?: string) => {
      if (targetWorkId) {
        try {
          dispatch(startLoading());
          await deleteWork(targetWorkId);
          dispatch(endLoading());
          dispatch(
            openSnackbar({
              text: '作業情報を削除しました。',
            }),
          );
          // ダイアログを閉じる
          handleClose(true);
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('作業情報を削除できませんでした。', e),
            ),
          );
        }
      }
    },
    [dispatch, handleClose],
  );

  /**
   * 削除確認ダイアログを表示
   */
  const openDeleteConfirmDialog = (targetWorkId?: string) => {
    openDialog({
      title: '作業情報の削除',
      content: (
        <>
          削除した作業情報は元に戻すことはできません。
          <br />
          削除してもよろしいですか？
        </>
      ),
      buttonTitle: '削除',
      action: () => executeDelete(targetWorkId),
    });
  };

  useEffect(() => {
    dispatch(getUserInfoList());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (open) {
        try {
          dispatch(startLoading());

          if (workId) {
            // 作業情報、作業履歴一覧をAPIから取得
            const work = await getWork(workId);
            const workHistoryList = await getWorkHistoriesByWorkId(workId);

            // 作業履歴を工程ごとに切り分け
            let workHistoryCreated: WorkHistoryDto | undefined;
            let workHistoryConfirmed: WorkHistoryDto | undefined;
            let workHistoryWorked: WorkHistoryDto | undefined;
            workHistoryList.forEach(value => {
              if (value.workProcess === WorkProcess.Inspection) {
                workHistoryCreated = value;
                setWorkHistoryCreatedDto(workHistoryCreated);
              } else if (value.workProcess === WorkProcess.Confirmation) {
                workHistoryConfirmed = value;
                setWorkHistoryConfirmedDto(workHistoryConfirmed);
              } else {
                workHistoryWorked = value;
                setWorkHistoryWorkedDto(workHistoryWorked);
              }
            });
            if (!workHistoryCreated) {
              setWorkHistoryCreatedDto(null);
            }
            if (!workHistoryConfirmed) {
              setWorkHistoryConfirmedDto(null);
            }
            if (!workHistoryWorked) {
              setWorkHistoryWorkedDto(null);
            }

            if (
              work &&
              work.clothing &&
              work.clothing.orderId &&
              work.clothing.tagNo &&
              work.clothing.clothingType &&
              work.workContentId &&
              work.workContent
            ) {
              const workInfo: WorkInfoDto = {
                workId: work.workId,
                clothingId: work.clothingId,
                orderId: work.clothing.orderId,
                orderNo: `000000${work.clothing.orderNo}`.slice(-6),
                tagNo: work.clothing.tagNo,
                clothingTypeId: work.clothing.clothingType.clothingTypeId,
                clothingType: work.clothing.clothingType,
                workType: work.workType,
                workContentId: work.workContentId,
                workContent: work.workContent,
                createdBy: workHistoryCreated?.createdBy || null,
                workingResult: work.workingResult,
                workedBy: workHistoryWorked?.createdBy || null,
                workedAt: work.workedAt,
                confirmationResult: work.confirmationResult,
                confirmedBy: workHistoryConfirmed?.createdBy || null,
                confirmedAt: work.confirmedAt,
                workSide: work.workSide,
                workLocation: work.workLocation,
                beforePhoto: work.beforePhoto,
                afterPhoto: work.afterPhoto,
              };

              Object.keys(workInfo).forEach(key => {
                const value = workInfo[key as keyof WorkInfoDto];
                setValue(key, value);
              });

              // useFormのsetValueで値をセットできない項目はstateから値をセット
              // ---------------------------------------------------------------
              setClothingType(workInfo.clothingType); // 衣類種別
              setWorkType(workInfo.workType); // 作業種別
              setWorkContent(workInfo.workContent); // 作業内容
              setWorkingResult(workInfo.workingResult); // 作業結果
              setConfirmationResult(workInfo.confirmationResult); // 検査結果
              setWorkSide(workInfo.workSide); // 作業箇所表裏
              setWorkLocation(workInfo.workLocation); // 作業箇所
              setBeforePhoto(workInfo.beforePhoto || ''); // 作業前写真
              setAfterPhoto(workInfo.afterPhoto || ''); // 作業後写真

              // 作業登録者
              const createdUser = userInfoList.find(user => {
                return user.userId === workInfo.createdBy;
              });
              setCreatedUserDto(createdUser || null);

              // 作業実施者
              const workedUser = userInfoList.find(user => {
                return user.userId === workInfo.workedBy;
              });
              setWorkedUserDto(workedUser || null);

              // 検査実施者
              const confirmedUser = userInfoList.find(user => {
                return user.userId === workInfo.confirmedBy;
              });
              setConfirmedUserDto(confirmedUser || null);

              setWorkInfoDto(workInfo);
            }
          }
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('作業情報を取得できませんでした。', e, true),
            ),
          );
        } finally {
          dispatch(endLoading());
        }
      } else {
        setClothingType(undefined);
        setWorkInfoDto(undefined);
        setBeforePhoto('');
        setAfterPhoto('');
      }
    })();
  }, [dispatch, open, setValue, userInfoList, workId]);

  return (
    <>
      <CustomDialog {...dialogProps} />
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        scroll="paper"
        maxWidth="xl"
        data-cy="workEdit-dialog"
      >
        <Grid container justify="space-between">
          <DialogTitle
            id="scroll-dialog-title"
            className={classes.dialogTitle}
            disableTypography
          >
            <Typography
              component="h2"
              variant="h2"
              data-cy="workEdit-dialog-title-label"
            >
              作業情報の変更
            </Typography>
          </DialogTitle>
          <DialogActions>
            <StyledIconButton
              onClick={() => onClose(false)}
              data-cy="workEdit-dialog-close-button"
            >
              <CancelIcon />
            </StyledIconButton>
          </DialogActions>
        </Grid>
        <DialogContent dividers>
          <form onSubmit={onSubmit} noValidate>
            <Grid
              container
              item
              xs={12}
              justify="space-between"
              alignItems="flex-start"
              className={classes.dialogContent}
            >
              <Grid container item xs={3} spacing={2}>
                <Grid item xs={12}>
                  <CustomTextField
                    name="orderNo"
                    label="受付番号"
                    fullWidth
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-orderNo-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="tagNo"
                    label="タグ番号"
                    fullWidth
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-tagNo-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="clothingTypeName"
                    label="衣類種別"
                    disabled
                    size="small"
                    value={
                      clothingType?.clothingTypeName
                        ? clothingType.clothingTypeName
                        : ''
                    }
                    inputRef={register}
                    onClick={openSelectClothingTypeDialog}
                    InputProps={{
                      readOnly: true,
                      className: classes.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <StyledIconButton>
                            <ArrowDropDownIcon />
                          </StyledIconButton>
                        </InputAdornment>
                      ),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      'data-cy': 'dialog-clothingTypeName-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledFormControl variant="outlined" fullWidth>
                    <InputLabel>作業種別</InputLabel>
                    <StyledSelect
                      native
                      label="作業種別"
                      inputRef={register}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>,
                      ) => {
                        const targetWorkType = event.target.value;
                        setWorkType(targetWorkType as WorkType);

                        // 作業種別切り替えに合わせ、作業内容stateを変更する
                        const workContentDto = workContentList.find(
                          wc => wc.workType === targetWorkType,
                        );
                        setWorkContent(workContentDto);
                      }}
                      name="workType"
                      inputProps={{
                        'data-cy': 'dialog-workType-input',
                      }}
                    >
                      <option value={WorkType.LintRemoval}>
                        {workTypes.LintRemoval.title}
                      </option>
                      <option value={WorkType.Repair}>
                        {workTypes.Repair.title}
                      </option>
                      <option value={WorkType.StainRemoval}>
                        {workTypes.StainRemoval.title}
                      </option>
                    </StyledSelect>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="workContent"
                    label="作業内容"
                    disabled
                    size="small"
                    value={workContent?.workContentName || ''}
                    inputRef={register}
                    onClick={openWorkContentDialog}
                    InputProps={{
                      readOnly: true,
                      className: classes.InputProps,
                      endAdornment:
                        workType === WorkType.Repair ? (
                          <InputAdornment position="end">
                            <StyledIconButton>
                              <ArrowDropDownIcon />
                            </StyledIconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      'data-cy': 'dialog-workContent-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={userInfoList}
                    value={createdUserDto}
                    getOptionLabel={option => option.userName}
                    getOptionSelected={(option, value) => option === value}
                    disableClearable
                    size="small"
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      selectedValue: UserInfoDto | null,
                    ) => {
                      setCreatedUserDto(selectedValue);
                    }}
                    renderInput={params => (
                      <CustomTextField
                        {...params}
                        label="作業登録者"
                        name="createdByName"
                      />
                    )}
                    data-cy="dialog-createdBy-autocomplete"
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledFormControl variant="outlined" fullWidth>
                    <InputLabel>作業結果</InputLabel>
                    <StyledSelect
                      native
                      label="作業結果"
                      inputRef={register}
                      name="workingResult"
                      inputProps={{
                        'data-cy': 'dialog-workingResult-input',
                      }}
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string | undefined;
                          value: unknown;
                        }>,
                      ) => {
                        const selectedWorkingResult = event.target
                          .value as WorkingResult;
                        if (selectedWorkingResult === WorkingResult.NotYet) {
                          setWorkedUserDto(null);
                          setValue(
                            'confirmationResult',
                            ConfirmationResult.NotYet,
                          );
                          setConfirmationResult(ConfirmationResult.NotYet);
                          setConfirmedUserDto(null);
                        }
                        setWorkingResult(selectedWorkingResult);
                      }}
                    >
                      <option value={WorkingResult.NotYet}>
                        {workingResults[WorkingResult.NotYet].label}
                      </option>
                      <option value={WorkingResult.Perfect}>
                        {workingResults[WorkingResult.Perfect].label}
                      </option>
                      <option value={WorkingResult.Part}>
                        {workingResults[WorkingResult.Part].label}
                      </option>
                      <option value={WorkingResult.Almost}>
                        {workingResults[WorkingResult.Almost].label}
                      </option>
                      <option value={WorkingResult.CouldNot}>
                        {workingResults[WorkingResult.CouldNot].label}
                      </option>
                      <option value={WorkingResult.NotRequired}>
                        {workingResults[WorkingResult.NotRequired].label}
                      </option>
                    </StyledSelect>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={userInfoList}
                    value={workedUserDto}
                    getOptionLabel={option => option.userName}
                    getOptionSelected={(option, value) => option === value}
                    disabled={workingResult === WorkingResult.NotYet}
                    disableClearable
                    size="small"
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      selectedValue: UserInfoDto | null,
                    ) => {
                      setWorkedUserDto(selectedValue);
                    }}
                    renderInput={params => (
                      <CustomTextField
                        {...params}
                        label="作業実施者"
                        name="workedBy"
                        helperText={
                          errors.workedBy ? errors.workedBy.message : ''
                        }
                        error={!!errors.workedBy}
                      />
                    )}
                    data-cy="dialog-workedBy-autocomplete"
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledFormControl variant="outlined" fullWidth>
                    <InputLabel>検査結果</InputLabel>
                    <StyledSelect
                      native
                      label="検査結果"
                      inputRef={register}
                      name="confirmationResult"
                      disabled={workingResult === WorkingResult.NotYet}
                      className={`
                        ${workingResult === WorkingResult.NotYet &&
                          classes.inputDisabled}
                        `}
                      inputProps={{
                        'data-cy': 'dialog-confirmationResult-input',
                      }}
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string | undefined;
                          value: unknown;
                        }>,
                      ) => {
                        const selectedConfirmationResult = event.target
                          .value as ConfirmationResult;
                        if (
                          selectedConfirmationResult ===
                          ConfirmationResult.NotYet
                        ) {
                          setConfirmedUserDto(null);
                        }
                        setConfirmationResult(selectedConfirmationResult);
                      }}
                    >
                      <option value={ConfirmationResult.NotYet}>
                        {confirmationResults[ConfirmationResult.NotYet].label}
                      </option>
                      <option value={ConfirmationResult.Ok}>
                        {confirmationResults[ConfirmationResult.Ok].label}
                      </option>
                      <option value={ConfirmationResult.Ng}>
                        {confirmationResults[ConfirmationResult.Ng].label}
                      </option>
                    </StyledSelect>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={userInfoList}
                    value={confirmedUserDto}
                    getOptionLabel={option => option.userName}
                    getOptionSelected={(option, value) => option === value}
                    disabled={confirmationResult === ConfirmationResult.NotYet}
                    disableClearable
                    size="small"
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      selectedValue: UserInfoDto | null,
                    ) => {
                      setConfirmedUserDto(selectedValue);
                    }}
                    renderInput={params => (
                      <CustomTextField
                        {...params}
                        label="検査実施者"
                        name="confirmedBy"
                        helperText={
                          errors.confirmedBy ? errors.confirmedBy.message : ''
                        }
                        error={!!errors.confirmedBy}
                      />
                    )}
                    data-cy="dialog-confirmedBy-autocomplete"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={3} spacing={2}>
                <Grid item xs={12}>
                  <ClothingImage
                    clothingType={clothingType}
                    workType={workType}
                    workSide={workSide}
                    workLocation={workInfoDto?.workLocation}
                    setWorkSide={ws => setWorkSide(ws)}
                    setWorkLocation={wl => setWorkLocation(wl)}
                    isAdmin
                  />
                </Grid>
              </Grid>

              <Grid container item xs={3} spacing={4}>
                <Grid item xs={11}>
                  <Photo
                    caption="作業前"
                    image={beforePhoto}
                    setImage={setBeforePhoto}
                    isAdmin
                    nameOption={workInfoDto?.tagNo}
                  />
                </Grid>

                <Grid item xs={11}>
                  <CustomTextField
                    name="beforePhoto"
                    label="アップロード画像"
                    size="small"
                    value={beforePhotoName || ''}
                    disabled
                    inputProps={{
                      'data-cy': 'dialog-beforePhoto-input',
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FileUplodeButton setPhotoInfo={setBeforePhotoInfo} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={3} spacing={4}>
                <Grid item xs={11}>
                  <Photo
                    caption="作業後"
                    image={afterPhoto}
                    setImage={setAfterPhoto}
                    isAdmin
                    nameOption={workInfoDto?.tagNo}
                  />
                </Grid>
                <Grid item xs={11}>
                  <CustomTextField
                    name="afterPhoto"
                    label="アップロード画像"
                    size="small"
                    value={afterPhotoName || ''}
                    disabled
                    inputProps={{
                      'data-cy': 'dialog-afterPhoto-input',
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FileUplodeButton setPhotoInfo={setAfterPhotoInfo} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-end">
              <Grid item>
                <Box mt={3} mr={2}>
                  <ActionButton
                    color="delete"
                    data-cy="dialog-delete-button"
                    fullWidth
                    type="button"
                    onClick={() => openDeleteConfirmDialog(workId)}
                  >
                    <Box component="span" width="100px">
                      削除
                    </Box>
                  </ActionButton>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={3}>
                  <ActionButton
                    type="button"
                    color="primary"
                    data-cy="dialog-regist-button"
                    fullWidth
                    onClick={onSubmit}
                  >
                    <Box component="span" width="100px">
                      登録
                    </Box>
                  </ActionButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <ClothingTypeDialog
        open={isOpenClothingTypeDialog}
        handleClose={closeSelectClothingTypeDialog}
        handleSelect={selectClothingType}
        isAdmin
      />

      <WorkContentDialog
        open={isOpenWorkContentDialog}
        handleClose={closeWorkContentDialog}
        handleSelect={selectWorkContent}
      />
    </>
  );
};

export default WorkEditDialog;
