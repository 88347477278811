import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'components/common/Theme';

const useStyles = makeStyles(() =>
  createStyles({
    noWorkMsgArea: {
      position: 'relative',
    },
    noWorkMsgBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      width: '80%',
    },
    noWorkMsg: {
      marginBottom: '20px',
    },
  }),
);

const NoWorkMessage = () => {
  const classes = useStyles();

  return (
    <Box
      border={1}
      borderColor="divider"
      borderRadius="10px"
      textAlign="center"
      className={classes.noWorkMsgArea}
      paddingTop="32%"
    >
      <Box className={classes.noWorkMsgBox}>
        <Typography component="p" className={classes.noWorkMsg}>
          <Box
            component="span"
            color={colors.gray.contrastText}
            fontWeight={900}
            fontSize="2.2rem"
          >
            No Work
          </Box>
        </Typography>
        <Typography component="p" variant="h3">
          <Box
            component="span"
            color="text.primary"
            fontWeight={600}
            data-cy="no-work-message"
          >
            この衣類には作業が登録されていません。
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
export default NoWorkMessage;
