import React, { FC, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ActionButton from 'components/common/ActionButton';
import { useDispatch } from 'react-redux';
import { startLoading, endLoading } from 'modules/commonModule';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { useForm } from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      width: '100%',
      maxWidth: '100%',
      padding: 0,
    },
    loginWrap: {
      height: '100vh',
      position: 'relative',
    },
    loginBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    typo: {
      display: 'inline-block',
      position: 'relative',
      zIndex: 1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: 'white',
    },
    item: {
      width: '20%',
      minWidth: '165px',
    },
    toggleGroup: {
      width: '100%',
    },
    loginBtn: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const ColorToggleButton = withStyles(theme => ({
  root: {
    fontWeight: 600,
    flexGrow: 1,
    borderColor: theme.palette.divider,
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(ToggleButton);

type FormData = {
  name: string;
  newPassword: string;
};

type LocationState = {
  userId: string;
  password: string;
};

const RegisterUserPage: FC<{}> = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { userId, password } = location.state;
  const classes = useStyles();
  const [selectedWorkProcess, setSelectedWorkProcess] = useState<WorkProcess>();
  const [workProcessError, setWorkProcessError] = useState('');
  const { register, handleSubmit, errors, setError } = useForm<FormData>({
    defaultValues: {
      name: '',
      newPassword: '',
    },
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { openDialog, dialogProps } = useDialog();

  /**
   * 登録
   */
  const onSubmit = handleSubmit(async ({ name, newPassword }) => {
    if (!selectedWorkProcess) {
      setWorkProcessError('担当工程を選択してください。');

      return;
    }
    try {
      dispatch(startLoading());
      const user = await Auth.signIn(userId, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword, // the new password
          // OPTIONAL, the required attributes
          { name, 'custom:workProcess': selectedWorkProcess },
        );
      }

      if (selectedWorkProcess === WorkProcess.Inspection) {
        history.push('/inspection/readlabel', { selectedWorkProcess });
      } else if (selectedWorkProcess === WorkProcess.Repair) {
        history.push('/repair/readtag', { selectedWorkProcess });
      } else if (selectedWorkProcess === WorkProcess.StainRemoval) {
        history.push('/stainremoval/readtag', { selectedWorkProcess });
      } else if (selectedWorkProcess === WorkProcess.Confirmation) {
        history.push('/confirmation/readtag', { selectedWorkProcess });
      } else if (selectedWorkProcess === WorkProcess.Admin) {
        history.push('/admin/dashboard', { selectedWorkProcess });
      }

      return;
    } catch (e) {
      if (e.code === 'NotAuthorizedException') {
        setError([
          {
            type: 'required',
            name: 'username',
            message: '作業者IDまたはパスワードが間違っています。',
          },
          {
            type: 'required',
            name: 'password',
            message: '作業者IDまたはパスワードが間違っています。',
          },
        ]);
      } else if (e.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (e.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (e.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      }

      openDialog({
        title: 'ユーザー登録でエラーが発生しました。',
        content: (
          <>
            code:{e.code}
            <br />
            name:{e.name}
            <br />
            message:{e.message}
          </>
        ),
      });
    } finally {
      dispatch(endLoading());
    }

    history.goBack();
  });

  const changeWorkProcess = (
    event: React.MouseEvent<HTMLElement>,
    workProcess: WorkProcess,
  ) => {
    setSelectedWorkProcess(workProcess);
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <CustomDialog {...dialogProps} />
      <Container component="main" className={classes.container}>
        <CssBaseline />
        <Box className={classes.loginWrap}>
          <Box className={classes.loginBox} minWidth="450px">
            <Box textAlign="center" mb={1}>
              <img src="/logo.png" alt="Logo" data-cy="logo-image" />
            </Box>
            <Box textAlign="center" mb={6}>
              <Typography variant="caption" noWrap>
                作業用アプリ
              </Typography>
              <Box mt={2}>
                <Typography variant="subtitle1" noWrap color="secondary">
                  初回ログイン
                </Typography>
                <Typography variant="h5" noWrap color="secondary">
                  作業者名・新しいパスワード・担当工程を登録してください。
                </Typography>
              </Box>
            </Box>
            <Box mb={2}>
              <TextField
                name="username"
                label="作業者ID"
                variant="outlined"
                fullWidth
                value={userId}
                disabled
                inputProps={{
                  'data-cy': 'user-name-input',
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                name="name"
                label="作業者名"
                variant="outlined"
                fullWidth
                autoFocus
                required
                autoComplete="off"
                inputRef={register({
                  required: '作業者名を入力してください。',
                })}
                helperText={errors.name ? errors.name.message : ''}
                error={!!errors.name}
                inputProps={{
                  'data-cy': 'name-input',
                }}
              />
            </Box>
            <Box mb={3}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={!!errors.newPassword}
              >
                <InputLabel htmlFor="newPassword">新しいパスワード</InputLabel>
                <OutlinedInput
                  name="newPassword"
                  id="newPassword"
                  type={isShowPassword ? 'text' : 'password'}
                  autoComplete="off"
                  inputRef={register({
                    required: '新しいパスワードを入力してください。',
                    minLength: {
                      value: 6,
                      message: '6文字以上で入力してください。',
                    },
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        data-cy="mask-button"
                      >
                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  aria-describedby="passwordHelperText"
                  inputProps={{
                    'data-cy': 'password-input',
                    'aria-label': 'weight',
                  }}
                  labelWidth={155}
                />
                {!!errors.newPassword && (
                  <FormHelperText id="passwordHelperText">
                    {errors.newPassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box position="relative" textAlign="center" mb={1}>
              <Typography variant="h5" noWrap className={classes.typo}>
                担当工程
              </Typography>
              <Box position="absolute" width="100%" top="50%">
                <Divider />
              </Box>
            </Box>
            <Box mb={4}>
              <ToggleButtonGroup
                size="large"
                exclusive
                onChange={changeWorkProcess}
                value={selectedWorkProcess}
                className={classes.toggleGroup}
                data-cy="process-buttons"
              >
                <ColorToggleButton
                  key={WorkProcess.Inspection}
                  value={WorkProcess.Inspection}
                  data-cy="inspection-button"
                >
                  検品
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.Repair}
                  value={WorkProcess.Repair}
                  data-cy="repair-button"
                >
                  修理
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.StainRemoval}
                  value={WorkProcess.StainRemoval}
                  data-cy="stainRemoval-button"
                >
                  染み/汚れ
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.Confirmation}
                  value={WorkProcess.Confirmation}
                  data-cy="confirmation-button"
                >
                  検査
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.Admin}
                  value={WorkProcess.Admin}
                  data-cy="admin-button"
                >
                  管理者
                </ColorToggleButton>
              </ToggleButtonGroup>
              {!!workProcessError && (
                <FormControl error>
                  <FormHelperText>{workProcessError}</FormHelperText>
                </FormControl>
              )}
            </Box>
            <Box
              ml="auto"
              mr="auto
          "
            >
              <ActionButton
                data-cy="change-password-button"
                type="submit"
                fullWidth
                color="primary"
                className={classes.loginBtn}
              >
                登録
              </ActionButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </form>
  );
};
export default RegisterUserPage;
