import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { PropTypes, createStyles } from '@material-ui/core';
import { colors } from 'components/common/Theme';

interface PropsType {
  color?:
    | 'Inspection'
    | 'repair'
    | 'stainRemoval'
    | 'confirmation'
    | 'delete'
    | 'logout'
    | PropTypes.Color;
  size?: 'small' | 'large';
  onClick?: () => void | Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      paddingTop: (props: PropsType) => {
        if (props.size === 'large') {
          return theme.spacing(2);
        }
        if (props.size === 'small') {
          return theme.spacing(0.7);
        }

        return theme.spacing(1);
      },
      paddingBottom: (props: PropsType) => {
        if (props.size === 'large') {
          return theme.spacing(2);
        }
        if (props.size === 'small') {
          return theme.spacing(0.7);
        }

        return theme.spacing(1);
      },
      borderRadius: 5,
      fontSize: theme.typography.h5.fontSize,
      background: (props: PropsType) => {
        if (props.color === 'Inspection') {
          return colors.inspection.main;
        }
        if (props.color === 'repair') {
          return colors.repair.main;
        }
        if (props.color === 'stainRemoval') {
          return colors.stainRemoval.main;
        }
        if (props.color === 'confirmation') {
          return colors.confirmation.main;
        }
        if (props.color === 'delete') {
          return colors.delete.main;
        }
        if (props.color === 'logout') {
          return colors.logout.main;
        }

        return undefined;
      },
      '&:hover': {
        backgroundColor: (props: PropsType) => {
          if (props.color === 'Inspection') {
            return colors.inspection.dark;
          }
          if (props.color === 'repair') {
            return colors.repair.dark;
          }
          if (props.color === 'stainRemoval') {
            return colors.stainRemoval.dark;
          }
          if (props.color === 'confirmation') {
            return colors.confirmation.dark;
          }
          if (props.color === 'delete') {
            return colors.delete.dark;
          }
          if (props.color === 'logout') {
            return colors.logout.light;
          }

          return undefined;
        },
      },
    },
  }),
);

const ActionButton = (
  props: PropsType & Omit<ButtonProps, keyof PropsType>,
) => {
  const classes = useStyles(props);
  const { color, ...other } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const wasCleanuped = useRef(false);

  useEffect(() => {
    return () => {
      wasCleanuped.current = true;
    };
  }, []);

  // ボタン連打防止処理
  const onClick = useCallback(async () => {
    if (props.onClick) {
      setDisabled(true);
      await props.onClick();
      window.setTimeout(() => {
        if (!wasCleanuped.current) {
          setDisabled(false);
        }
      }, 1500);
    }
  }, [props]);

  other.fullWidth = true;
  other.variant = 'contained';
  if (
    color === 'Inspection' ||
    color === 'repair' ||
    color === 'stainRemoval' ||
    color === 'confirmation' ||
    color === 'delete' ||
    color === 'logout'
  ) {
    return (
      <Button
        disabled={disabled}
        classes={classes}
        {...other}
        onClick={onClick}
      />
    );
  }

  return (
    <Button
      disabled={disabled}
      classes={classes}
      {...other}
      color={color}
      onClick={onClick}
    />
  );
};

export default ActionButton;
