import { API } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { trimQueryStringParameters, callGetApi } from 'utils/apiUtils';
import {
  UserInfoDto,
  CreateUserInfoDto,
  UpdateUserInfoDto,
  UserConditions,
} from './users.dto';

const path = '/users';

/**
 * ユーザー情報取得
 * @param conditions 検索条件
 */
export const getUsers = async (conditions?: UserConditions) => {
  const users: UserInfoDto[] = await callGetApi(path, {
    queryStringParameters: trimQueryStringParameters(conditions),
  });

  return users;
};

/**
 * ユーザー情報取得（未認証時）
 * @param conditions 検索条件
 */
export const getUsersByUnAuth = async (conditions?: UserConditions) => {
  const users: UserInfoDto[] = await API.get(API_NAME, `${path}/osekkai`, {
    queryStringParameters: trimQueryStringParameters(conditions),
  });

  return users;
};

/**
 * ユーザー情報登録
 * @param createUserInfoDto ユーザー情報
 */
export const createUser = async (createUserInfoDto: CreateUserInfoDto) => {
  try {
    const user: UserInfoDto = await API.post(API_NAME, path, {
      body: { ...createUserInfoDto },
    });

    return user;
  } catch (e) {
    if (e.response && e.response.status === 409) {
      return undefined;
    }
    throw e;
  }
};

/**
 * ユーザー情報更新
 * @param updateUserInfoDto ユーザー情報
 */
export const updateUser = async (
  userId: string,
  updateUserInfoDto: UpdateUserInfoDto,
) => {
  const user: UserInfoDto = await API.patch(API_NAME, `${path}/${userId}`, {
    body: { ...updateUserInfoDto },
  });

  return user;
};

/**
 * ユーザー情報を削除
 * @param userId ユーザーID
 */
export const deleteUser = async (userId: string) => {
  await API.del(API_NAME, `${path}/${userId}`, {});
};
