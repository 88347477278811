import React, { FC } from 'react';
import { Theme, makeStyles, createStyles, Grid, Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentsArea: {
      height: 'calc(100vh - 96px)',
      boxShadow: theme.shadows[5],
    },
  }),
);

const ContentsArea: FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={10}>
      <Box
        p={3}
        mt={1}
        mb={1}
        borderRadius={8}
        bgcolor="#fff"
        className={classes.contentsArea}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default ContentsArea;
