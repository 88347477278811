import React, { FC, useState, useCallback, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Box } from '@material-ui/core';
import { UserInfoDto } from 'api/users/users.dto';
import { workProcesses } from 'app/constants';
import DataTable from 'react-data-table-component';
import { colors } from 'components/common/Theme';
import ActionButton from 'components/Admin/common/ActionButton';
import { useHistory } from 'react-router-dom';
import UserEditDialog from './UserEditDialog';

/* react-data-table-componentコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const customStyles = {
  table: {
    style: {
      '& .eGEGHg': {
        maxHeight: 'none',
        overflowY: 'auto',
      },
    },
  },
  header: {
    style: {
      display: 'none',
    },
  },
  headRow: {
    style: {
      backgroundColor: colors.admin.sideBar.main,
      minHeight: '37px',
    },
  },
  rows: {
    style: {
      minHeight: '37px',
    },
    stripedStyle: {
      '&:nth-of-type(even)': {
        backgroundColor: colors.admin.table.body.even,
        '& div[role="cell"]': {
          backgroundColor: colors.admin.table.body.even,
        },
      },
      '&:nth-of-type(odd)': {
        backgroundColor: colors.admin.table.body.odd,
        '& div[role="cell"]': {
          backgroundColor: colors.admin.table.body.odd,
        },
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: '#FFF',
    },
    activeSortStyle: {
      color: '#FFF',
      '&:focus': {
        outline: 'none',
      },
      '&:hover:not(:focus)': {
        color: '#FFF',
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#FFF',
      },
      '&:hover': {
        color: '#FFF',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

/**
 * material-uiコンポーネントの既存スタイルに上書き
 */
const StyledIconButton = withStyles(() => ({
  root: {
    padding: 0,
    display: 'block',
    width: '100%',
  },
}))(IconButton);

interface PropsType {
  userInfoList: UserInfoDto[];
  userSearch: () => void;
}
const UsersTable: FC<PropsType> = ({ userInfoList, userSearch }) => {
  const history = useHistory();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  // 担当工程を表示用に変換
  const dispWorkProcess = useCallback(
    (row: UserInfoDto) => <>{workProcesses[row.workProcess].title}</>,
    [],
  );

  /**
   * 作業者情報編集ダイアログを表示 */
  const openDialog = useCallback(
    (userId?: string) => {
      if (userId) {
        setSelectedUserId(userId);
      } else {
        setSelectedUserId(null);
      }
      setIsOpenDialog(true);
      // ダイアログをブラウザバックで閉じる対応
      history.push('/admin/registeruser');
    },
    [history],
  );

  /**
   * 作業者情報編集ダイアログを閉じる */
  const closeDialog = useCallback(
    (reloadFlg: boolean) => {
      // ダイアログで作業者情報の更新をされた場合はデータテーブルをリロード
      if (reloadFlg) {
        userSearch();
      }
      setIsOpenDialog(false);
    },
    [userSearch],
  );

  // 編集ボタンコンポーネント
  const editButton = useCallback(
    (row: UserInfoDto) => (
      <StyledIconButton onClick={() => openDialog(row.userId)}>
        <EditIcon />
      </StyledIconButton>
    ),
    [openDialog],
  );

  const columns = useMemo(() => {
    return [
      {
        name: '',
        selector: 'editUserInfoButton',
        width: '80px',
        cell: editButton,
      },
      {
        name: '作業者ID',
        selector: 'userId',
        sortable: true,
        maxWidth: '150px',
      },
      {
        name: '作業者名',
        selector: 'userName',
        sortable: true,
        maxWidth: '250px',
      },
      {
        name: '担当工程',
        selector: 'workProcess',
        sortable: true,
        maxWidth: '150px',
        cell: dispWorkProcess,
      },
    ];
  }, [dispWorkProcess, editButton]);

  return (
    <>
      <Grid container justify="flex-end">
        <Grid item>
          <Box mb={1}>
            <ActionButton
              color="primary"
              data-cy="regist-button"
              fullWidth
              size="small"
              onClick={openDialog}
            >
              <Box component="span" width="100px">
                新規登録
              </Box>
            </ActionButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container data-cy="userList-dataTable">
        <DataTable
          columns={columns}
          data={userInfoList}
          fixedHeader
          fixedHeaderScrollHeight="calc(100vh - 380px)"
          striped
          customStyles={customStyles}
        />
      </Grid>

      <UserEditDialog
        open={isOpenDialog}
        handleClose={closeDialog}
        selectedUserId={selectedUserId}
      />
    </>
  );
};
export default UsersTable;
