/* eslint-disable max-classes-per-file */
import { WorkContentDto } from 'api/workContents/workContents.dto';
import { ClothingDto } from 'api/clothings/clothings.dto';
import { ClothingTypeDto } from 'api/clothingTypes/clothingTypes.dto';

/**
 * 作業種別
 */
export enum WorkType {
  /** 毛玉取り */
  LintRemoval = 'LintRemoval',
  /** 修理 */
  Repair = 'Repair',
  /** 染み抜き */
  StainRemoval = 'StainRemoval',
}

/**
 * 作業箇所表裏
 */
export enum WorkSide {
  /** 表 */
  Front = 'Front',
  /** 裏 */
  Back = 'Back',
}

/**
 * 作業結果
 */
export enum WorkingResult {
  /** 未作業 */
  NotYet = 'NotYet',
  /** 完璧にできた */
  Perfect = 'Perfect',
  /** ほぼできた	 */
  Almost = 'Almost',
  /** 一部できた */
  Part = 'Part',
  /** できなかった */
  CouldNot = 'CouldNot',
  /** する必要無し */
  NotRequired = 'NotRequired',
}

/**
 * 検査結果
 */
export enum ConfirmationResult {
  /** 未検査 */
  NotYet = 'NotYet',
  /** 検査OK */
  Ok = 'Ok',
  /** 検査NG */
  Ng = 'Ng',
}

/**
 * 作業情報
 */
export class WorkDto {
  /** 作業Id */
  readonly workId!: string;

  /** 衣類Id */
  readonly clothingId!: string;

  /** 衣類情報 */
  readonly clothing!: ClothingDto;

  /** 作業種別 */
  readonly workType!: WorkType;

  /** 作業内容Id */
  readonly workContentId!: string | null;

  /** 作業内容 */
  readonly workContent?: WorkContentDto;

  /** 作業箇所表裏 */
  readonly workSide!: WorkSide | null;

  /** 作業箇所 */
  readonly workLocation!: string | null;

  /** 作業前写真 */
  readonly beforePhoto!: string | null;

  /** 作業後写真 */
  readonly afterPhoto!: string | null;

  /** 作業結果 */
  readonly workingResult!: WorkingResult;

  /** 作業終了日時 */
  readonly workedAt!: Date | null;

  /** 検査結果 */
  readonly confirmationResult!: ConfirmationResult;

  /** 検査終了日時 */
  readonly confirmedAt!: Date | null;

  /** 登録者 */
  readonly createdBy!: string;

  /** 登録日時 */
  readonly createdAt!: Date;

  /** 更新者 */
  readonly updatedBy!: string;

  /** 更新日時 */
  readonly updatedAt!: Date;
}

/**
 * 作業一覧情報
 */
export class WorkListDto {
  /** 作業Id */
  readonly workId!: string;

  /** 作業種別 */
  readonly workType!: WorkType;

  /** 作業内容名 */
  readonly workContentName!: string | null;

  /** 作業箇所表裏 */
  readonly workSide!: WorkSide | null;

  /** 作業箇所 */
  readonly workLocation!: string | null;

  /** 作業結果 */
  readonly workingResult!: WorkingResult;

  /** 検査結果 */
  readonly confirmationResult!: ConfirmationResult;
}

/**
 * 登録作業情報
 */
export class CreateWorkDto {
  /** 衣類Id */
  clothingId!: string;

  /** 作業種別 */
  workType!: WorkType;

  /** 作業内容Id */
  workContentId?: string | null;

  /** 作業箇所表裏 */
  workSide?: WorkSide | null;

  /** 作業箇所 */
  workLocation?: string | null;

  /** 作業前写真 */
  beforePhoto?: string | null;

  /** 作業後写真 */
  afterPhoto?: string | null;

  /** 作業結果 */
  workingResult?: WorkingResult;

  /** 作業終了日時 */
  workedAt?: Date | null;

  /** 検査結果 */
  confirmationResult?: ConfirmationResult;

  /** 検査終了日時 */
  confirmedAt?: Date | null;
}

/**
 * 更新作業情報
 */
export class UpdateWorkDto {
  /** 作業種別 */
  workType?: WorkType;

  /** 作業内容Id */
  workContentId?: string | null;

  /** 作業箇所表裏 */
  workSide?: WorkSide | null;

  /** 作業箇所 */
  workLocation?: string | null;

  /** 作業前写真 */
  beforePhoto?: string | null;

  /** 作業後写真 */
  afterPhoto?: string | null;

  /** 作業結果 */
  workingResult?: WorkingResult;

  /** 作業終了日時 */
  workedAt?: Date | null | 'NOW()';

  /** 検査結果 */
  confirmationResult?: ConfirmationResult;

  /** 検査終了日時 */
  confirmedAt?: Date | null | 'NOW()';
}

/**
 * 作業情報検索結果（管理画面用）
 */
export class WorkAdminDto {
  /** 作業Id */
  readonly workId!: string;

  /** 受注番号 */
  readonly orderNo!: number;

  /** ECサイト注文番号 */
  readonly ecOrderNo!: string;

  /** タグ番号 */
  readonly tagNo!: string;

  /** 衣類種別名 */
  readonly clothingTypeName!: string | null;

  /** 検品終了日時 */
  readonly inspectedAt!: Date | null;

  /** 検品担当者名 */
  readonly inspectedByName!: string | null;

  /** 作業種別 */
  readonly workType!: WorkType;

  /** 作業内容名 */
  readonly workContentName!: string | null;

  /** 作業結果 */
  readonly workingResult!: WorkingResult;

  /** 作業終了日時 */
  readonly workedAt!: Date | null;

  /** 作業担当者名 */
  readonly workedByName!: string | null;

  /** 検査結果 */
  readonly confirmationResult!: ConfirmationResult;

  /** 検査完了日時 */
  readonly confirmedAt!: Date | null;

  /** 検査実施者 */
  readonly confirmedByName!: string | null;

  /** 作業前写真 */
  readonly beforePhoto!: string | null;

  /** 作業後写真 */
  readonly afterPhoto!: string | null;
}

/**
 * 作業情報一覧（管理画面用）
 */
export class WorkAdminListDto {
  /** 件数 */
  readonly count!: number;

  /** 作業情報一覧 */
  readonly workAdminList!: WorkAdminDto[];
}

/**
 * 作業情報編集（管理画面用）
 */
export class WorkInfoDto {
  /** 作業Id */
  readonly workId!: string;

  /** 衣類Id */
  readonly clothingId!: string;

  /** 注文Id */
  readonly orderId!: string;

  /** 受付番号 */
  readonly orderNo!: string;

  /** タグ番号 */
  readonly tagNo!: string;

  /** 衣類種別Id */
  readonly clothingTypeId!: string;

  /** 衣類種別 */
  readonly clothingType!: ClothingTypeDto;

  /** 作業種別 */
  readonly workType!: WorkType;

  /** 作業内容Id */
  readonly workContentId!: string;

  /** 作業内容 */
  readonly workContent!: WorkContentDto;

  /** 作業登録者 */
  readonly createdBy!: string | null;

  /** 作業結果 */
  readonly workingResult!: WorkingResult;

  /** 作業実施者 */
  readonly workedBy!: string | null;

  /** 作業終了日時 */
  readonly workedAt!: Date | null;

  /** 検査結果 */
  readonly confirmationResult!: ConfirmationResult;

  /** 検査実施者 */
  readonly confirmedBy!: string | null;

  /** 検査終了日時 */
  readonly confirmedAt!: Date | null;

  /** 作業箇所表裏 */
  readonly workSide!: WorkSide | null;

  /** 作業箇所 */
  readonly workLocation!: string | null;

  /** 作業前写真 */
  readonly beforePhoto!: string | null;

  /** 作業後写真 */
  readonly afterPhoto!: string | null;
}
