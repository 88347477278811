import { API, Auth } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { OrderConditions } from 'api/orders/orders.dto';
import { trimQueryStringParameters, callGetApi } from 'utils/apiUtils';
import {
  WorkDto,
  WorkListDto,
  CreateWorkDto,
  UpdateWorkDto,
  WorkAdminListDto,
} from './works.dto';

const path = '/works';

/**
 * 作業情報を取得
 * @param workId 作業Id
 */
export const getWork = async (workId: string) => {
  const workDto: WorkDto = await callGetApi(`${path}/${workId}`, {});

  return workDto;
};

/**
 * 作業情報一覧を取得
 * @param clothingId 衣類Id
 */
export const getWorksByClothingId = async (clothingId: string) => {
  const workListDto: WorkListDto[] = await callGetApi(
    `${path}/list/${clothingId}`,
    {},
  );

  return workListDto;
};

/**
 * 作業情報を登録
 * @param createWorkDto 作業情報
 */
export const createWork = async (createWorkDto: CreateWorkDto) => {
  const user = await Auth.currentAuthenticatedUser();
  const work: WorkDto = await API.post(API_NAME, path, {
    body: { ...createWorkDto, createdBy: user.getUsername() },
  });

  return work;
};

/**
 * 作業情報を更新
 * @param workId 作業Id
 * @param updateWorkDto 作業情報
 */
export const updateWork = async (
  workId: string,
  updateWorkDto: UpdateWorkDto,
) => {
  const user = await Auth.currentAuthenticatedUser();
  const work: WorkDto = await API.patch(API_NAME, `${path}/${workId}`, {
    body: { ...updateWorkDto, updatedBy: user.getUsername() },
  });

  return work;
};

/**
 * 作業情報を削除
 * @param workId 作業Id
 */
export const deleteWork = async (workId: string) => {
  const user = await Auth.currentAuthenticatedUser();
  await API.del(API_NAME, `${path}/${workId}`, {
    body: { deletedBy: user.getUsername() },
  });
};

/**
 * 検索条件から作業情報一覧を取得（管理画面用）
 * @param page ページ
 * @param perPage 1ページあたりの件数
 * @param conditions 検索条件
 */
export const getWorkListByConditions = async (conditions?: OrderConditions) => {
  try {
    const workList: WorkAdminListDto = await callGetApi(`${path}/admin`, {
      queryStringParameters: trimQueryStringParameters(conditions),
    });

    return workList;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};
