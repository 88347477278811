import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Grid,
  TextField,
  Box,
  Typography,
  Divider,
  withStyles,
  Theme,
  CardActionArea,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setTitle,
  openSnackbar,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Photo from 'components/common/Photo';
import { ClothingTypeDto } from 'api/clothingTypes/clothingTypes.dto';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  getClothingByConditions,
  getClothing,
  createClothing,
  updateClothing,
  deleteClothing,
} from 'api/clothings/clothings.api';
import {
  ClothingDto,
  CreateClothingDto,
  UpdateClothingDto,
} from 'api/clothings/clothings.dto';
import {
  WorkListDto,
  WorkType,
  WorkSide,
  WorkingResult,
  ConfirmationResult,
} from 'api/works/works.dto';
import { getWorksByClothingId } from 'api/works/works.api';
import {
  createOrder,
  getOrder,
  getOrderByNo,
  updateOrderEndedAt,
} from 'api/orders/orders.api';
import { OrderDto } from 'api/orders/orders.dto';
import {
  uploadS3Base64Image,
  isBase64Image,
  removeS3Image,
} from 'utils/awsUtils';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { useForm } from 'react-hook-form';
import CustomCard from 'components/common/CustomCard';
import OrderNoField from 'components/common/OrderNoField';
import { workProcesses } from 'app/constants';
import WorkDetail from '../common/WorkDetail';
import ClothingTypeDialog from './ClothingTypeDialog';
import NoWorkMessage from './NoWorkMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputArea: {
      position: 'relative',
    },
    clothingTypeBox: {
      position: 'absolute',
      width: '35%',
      right: 0,
    },
    customCard: {
      overflow: 'inherit',
      boxShadow: 'none',
    },
    media: {
      height: 0,
      paddingTop: '100%',
    },
    cardContent: {
      textAlign: 'center',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    clothingTypeLabel: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
      whiteSpace: 'nowrap',
      position: 'absolute',
      top: '50%',
      zIndex: 1,
      transitionDuration: '0.2s, 0.2s',
      transform: 'translate(50%, -50%) scale(1)',
    },
    clothingTypeSelected: {
      color: theme.palette.text.primary,
      background: theme.palette.common.white,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      top: 0,
      transform: 'translate(0, -50%) scale(0.75)',
    },
    forcus: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    clothingTypeForcus: {
      color: theme.palette.primary.main,
    },
    error: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    clothingTypeError: {
      color: theme.palette.error.main,
    },
    errorMessage: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.error.main,
      whiteSpace: 'nowrap',
    },
    divider: {
      width: '50%',
      marginBottom: theme.spacing(3),
    },
    item: {
      width: '20%',
      minWidth: '165px',
    },
    toggleGroup: {
      width: '100%',
    },
    container: {
      paddingRight: '24px !important',
    },
    resetPt: {
      paddingTop: '0 !important',
    },
    resetPb: {
      paddingBottom: '0 !important',
    },
    workListArea: {
      overflowY: 'scroll',
      paddingTop: '5px',
      paddingLeft: '5px',
      maxHeight: 'calc(44vh)',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    workListTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '50%',
    },
  }),
);

const ColorToggleButton = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 600,
    flexGrow: 1,
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(ToggleButton);

type FormData = {
  clothingTypeName: string;
};

type LocationState = {
  workProcess: WorkProcess;
  orderId: string;
  clothingId: string;
  tagNo: string;
  syncClothingOnly?: boolean;
};

const RegistClothingPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { errors, setError, clearError } = useForm<FormData>();
  const classes = useStyles();
  const location = useLocation<LocationState>();
  const {
    workProcess,
    orderId,
    clothingId,
    tagNo,
    syncClothingOnly,
  } = location.state;
  const [orderDto, setOrderDto] = useState<OrderDto>();
  const [clothingDto, setClothingDto] = useState<ClothingDto>();
  const [workListDto, setWorkListDto] = useState<WorkListDto[]>([]);
  const [workCount, setWorkCount] = useState(0);
  const [notYetCount, setNotYetCount] = useState(0);
  const [instructionPhoto1, setInstructionPhoto1] = useState('');
  const [instructionPhoto2, setInstructionPhoto2] = useState('');
  const [
    isOpenSelectClothingTypeDialog,
    setIsOpenSelectClothingTypeDialog,
  ] = useState(false);
  const [hasAccessories, setHasAccessories] = useState<boolean | undefined>();
  const [clothingType, setClothingType] = useState<ClothingTypeDto>();
  const { openDialog, dialogProps } = useDialog();
  const workProcessName =
    workProcess === WorkProcess.Confirmation
      ? workProcesses[WorkProcess.Confirmation].title
      : '作業';

  dispatch(setTitle('衣類情報登録'));

  useEffect(() => {
    // 衣類種別が選択されたときエラーを消す
    clearError('clothingTypeName');
  }, [clearError, clothingType]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());
        let order!: OrderDto | undefined;
        let clothing: ClothingDto | undefined;

        if (clothingId) {
          // 衣類Id指定 (注文情報登録を経由して遷移、衣類を選択)
          clothing = await getClothing(clothingId);
          order = clothing.order;
        } else if (tagNo) {
          // タグNo指定
          if (orderId) {
            // 注文Id指定 (注文情報登録を経由して遷移、バーコード読み取りまたは未登録を選択)
            order = await getOrder(orderId);
            clothing = await getClothingByConditions({
              tagNo,
              orderNo: order.orderNo,
            });
          } else {
            // タグNo指定(注文情報登録を経由せずに遷移)
            clothing = await getClothingByConditions({ tagNo });
            if (clothing) {
              if (clothing.orderId) {
                order = clothing.order;
              } else if (clothing.orderNo) {
                order = await getOrderByNo(clothing.orderNo);
              }
            } else {
              // エラーメッセージダイアログを表示
              dispatch(endLoading());
              openDialog({
                title: 'エラーが発生しました。',
                content: '事前に注文情報を登録してください。',
                closeAction: () => {
                  history.goBack();
                },
              });

              return;
            }
          }
        }

        setOrderDto(order);
        setClothingDto(clothing || { tagNo });
        setInstructionPhoto1(clothing?.instructionPhoto1 || '');
        setInstructionPhoto2(clothing?.instructionPhoto2 || '');
        setHasAccessories(clothing?.hasAccessories || false);
        setClothingType(clothing?.clothingType);

        if (clothing?.clothingId) {
          let works = await getWorksByClothingId(clothing.clothingId);
          let workCnt = 0;
          let notYetCnt = 0;

          // ログインユーザーの担当工程が修理 or 染み抜きの場合は、
          // 作業明細のフィルタリング（担当工程の作業明細のみを表示）を行う
          if (
            workProcess === WorkProcess.Repair ||
            workProcess === WorkProcess.StainRemoval
          ) {
            works = works.filter(
              work => work.workType === WorkType[workProcess],
            );
            // 作業件数
            workCnt = works.length;
            // 作業未実施の件数
            notYetCnt = works.filter(
              work => work.workingResult === WorkingResult.NotYet,
            ).length;
          } else if (workProcess === WorkProcess.Confirmation) {
            // 作業件数
            workCnt = works.length;
            // 検査未実施の件数
            notYetCnt = works.filter(
              work => work.confirmationResult === ConfirmationResult.NotYet,
            ).length;
          }
          setWorkCount(workCnt);
          setNotYetCount(notYetCnt);
          setWorkListDto(works);
        }
        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('衣類情報を取得できませんでした。', e, true),
          ),
        );
      }
    })();
  }, [clothingId, dispatch, history, openDialog, orderId, tagNo, workProcess]);

  /**
   * 入力項目が変更されているか
   */
  const wasChanged = useCallback(() => {
    return (
      clothingType !== clothingDto?.clothingType ||
      hasAccessories !== (clothingDto?.hasAccessories || false) ||
      instructionPhoto1 !== (clothingDto?.instructionPhoto1 || '') ||
      instructionPhoto2 !== (clothingDto?.instructionPhoto2 || '')
    );
  }, [
    clothingDto,
    clothingType,
    hasAccessories,
    instructionPhoto1,
    instructionPhoto2,
  ]);

  /**
   * 指示書のアップロード
   */
  const uploadInstructionPhotos = useCallback(
    async (uploadClothingId: string) => {
      let updateClothingPhotosDto: UpdateClothingDto | undefined;

      // 指示書1
      const fileName1 = `${orderId ||
        orderDto?.orderId}/${uploadClothingId}/${uploadClothingId}-1`;
      if (isBase64Image(instructionPhoto1)) {
        const instructionPhoto1Key = await uploadS3Base64Image(
          instructionPhoto1,
          fileName1,
        );
        if (instructionPhoto1Key) {
          updateClothingPhotosDto = { instructionPhoto1: instructionPhoto1Key };
        }
      } else if (
        instructionPhoto1 === '' &&
        instructionPhoto1 !== (clothingDto?.instructionPhoto1 || '')
      ) {
        await removeS3Image(fileName1);
        updateClothingPhotosDto = { instructionPhoto1: null };
      }

      // 指示書2
      const fileName2 = `${orderId ||
        orderDto?.orderId}/${uploadClothingId}/${uploadClothingId}-2`;
      if (isBase64Image(instructionPhoto2)) {
        const instructionPhoto2Key = await uploadS3Base64Image(
          instructionPhoto2,
          fileName2,
        );
        if (instructionPhoto2Key) {
          updateClothingPhotosDto = {
            instructionPhoto2: instructionPhoto2Key,
            ...updateClothingPhotosDto,
          };
        }
      } else if (
        instructionPhoto2 === '' &&
        instructionPhoto2 !== (clothingDto?.instructionPhoto2 || '')
      ) {
        await removeS3Image(fileName2);
        updateClothingPhotosDto = {
          instructionPhoto2: null,
          ...updateClothingPhotosDto,
        };
      }

      return updateClothingPhotosDto;
    },
    [clothingDto, instructionPhoto1, instructionPhoto2, orderDto, orderId],
  );

  /**
   * 衣類情報登録
   */
  const registerClothing = useCallback(async () => {
    let clothing: ClothingDto | undefined;
    try {
      if (!clothingType?.clothingTypeId) {
        setError([
          {
            type: 'required',
            name: 'clothingTypeName',
            message: '衣類種別を選んでください。',
          },
        ]);

        return undefined;
      }
      dispatch(startLoading());

      let order: OrderDto | undefined;
      if (!orderDto?.orderId) {
        // orderDto.orderIdがない(Orderなし・SyncOrderあり)場合、Orderにデータ登録
        order = await createOrder({
          orderNo: orderDto?.orderNo || 0,
          startedAt: orderDto?.startedAt,
        });
      } else {
        order = orderDto;
      }

      if (!clothingDto || !clothingDto.clothingId) {
        // clothingDtoがない(Clothing・SyncClothingともになし)
        // またはclothingDto.clothingIdがない(Clothingなし・SyncClothingあり)場合、新規登録
        const createClothingDto: CreateClothingDto = {
          orderId: order?.orderId || '',
          orderNo: order?.orderNo || 0,
          branchNo: clothingDto?.branchNo || 9999,
          tagNo,
          hasAccessories,
          clothingTypeId: clothingType?.clothingTypeId,
        };
        clothing = await createClothing(createClothingDto);
        if (clothing.clothingId) {
          // 写真が登録されている場合はアップロードして更新する
          const uploadInstructionPhotosDto = await uploadInstructionPhotos(
            clothing.clothingId,
          );
          if (uploadInstructionPhotosDto) {
            clothing = await updateClothing(
              clothing.clothingId,
              uploadInstructionPhotosDto,
            );
          }
          await updateOrderEndedAt(order?.orderId);
          dispatch(openSnackbar({ text: '衣類情報を登録しました。' }));
        }
      } else if (clothingDto?.clothingId) {
        if (wasChanged()) {
          // clothingDto.clothingIdがある(Clothingあり)場合で項目が変更されている場合、更新
          const updateClothingPhotosDto = await uploadInstructionPhotos(
            clothingDto?.clothingId,
          );
          const updateClothingDto: UpdateClothingDto = {
            hasAccessories,
            clothingTypeId: clothingType?.clothingTypeId,
            ...updateClothingPhotosDto,
          };
          clothing = await updateClothing(
            clothingDto.clothingId,
            updateClothingDto,
          );
          await updateOrderEndedAt(order?.orderId);
          dispatch(openSnackbar({ text: '衣類情報を更新しました。' }));
        } else {
          // 変更なし
          clothing = clothingDto;
        }
      }
      dispatch(endLoading());

      return clothing;
    } catch (e) {
      dispatch(
        failLoading(createErrorObject('衣類情報を登録できませんでした。', e)),
      );
    }

    return undefined;
  }, [
    clothingDto,
    clothingType,
    dispatch,
    hasAccessories,
    orderDto,
    setError,
    tagNo,
    uploadInstructionPhotos,
    wasChanged,
  ]);

  /**
   * 付属品有無選択
   * @param event
   * @param newHasAccessories
   */
  const selectHasAccessories = (
    event: React.MouseEvent<HTMLElement>,
    newHasAccessories: boolean,
  ) => {
    setHasAccessories(newHasAccessories);
  };

  /**
   * 作業登録しない
   */
  const readTag = useCallback(async () => {
    const clothing = await registerClothing();
    if (clothing) {
      // 検品担当者の場合は注文IDと受注番号を渡して遷移
      if (workProcess === WorkProcess.Inspection) {
        history.push('/inspection/readtag', {
          workProcess,
          orderId: orderId || orderDto?.orderId,
          orderNo: orderDto?.orderNo,
        });
      } else {
        history.push(`/${workProcess.toLowerCase()}/readtag`, { workProcess });
      }
    }
  }, [history, orderDto, orderId, registerClothing, workProcess]);

  /**
   * 次のタグ読取への遷移確認ダイアログ
   * 作業未実施の明細が存在する場合に実行する
   */
  const openConfirmDialog = useCallback(() => {
    openDialog({
      title: '衣類タグ読取へ移動します。',
      content: (
        <>
          すべての{workProcessName}が完了していませんが
          <br />
          このまま衣類タグ読取へ移動してもよろしいでしょうか？
        </>
      ),
      buttonTitle: '移動する',
      action: readTag,
    });
  }, [openDialog, workProcessName, readTag]);

  /**
   * 作業登録へ
   * @param workType 作業種別
   * @param workId 作業Id
   */
  const registWork = useCallback(
    async (workType: WorkType, workId?: string) => {
      const clothing = await registerClothing();
      if (clothing) {
        history.push(`/${workProcess.toLowerCase()}/registwork`, {
          workProcess,
          orderId,
          clothingId: clothing?.clothingId,
          workId,
          workType,
        });
      }
    },
    [history, orderId, registerClothing, workProcess],
  );

  /**
   * 作業結果登録へ
   */
  const registResult = useCallback(
    (workType: WorkType, workId: string) => {
      history.push(`/${workProcess.toLowerCase()}/registresult`, {
        workProcess,
        workType,
        workId,
      });
    },
    [history, workProcess],
  );

  /**
   * 戻る
   */
  const backReadTag = useCallback(() => {
    // 検品担当者の場合、注文IDと受注番号を遷移先に渡す
    if (workProcess === WorkProcess.Inspection) {
      history.push('/inspection/readtag', {
        workProcess,
        orderId: orderId || orderDto?.orderId,
        orderNo: orderDto?.orderNo,
      });
    } else {
      history.push(`/${workProcess.toLowerCase()}/readtag`, { workProcess });
    }
  }, [history, orderDto, orderId, workProcess]);

  /**
   * 削除実行
   */
  const executeDelete = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (clothingDto?.clothingId) {
        await updateOrderEndedAt(orderDto?.orderId);
        await deleteClothing(clothingDto.clothingId);
        dispatch(
          openSnackbar({
            text: '衣類情報を削除しました。',
          }),
        );
        backReadTag();
      } else if (
        orderDto?.orderId &&
        orderDto?.orderNo &&
        clothingDto?.branchNo
      ) {
        // syncClothingデータ上のみで存在する衣類情報の削除処理
        const createClothingDto: CreateClothingDto = {
          orderId: orderDto.orderId,
          orderNo: orderDto.orderNo,
          branchNo: clothingDto.branchNo,
          tagNo,
        };
        const clothing = await createClothing(createClothingDto);
        if (clothing.clothingId) {
          await updateOrderEndedAt(orderDto?.orderId);
          await deleteClothing(clothing.clothingId);
          dispatch(
            openSnackbar({
              text: '衣類情報を削除しました。',
            }),
          );
          backReadTag();
        }
      }
      dispatch(endLoading());
    } catch (e) {
      dispatch(
        failLoading(createErrorObject('衣類情報を削除できませんでした。', e)),
      );
    }
  }, [backReadTag, clothingDto, dispatch, orderDto, tagNo]);

  /**
   * 削除確認ダイアログを表示
   */
  const openDeleteConfirmDialog = useCallback(() => {
    openDialog({
      title: '衣類情報を削除します。',
      content: (
        <>
          削除した衣類情報を元に戻すことはできません。
          <br />
          削除してもよろしいですか？
        </>
      ),
      buttonTitle: '削除',
      action: executeDelete,
    });
  }, [executeDelete, openDialog]);

  /**
   * 戻るボタン押下時の確認ダイアログを表示
   */
  const openBackConfirmDialog = useCallback(() => {
    if (wasChanged()) {
      openDialog({
        title: '衣類タグ読取へ戻ります。',
        content: '入力中の内容を破棄し、衣類タグ読取へ戻りますか？',
        buttonTitle: '戻る',
        action: backReadTag,
      });
    } else {
      backReadTag();
    }
  }, [backReadTag, openDialog, wasChanged]);

  /**
   * 衣類種別選択ダイアログを表示
   */
  const openSelectClothingTypeDialog = () => {
    setIsOpenSelectClothingTypeDialog(true);
  };

  /**
   * 衣類種別選択ダイアログを閉じる
   */
  const closeSelectClothingTypeDialog = () => {
    setIsOpenSelectClothingTypeDialog(false);
  };

  /**
   * 衣類種別を選択
   * @param clothingTypeDto 衣類種別
   */
  const selectClothingType = (clothingTypeDto: ClothingTypeDto) => {
    setClothingType(clothingTypeDto);
    setIsOpenSelectClothingTypeDialog(false);
  };

  return (
    <>
      <CustomDialog {...dialogProps} />
      <WorkArea>
        <ContentsArea>
          <Grid
            container
            spacing={5}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              container
              item
              xs={6}
              spacing={2}
              className={classes.inputArea}
            >
              <Grid item xs={8}>
                <OrderNoField orderNo={orderDto?.orderNo} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="tag_no"
                  label="タグ番号"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={clothingDto?.tagNo ? clothingDto?.tagNo : ' '}
                  inputProps={{
                    'data-cy': 'tag-no-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <ToggleButtonGroup
                  size="large"
                  exclusive
                  onChange={selectHasAccessories}
                  value={hasAccessories}
                  className={classes.toggleGroup}
                  data-cy="hasAccessories-buttons"
                >
                  <ColorToggleButton
                    value={false}
                    data-cy="hasAccessories-false-button"
                  >
                    付属品なし
                  </ColorToggleButton>
                  <ColorToggleButton value data-cy="hasAccessories-true-button">
                    付属品あり
                  </ColorToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={12} className={classes.clothingTypeBox}>
                <CustomCard
                  className={`${classes.customCard}
                  ${clothingType?.clothingTypePictureFront || classes.forcus}
                  ${errors.clothingTypeName && classes.error}`}
                >
                  <CardActionArea
                    onClick={openSelectClothingTypeDialog}
                    data-cy="clothing-type-image"
                  >
                    <Typography
                      component="p"
                      variant="h3"
                      className={`
                      ${classes.clothingTypeLabel}
                      ${clothingType?.clothingTypePictureFront ||
                        classes.clothingTypeForcus}
                      ${errors.clothingTypeName && classes.clothingTypeError}
                      ${clothingType?.clothingTypePictureFront &&
                        classes.clothingTypeSelected}
                      `}
                      data-cy="clothing-type-input-label"
                    >
                      衣類種別 *
                    </Typography>
                    <Box pt={2} pl={2} pr={2}>
                      {clothingType?.clothingTypePictureFront ? (
                        <CardMedia
                          image={clothingType?.clothingTypePictureFront}
                          className={classes.media}
                        />
                      ) : (
                        <Box className={classes.media} />
                      )}
                    </Box>
                    <CardContent className={classes.cardContent}>
                      <Box pt={1} pb={2}>
                        <Typography
                          component="p"
                          variant="h4"
                          data-cy="clothing-type-label"
                        >
                          {clothingType?.clothingTypeName}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </CustomCard>
                {errors.clothingTypeName && (
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.errorMessage}
                    data-cy="clothing-type-error"
                  >
                    {errors.clothingTypeName.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              container
              spacing={2}
              className={classes.resetPb}
              justify="flex-end"
            >
              <Grid item xs={11} container spacing={2} justify="flex-end">
                <Grid item xs={4}>
                  <Photo
                    image={orderDto?.formPhoto}
                    caption="申込書"
                    readonly
                    level="protected"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Photo
                    caption="指示書"
                    image={instructionPhoto1}
                    setImage={setInstructionPhoto1}
                    readonly={workProcess !== WorkProcess.Inspection}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Photo
                    caption="指示書"
                    image={instructionPhoto2}
                    setImage={setInstructionPhoto2}
                    readonly={workProcess !== WorkProcess.Inspection}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.resetPt}>
              <Box mb={1} className={classes.workListTitle}>
                <Typography component="p" variant="h2">
                  <Box component="span" color="text.primary" fontWeight={600}>
                    作業内容
                  </Box>
                </Typography>
                {workCount > 0 &&
                  (workProcess === WorkProcess.Repair ||
                    workProcess === WorkProcess.StainRemoval ||
                    workProcess === WorkProcess.Confirmation) && (
                    <Typography component="p" variant="h5">
                      <Box
                        component="span"
                        color="text.primary"
                        fontWeight={600}
                        data-cy="work-count"
                      >
                        {`${workProcessName}待ち：${notYetCount}件 （全 ${workCount}件）`}
                      </Box>
                    </Typography>
                  )}
              </Box>

              <Divider className={classes.divider} />

              {workListDto.length > 0 ? (
                <Grid
                  container
                  item
                  wrap="wrap"
                  xs={12}
                  spacing={2}
                  className={classes.workListArea}
                  data-cy="work-list"
                >
                  {workListDto.map(work => (
                    <Grid
                      item
                      className={classes.item}
                      key={work.workId}
                      data-cy="work-detail"
                    >
                      <WorkDetail
                        workId={work.workId}
                        workType={work.workType}
                        workContentName={work.workContentName}
                        workSide={work.workSide}
                        workLocation={work.workLocation}
                        clothingTypePicture={
                          work.workSide === WorkSide.Back
                            ? clothingDto?.clothingType?.clothingTypePictureBack
                            : clothingDto?.clothingType
                                ?.clothingTypePictureFront
                        }
                        workingResult={work.workingResult}
                        confirmationResult={work.confirmationResult}
                        onClick={
                          workProcess === WorkProcess.Inspection
                            ? registWork
                            : registResult
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <NoWorkMessage />
                </Grid>
              )}
            </Grid>
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            <ActionButton
              onClick={notYetCount === 0 ? readTag : openConfirmDialog}
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              data-cy="read-tag-button"
            >
              次の衣類登録へ
            </ActionButton>
            {(workProcess === WorkProcess.Inspection ||
              workProcess === WorkProcess.Confirmation) && (
              <ActionButton
                onClick={() => registWork(WorkType.LintRemoval)}
                color="Inspection"
                endIcon={<ArrowForwardIosIcon />}
                data-cy="regist-lintRemova-button"
              >
                毛玉取り登録へ
              </ActionButton>
            )}
            {(workProcess === WorkProcess.Inspection ||
              workProcess === WorkProcess.Repair ||
              workProcess === WorkProcess.Confirmation) && (
              <ActionButton
                onClick={() => registWork(WorkType.Repair)}
                color="repair"
                endIcon={<ArrowForwardIosIcon />}
                data-cy="regist-repair-button"
              >
                修理登録へ
              </ActionButton>
            )}
            {(workProcess === WorkProcess.Inspection ||
              workProcess === WorkProcess.StainRemoval ||
              workProcess === WorkProcess.Confirmation) && (
              <ActionButton
                onClick={() => registWork(WorkType.StainRemoval)}
                color="stainRemoval"
                endIcon={<ArrowForwardIosIcon />}
                data-cy="regist-stainRemoval-button"
              >
                染み/汚れ登録へ
              </ActionButton>
            )}
          </Grid>
          <Grid item>
            {(clothingDto?.clothingId || syncClothingOnly) &&
              workProcess === WorkProcess.Inspection && (
                <ActionButton
                  onClick={openDeleteConfirmDialog}
                  color="delete"
                  endIcon={<DeleteIcon />}
                  data-cy="delete-clothing-button"
                >
                  衣類情報削除
                </ActionButton>
              )}
            <ActionButton
              onClick={openBackConfirmDialog}
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              data-cy="back-button"
            >
              戻る
            </ActionButton>
          </Grid>
        </ButtonsArea>
      </WorkArea>
      <ClothingTypeDialog
        open={isOpenSelectClothingTypeDialog}
        handleClose={closeSelectClothingTypeDialog}
        handleSelect={selectClothingType}
      />
    </>
  );
};

export default RegistClothingPage;
