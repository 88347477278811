import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTitle,
  startLoading,
  failLoading,
  createErrorObject,
  endLoading,
} from 'modules/commonModule';
import ContentsArea from 'components/Admin/common/ContentsArea';
import { Box, Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import ActionButton from 'components/Admin/common/ActionButton';
import { OrderAdminDto } from 'api/orders/orders.dto';
import {
  ConfirmationResult,
  WorkAdminDto,
  WorkType,
} from 'api/works/works.dto';
import { getOrderListByConditions } from 'api/orders/orders.api';
import { getWorkListByConditions } from 'api/works/works.api';
import { confirmationResults, workTypes } from 'app/constants';
import moment from 'moment';
import CustomDialog, { useMsgDialog } from 'components/common/CustomDialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UserInfoDto } from 'api/users/users.dto';
import { getUserInfoList } from 'modules/mastersModule';
import { RootState } from 'app/rootReducer';
import { useForm } from 'react-hook-form';
import CustomTextField from '../common/CustomTextField';
import OrderInfoTable from './OrderInfoTable';
import WorkInfoTable from './WorkInfoTable';
import CustomToggleButtons from '../common/CustomToggleButtons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleGroup: {
      width: '100%',
    },
    gridItem: {
      maxWidth: '450px',
    },
    gridFrom: {
      paddingRight: theme.spacing(1),
    },
    gridTo: {
      paddingLeft: theme.spacing(1),
    },
    paging: {
      '& div': {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
    activePage: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }),
);

export type OrderSearchForm = {
  /** 作業種別 */
  workType?: WorkType[];
  /** 作業残 */
  isRemaining?: boolean;
  /** 担当者名 */
  workedByUser: UserInfoDto | null;
  /** 注文日（FROM） */
  orderedAtFrom?: string | null;
  /** 注文日（TO） */
  orderedAtTo?: string | null;
  /** 検品終了日時（FROM） */
  inspectedAtFrom?: string | null;
  /** 検品終了日時（TO） */
  inspectedAtTo?: string | null;
  /** 検品結果 */
  confirmationResult?: ConfirmationResult[];
  /** 受付番号（FROM） */
  orderNoFrom?: string | null;
  /** 受付番号（TO） */
  orderNoTo?: string | null;
  /** タグ番号（FROM） */
  tagNoFrom?: string | null;
  /** タグ番号（TO） */
  tagNoTo?: string | null;
  /** 希望納期（FROM） */
  deliveryDateFrom?: string | null;
  /** 希望納期（TO） */
  deliveryDateTo?: string | null;
  /** 検査完了日時（FROM） ※全衣類の全作業の検査完了した日時 */
  confirmedAtFrom?: string | null;
  /** 検査完了日時（TO）  ※全衣類の全作業の検査完了した日時 */
  confirmedAtTo?: string | null;
  /** ECサイト注文番号 */
  ecOrderNo?: string | null;
};

const OrderSearchPage: FC<{}> = () => {
  const dispatch = useDispatch();
  const { userInfoList } = useSelector((state: RootState) => state.masters);
  const classes = useStyles();
  const { openMsgDialog, msgDialogProps } = useMsgDialog();
  const [allCount, setAllCount] = useState<number>(0);
  const [orderInfoListDto, setOrderInfoListDto] = useState<OrderAdminDto[]>([]);
  const [workInfoListDto, setWorkInfoListDto] = useState<WorkAdminDto[]>([]);
  const [orderPage, setOrderPage] = useState<number>(1);
  const [workPage, setWorkPage] = useState<number>(1);
  const [isWorkPage, setIsWorkPage] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(1000);
  const [conditions, setConditions] = useState<OrderSearchForm>({
    workedByUser: null,
  });
  const formRef = useRef<HTMLFormElement>(null);
  const { register, getValues, reset, errors, setError, clearError } = useForm<
    OrderSearchForm
  >({
    defaultValues: {
      orderedAtFrom: null,
      orderedAtTo: null,
      inspectedAtFrom: moment()
        .utc()
        .local()
        .format('YYYY-MM-DDT00:00'),
      inspectedAtTo: null,
      orderNoFrom: null,
      orderNoTo: null,
      tagNoFrom: null,
      tagNoTo: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      confirmedAtFrom: null,
      confirmedAtTo: null,
      ecOrderNo: null,
    },
  });

  // ページタイトル
  dispatch(setTitle('注文検索/作業検索'));

  /* 検索フォーム操作系
  -------------------------------------------------------------- */
  /** カーソルが離れた時 */
  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.currentTarget.checkValidity()) {
      clearError(event.currentTarget.name);
    }
  };

  /** 値が変更された時 */
  const handleChange = (
    name: string,
    value: string,
    currentTarget:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement),
  ) => {
    if (
      errors[name as keyof OrderSearchForm] &&
      currentTarget.checkValidity()
    ) {
      clearError(currentTarget.name);
    }
  };

  /** 入力エラーがあった時 */
  const handleInvalid = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    message: string,
  ) => {
    setError(event.currentTarget.name, 'manual', message);
  };

  /**
   * 検索条件（担当者）の選択値をstateにセット */
  const handleInputUser = useCallback(
    (workedByUser: UserInfoDto | null) => {
      setConditions({ ...conditions, workedByUser });
    },
    [conditions],
  );

  /**
   * 検索条件（ボタンフォーム）の選択値をstateにセット */
  const handleWorkType = useCallback(
    (workType: WorkType[]) => {
      setConditions({ ...conditions, workType });
    },
    [conditions],
  );

  const handleIsRemaining = useCallback(
    (isRemaining: boolean) => {
      setConditions({ ...conditions, isRemaining });
    },
    [conditions],
  );

  const handleConfirmationResult = useCallback(
    (confirmationResult: ConfirmationResult[]) => {
      setConditions({ ...conditions, confirmationResult });
    },
    [conditions],
  );

  /**
   * 検索条件リセット */
  const conditionReset = useCallback(() => {
    reset({});
    setConditions({ workedByUser: null });
  }, [reset]);

  // ToggleButton用 データリスト
  const workTypeDataList = useMemo(() => {
    return [
      {
        value: WorkType.LintRemoval,
        buttonLabel: workTypes[WorkType.LintRemoval].title,
        dataCy: 'lintRemoval-button',
      },
      {
        value: WorkType.Repair,
        buttonLabel: workTypes[WorkType.Repair].title,
        dataCy: 'repair-button',
      },
      {
        value: WorkType.StainRemoval,
        buttonLabel: workTypes[WorkType.StainRemoval].title,
        dataCy: 'stainRemoval-button',
      },
    ];
  }, []);

  // ToggleButton用 データリスト
  const isRemainingDataList = useMemo(() => {
    return [
      {
        value: true,
        buttonLabel: '残あり',
        dataCy: 'isRemainingTrue-button',
      },
      {
        value: false,
        buttonLabel: '残なし',
        dataCy: 'isRemainingFalse-button',
      },
    ];
  }, []);

  // ToggleButton用 データリスト
  const confirmationResultDataList = useMemo(() => {
    return [
      {
        value: ConfirmationResult.NotYet,
        buttonLabel: confirmationResults[ConfirmationResult.NotYet].label,
        dataCy: 'confirmationResultNotYet-button',
      },
      {
        value: ConfirmationResult.Ok,
        buttonLabel: confirmationResults[ConfirmationResult.Ok].label,
        dataCy: 'confirmationResultOk-button',
      },
      {
        value: ConfirmationResult.Ng,
        buttonLabel: confirmationResults[ConfirmationResult.Ng].label,
        dataCy: 'confirmationResultNg-button',
      },
    ];
  }, []);

  /**
   * APiリクエスト用のページ番号をstateにセット */
  const setNextPage = useCallback((pageNo: number, isWork?: boolean) => {
    if (isWork) {
      setWorkPage(pageNo);
    } else {
      setOrderPage(pageNo);
    }
  }, []);

  /**
   * APiリクエスト用のページごと表示件数をstateにセット */
  const setNewPerPage = useCallback((newPerPage: number) => {
    setPerPage(newPerPage);
  }, []);

  /**
   * 日付パラメータをAPI用に書き換える */
  const modifyDateParam = (form: OrderSearchForm) => {
    const modifiedForm = { ...form };
    if (modifiedForm.orderedAtFrom) {
      modifiedForm.orderedAtFrom = moment(modifiedForm.orderedAtFrom)
        .startOf('day')
        .toISOString();
    }
    if (modifiedForm.orderedAtTo) {
      modifiedForm.orderedAtTo = moment(modifiedForm.orderedAtTo)
        .endOf('day')
        .toISOString();
    }
    if (modifiedForm.inspectedAtFrom) {
      modifiedForm.inspectedAtFrom = moment(
        modifiedForm.inspectedAtFrom,
      ).toISOString();
    }
    if (modifiedForm.inspectedAtTo) {
      modifiedForm.inspectedAtTo = moment(modifiedForm.inspectedAtTo)
        .add(59, 'second')
        .add(999, 'millisecond')
        .toISOString();
    }
    if (modifiedForm.deliveryDateFrom) {
      modifiedForm.deliveryDateFrom = moment(modifiedForm.deliveryDateFrom)
        .startOf('day')
        .toISOString();
    }
    if (modifiedForm.deliveryDateTo) {
      modifiedForm.deliveryDateTo = moment(modifiedForm.deliveryDateTo)
        .endOf('day')
        .toISOString();
    }
    if (modifiedForm.confirmedAtFrom) {
      modifiedForm.confirmedAtFrom = moment(
        modifiedForm.confirmedAtFrom,
      ).toISOString();
    }
    if (modifiedForm.confirmedAtTo) {
      modifiedForm.confirmedAtTo = moment(modifiedForm.confirmedAtTo)
        .add(59, 'second')
        .add(999, 'millisecond')
        .toISOString();
    }

    return modifiedForm;
  };

  /**
   * 注文情報検索 */
  const orderSearch = useCallback(
    (orderSearchForm?: OrderSearchForm) => {
      (async () => {
        try {
          if (formRef.current && !formRef.current.checkValidity()) {
            openMsgDialog({
              title: '注文情報検索',
              content: '正しい検索条件を入力してください。',
            });

            return;
          }

          dispatch(startLoading());

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { workedByUser, ...orderConditions } = {
            page: orderPage,
            perPage,
            workedByName: orderSearchForm?.workedByUser?.userName,
            ...orderSearchForm,
            ...modifyDateParam(getValues()),
          };

          const orderInfoList = await getOrderListByConditions(orderConditions);

          if (orderInfoList?.count) {
            setWorkInfoListDto([]); // 作業情報結果を空に設定
            setAllCount(orderInfoList.count);
            setOrderInfoListDto(orderInfoList.orderAdminList);
          } else {
            // データが0件だった場合
            setWorkInfoListDto([]); // 作業情報結果を空に設定
            setOrderInfoListDto([]); // 注文情報結果を空に設定
            openMsgDialog({
              title: '注文情報検索',
              content: '該当する注文情報がありませんでした。',
            });
          }

          // 作業一覧表示フラグを設定
          setIsWorkPage(false);
          setWorkPage(1);

          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('注文情報を取得できませんでした。', e),
            ),
          );
        }
      })();
    },
    [dispatch, getValues, openMsgDialog, orderPage, perPage],
  );

  /**
   * 作業情報検索 */
  const workSearch = useCallback(
    (orderSearchForm?: OrderSearchForm) => {
      (async () => {
        try {
          if (formRef.current && !formRef.current.checkValidity()) {
            openMsgDialog({
              title: '作業情報検索',
              content: '正しい検索条件を入力してください。',
            });

            return;
          }

          dispatch(startLoading());

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { workedByUser, ...orderConditions } = {
            page: workPage,
            perPage,
            workedByName: orderSearchForm?.workedByUser?.userName,
            ...orderSearchForm,
            ...modifyDateParam(getValues()),
          };

          const workInfoList = await getWorkListByConditions(orderConditions);

          if (workInfoList?.count) {
            setOrderInfoListDto([]); // 注文情報結果を空に設定
            setAllCount(workInfoList.count);
            setWorkInfoListDto(workInfoList.workAdminList);
          } else {
            // データが0件だった場合
            setOrderInfoListDto([]); // 注文情報結果を空に設定
            setWorkInfoListDto([]); // 作業情報結果を空に設定
            openMsgDialog({
              title: '作業情報検索',
              content: '該当する作業情報がありませんでした。',
            });
          }

          // 作業一覧表示フラグを設定
          setIsWorkPage(true);
          setOrderPage(1);

          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('作業情報を取得できませんでした。', e),
            ),
          );
        }
      })();
    },
    [dispatch, getValues, openMsgDialog, perPage, workPage],
  );

  useEffect(() => {
    dispatch(getUserInfoList());
  }, [dispatch]);

  useEffect(() => {
    if (isWorkPage) {
      workSearch();
    } else {
      orderSearch();
    }
  }, [isWorkPage, orderSearch, workSearch]);

  return (
    <>
      <CustomDialog {...msgDialogProps} />
      <ContentsArea>
        <form ref={formRef} autoComplete="off">
          <Grid container spacing={2} style={{ width: '100%' }}>
            <Grid item xs={12}>
              <Box
                p={2}
                borderRadius={5}
                bgcolor="#fff"
                boxShadow={1}
                height="100%"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={4}>
                    <CustomToggleButtons
                      label="作業種別"
                      value={conditions.workType || []}
                      dataList={workTypeDataList}
                      onChange={handleWorkType}
                    />
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <CustomToggleButtons
                      label="作業残"
                      value={conditions.isRemaining}
                      dataList={isRemainingDataList}
                      onChange={handleIsRemaining}
                      exclusive
                    />
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Autocomplete
                      value={conditions.workedByUser}
                      options={userInfoList}
                      getOptionLabel={option => option.userName}
                      getOptionSelected={(option, value) => option === value}
                      size="small"
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        selectedValue: UserInfoDto | null,
                      ) => {
                        handleInputUser(selectedValue);
                      }}
                      renderInput={params => (
                        <CustomTextField
                          {...params}
                          label="担当者名"
                          name="workedByName"
                        />
                      )}
                      data-cy="workedByName-autocomplete"
                    />
                  </Grid>
                  <Grid container item justify="space-between" xs={6} lg={4}>
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        label="注文日"
                        type="date"
                        name="orderedAtFrom"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日付を入力してください。'),
                          'data-cy': 'orderedAtFrom-input',
                        }}
                        helperText={
                          errors.orderedAtFrom
                            ? errors.orderedAtFrom.message
                            : ''
                        }
                        error={!!errors.orderedAtFrom}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        type="date"
                        name="orderedAtTo"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日付を入力してください。'),
                          'data-cy': 'orderedAtTo-input',
                        }}
                        helperText={
                          errors.orderedAtTo ? errors.orderedAtTo.message : ''
                        }
                        error={!!errors.orderedAtTo}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item justify="space-between" xs={6} lg={4}>
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        type="datetime-local"
                        label="検品日時"
                        name="inspectedAtFrom"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31T23:59',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日時を入力してください。'),
                          'data-cy': 'inspectedAtFrom-input',
                        }}
                        helperText={
                          errors.inspectedAtFrom
                            ? errors.inspectedAtFrom.message
                            : ''
                        }
                        error={!!errors.inspectedAtFrom}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        type="datetime-local"
                        name="inspectedAtTo"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31T23:59',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日時を入力してください。'),
                          'data-cy': 'inspectedAtTo-input',
                        }}
                        helperText={
                          errors.inspectedAtTo
                            ? errors.inspectedAtTo.message
                            : ''
                        }
                        error={!!errors.inspectedAtTo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <CustomToggleButtons
                      label="検査結果"
                      value={conditions.confirmationResult || []}
                      dataList={confirmationResultDataList}
                      onChange={handleConfirmationResult}
                    />
                  </Grid>
                  <Grid container item justify="space-between" xs={6} lg={4}>
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        label="受付番号"
                        type="text"
                        name="orderNoFrom"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          inputMode: 'numeric',
                          maxLength: 6,
                          pattern: '[1-9][0-9]{0,5}',
                          onInvalid: e =>
                            handleInvalid(e, '1～999999で入力してください。'),
                          'data-cy': 'orderNoFrom-input',
                        }}
                        helperText={
                          errors.orderNoFrom ? errors.orderNoFrom.message : ''
                        }
                        error={!!errors.orderNoFrom}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        type="text"
                        name="orderNoTo"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          inputMode: 'numeric',
                          maxLength: 6,
                          pattern: '[1-9][0-9]{0,5}',
                          onInvalid: e =>
                            handleInvalid(e, '1～999999で入力してください。'),
                          'data-cy': 'orderNoTo-input',
                        }}
                        helperText={
                          errors.orderNoTo ? errors.orderNoTo.message : ''
                        }
                        error={!!errors.orderNoTo}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justify="space-between" xs={6} lg={4}>
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        label="タグ番号"
                        type="text"
                        name="tagNoFrom"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          inputMode: 'numeric',
                          maxLength: 7,
                          pattern: '[0-9]{7}',
                          onInvalid: e =>
                            handleInvalid(e, '7桁の数字で入力してください。'),
                          'data-cy': 'tagNoFrom-input',
                        }}
                        helperText={
                          errors.tagNoFrom ? errors.tagNoFrom.message : ''
                        }
                        error={!!errors.tagNoFrom}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        type="text"
                        name="tagNoTo"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          inputMode: 'numeric',
                          maxLength: 7,
                          pattern: '[0-9]{7}',
                          onInvalid: e =>
                            handleInvalid(e, '7桁の数字で入力してください。'),
                          'data-cy': 'tagNoTo-input',
                        }}
                        helperText={
                          errors.tagNoTo ? errors.tagNoTo.message : ''
                        }
                        error={!!errors.tagNoTo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="space-between" xs={6} lg={4}>
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        label="希望納期"
                        type="date"
                        name="deliveryDateFrom"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日付を入力してください。'),
                          'data-cy': 'deliveryDateFrom-input',
                        }}
                        helperText={
                          errors.deliveryDateFrom
                            ? errors.deliveryDateFrom.message
                            : ''
                        }
                        error={!!errors.deliveryDateFrom}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        type="date"
                        name="deliveryDateTo"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日付を入力してください。'),
                          'data-cy': 'deliveryDateTo-input',
                        }}
                        helperText={
                          errors.deliveryDateTo
                            ? errors.deliveryDateTo.message
                            : ''
                        }
                        error={!!errors.deliveryDateTo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="space-between" xs={6} lg={4}>
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        label="検査完了日時"
                        type="datetime-local"
                        name="confirmedAtFrom"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31T23:59',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日時を入力してください。'),
                          'data-cy': 'confirmedAtFrom-input',
                        }}
                        helperText={
                          errors.confirmedAtFrom
                            ? errors.confirmedAtFrom.message
                            : ''
                        }
                        error={!!errors.confirmedAtFrom}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        type="datetime-local"
                        name="confirmedAtTo"
                        inputRef={register}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '9999-12-31T23:59',
                          onInvalid: e =>
                            handleInvalid(e, '正しい日時を入力してください。'),
                          'data-cy': 'confirmedAtTo-input',
                        }}
                        helperText={
                          errors.confirmedAtTo
                            ? errors.confirmedAtTo.message
                            : ''
                        }
                        error={!!errors.confirmedAtTo}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <CustomTextField
                      label="ECサイト注文番号"
                      type="text"
                      name="ecOrderNo"
                      inputRef={register}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        inputMode: 'numeric',
                        maxLength: 50,
                        pattern: '[0-9]{1,}',
                        onInvalid: e =>
                          handleInvalid(e, '数字で入力してください。'),
                        'data-cy': 'ecOrderNo-input',
                      }}
                      helperText={
                        errors.ecOrderNo ? errors.ecOrderNo.message : ''
                      }
                      error={!!errors.ecOrderNo}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <Box pr={2} mt={2}>
                      <ActionButton
                        color="delete"
                        data-cy="clear-button"
                        fullWidth
                        size="small"
                        onClick={conditionReset}
                      >
                        <Box component="span" width="100px">
                          条件クリア
                        </Box>
                      </ActionButton>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box pr={2} mt={2}>
                      <ActionButton
                        color="primary"
                        data-cy="searchOrder-button"
                        fullWidth
                        size="small"
                        onClick={() => orderSearch(conditions)}
                      >
                        <Box component="span" width="100px">
                          注文検索
                        </Box>
                      </ActionButton>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={2}>
                      <ActionButton
                        color="primary"
                        data-cy="searchWork-button"
                        fullWidth
                        size="small"
                        onClick={() => workSearch(conditions)}
                      >
                        <Box component="span" width="100px">
                          作業検索
                        </Box>
                      </ActionButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {Object.keys(orderInfoListDto).length ||
            Object.keys(workInfoListDto).length ? (
              <Grid item xs={12}>
                <Box
                  px={2}
                  py={1}
                  borderRadius={5}
                  bgcolor="#fff"
                  boxShadow={1}
                  height="100%"
                >
                  <>
                    {!Object.keys(orderInfoListDto).length || (
                      <OrderInfoTable
                        orderInfoList={orderInfoListDto}
                        allCount={allCount}
                        setNextPage={setNextPage}
                        setNewPerPage={setNewPerPage}
                        perPage={perPage}
                        orderSearch={() => orderSearch(conditions)}
                      />
                    )}
                    {!Object.keys(workInfoListDto).length || (
                      <WorkInfoTable
                        workInfoList={workInfoListDto}
                        allCount={allCount}
                        setNextPage={setNextPage}
                        setNewPerPage={setNewPerPage}
                        perPage={perPage}
                        workSearch={() => workSearch(conditions)}
                      />
                    )}
                  </>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </form>
      </ContentsArea>
    </>
  );
};

export default OrderSearchPage;
