import React, { FC, useCallback } from 'react';
import { TablePagination, IconButton, Box } from '@material-ui/core';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

/* material-uiコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      position: 'absolute',
      top: 0,
      left: 0,
      '& .MuiTablePagination-toolbar': {
        minHeight: '42px',
      },
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiToolbar-regular': {
        paddingLeft: theme.spacing(1),
      },
      '& .MuiTablePagination-caption': {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    paginationActions: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: any;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = useCallback(() => {
    onChangePage(1);
  }, [onChangePage]);

  const handleBackButtonClick = useCallback(() => {
    onChangePage(page);
  }, [onChangePage, page]);

  const handleNextButtonClick = useCallback(() => {
    onChangePage(page + 2);
  }, [onChangePage, page]);

  const handleLastPageButtonClick = useCallback(() => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage)));
  }, [count, onChangePage, rowsPerPage]);

  return (
    <Box className={classes.paginationActions}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

interface PaginationPropsType {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage: (newPerPage: number) => void;
  currentPage: number;
}
const CustomPagination: FC<PaginationPropsType> = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}) => {
  const classes = useStyles1();

  return (
    <>
      <TablePagination
        className={classes.pagination}
        component="nav"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[1000]}
        page={currentPage - 1}
        onChangePage={onChangePage}
        onChangeRowsPerPage={({ target }) =>
          onChangeRowsPerPage(Number(target.value))
        }
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default CustomPagination;
