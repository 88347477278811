import React, { FC, useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setTitle,
  startLoading,
  failLoading,
  createErrorObject,
  endLoading,
  openSnackbar,
} from 'modules/commonModule';
import ContentsArea from 'components/Admin/common/ContentsArea';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Theme,
  Divider,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';
import ActionButton from 'components/Admin/common/ActionButton';
import { UpdateUrlNotificationMailDto } from 'api/settings/settings.dto';
import {
  getUrlNotificationMail,
  updateUrlNotificationMail,
} from 'api/settings/settings.api';
import { useForm } from 'react-hook-form';
import CustomTextField from '../common/CustomTextField';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/* material-uiコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const StyledTabs = withStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: 'inherit',
  },
}))(Tabs);

const StyledTab = withStyles((theme: Theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))(Tab);

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  buttonGrid: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  registButtonGrid: {
    width: '130px',
    flexBasis: '130px',
  },
  tab: {
    padding: '0px',
  },
  leftTab: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid  ${theme.palette.divider}`,
    borderRight: `1px solid  ${theme.palette.divider}`,
    borderRadius: '5px 0 0 0',
  },
  rightTab: {
    borderTop: `1px solid  ${theme.palette.divider}`,
    borderRight: `1px solid  ${theme.palette.divider}`,
    borderRadius: '0 5px 0 0',
  },
  bodyTextField: {
    marginTop: 0,
    '& > div': {
      borderRadius: '0 0 5px 5px',
    },
  },
}));

type FormData = {
  sendTime: string;
  fromName: string;
  fromAddress: string;
  title: string;
  bodyInput: string;
  bodyHtmlInput: string;
};
const CustomerMailPage: FC<{}> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const textRef = useRef<HTMLInputElement | null>(null);
  const { register, handleSubmit, errors, setError, reset } = useForm<
    FormData
  >();
  const [focusElement, setFocusElement] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const [val, setVal] = React.useState(0);
  const [conditions, setConditions] = useState<{
    bodyInput: string;
    bodyHtmlInput: string;
  }>({
    bodyInput: '',
    bodyHtmlInput: '',
  });

  // ページタイトル
  dispatch(setTitle('メール関連設定（文面・送信時刻）'));

  /**
   * メール本文（テキストフォーム）の選択値をstateにセット
   */
  const handleInput = useCallback(
    (name: string, value: string) => {
      setConditions({ ...conditions, [name]: value });
    },
    [conditions],
  );

  /**
   * フォーカスされている要素を取得
   */
  const setFocus = useCallback(e => {
    setFocusElement(e.target);
  }, []);

  /**
   * 本文テキストエリアのタブ切り替え
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setVal(newValue);
    },
    [],
  );

  /**
   * カーソル位置へのプレースホルダー挿入
   */
  const insertPlaceholder = useCallback(
    (keyWord: string) => {
      if (focusElement) {
        let sentence = focusElement.value; // テキストエリアの文章
        const len = sentence.length; // 文章の文字数
        const pos = focusElement.selectionStart || 0; // カーソル位置
        const before = sentence.substr(0, pos); // カーソル前の文章
        const word = `{{${keyWord}}}`; // 挿入するプレースホルダー
        const after = sentence.substr(pos, len); // カーソル後の文章

        sentence = before + word + after;
        focusElement.value = sentence;

        // メール本文の入力内容をstateにセット
        setConditions({
          ...conditions,
          [focusElement.name]: focusElement.value,
        });

        setFocusElement(null);
      }
    },
    [conditions, focusElement],
  );

  /**
   * メール設定の更新
   */
  const onSubmit = handleSubmit(
    async ({ sendTime, fromName, fromAddress, title }) => {
      try {
        dispatch(startLoading());

        if (!conditions.bodyInput) {
          setVal(0);
          setError([
            {
              type: 'required',
              name: 'bodyInput',
              message: 'テキストメール本文を入力してください。',
            },
          ]);
        } else if (conditions.bodyInput.length > 2000) {
          setVal(0);
          setError([
            {
              type: 'maxLength',
              name: 'bodyInput',
              message: '2000文字以下で入力してください。',
            },
          ]);
        } else if (!conditions.bodyHtmlInput) {
          setVal(1);
          setError([
            {
              type: 'required',
              name: 'bodyHtmlInput',
              message: 'HTMLメール本文を入力してください。',
            },
          ]);
        } else if (conditions.bodyHtmlInput.length > 30000) {
          setVal(1);
          setError([
            {
              type: 'maxLength',
              name: 'bodyHtmlInput',
              message: '30000文字以下で入力してください。',
            },
          ]);
        } else {
          const updateUrlNotificationMailDto: UpdateUrlNotificationMailDto = {
            sendTime,
            fromName,
            fromAddress,
            title,
            body: conditions.bodyInput,
            bodyHtml: conditions.bodyHtmlInput,
          };

          const urlNotificationMail = await updateUrlNotificationMail(
            updateUrlNotificationMailDto,
          );

          if (urlNotificationMail) {
            dispatch(
              openSnackbar({
                text: 'メール設定を更新しました。',
              }),
            );
          }
        }

        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('メール設定変更でエラーが発生しました。', e),
          ),
        );
      }
    },
  );

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());

        // メール関連の設定情報を取得
        const urlNotificationMail = await getUrlNotificationMail();
        // 入力フォームの初期値設定
        reset(urlNotificationMail);

        setConditions({
          bodyInput: urlNotificationMail.body,
          bodyHtmlInput: urlNotificationMail.bodyHtml,
        });

        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('メール設定情報を取得できませんでした。', e),
          ),
        );
      }
    })();
  }, [dispatch, reset]);

  return (
    <>
      <ContentsArea>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Box
              p={3}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={1}
              height="100%"
            >
              <Box mb={4}>
                <Typography component="h2" variant="h4">
                  <Box component="span" fontWeight={600}>
                    メール関連設定（文面・送信時刻）
                  </Box>
                </Typography>
              </Box>
              <form onSubmit={onSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box maxWidth={300}>
                      <CustomTextField
                        type="time"
                        name="sendTime"
                        label="送信時刻"
                        size="small"
                        inputRef={register({
                          required: '送信時刻を入力してください。',
                        })}
                        helperText={
                          errors.sendTime ? errors.sendTime.message : ''
                        }
                        error={!!errors.sendTime}
                        inputProps={{
                          'data-cy': 'sendTime-input',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      type="text"
                      name="fromName"
                      label="送信元名"
                      size="small"
                      autoComplete="off"
                      inputRef={register({
                        required: '送信元名を入力してください。',
                        maxLength: {
                          value: 20,
                          message: '20文字以下で入力してください。',
                        },
                      })}
                      helperText={
                        errors.fromName ? errors.fromName.message : ''
                      }
                      error={!!errors.fromName}
                      inputProps={{
                        'data-cy': 'fromName-input',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      type="text"
                      name="fromAddress"
                      label="送信元メールアドレス"
                      size="small"
                      autoComplete="off"
                      inputRef={register({
                        required: '送信元メールアドレスを入力してください。',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'メールアドレスの形式で入力してください。',
                        },
                      })}
                      helperText={
                        errors.fromAddress ? errors.fromAddress.message : ''
                      }
                      error={!!errors.fromAddress}
                      inputProps={{
                        'data-cy': 'fromAddress-input',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      type="text"
                      name="title"
                      label="件名"
                      size="small"
                      autoComplete="off"
                      inputRef={e => {
                        register(e, {
                          required: '件名を入力してください。',
                          maxLength: {
                            value: 100,
                            message: '100文字以下で入力してください。',
                          },
                        });
                        textRef.current = e;
                      }}
                      helperText={errors.title ? errors.title.message : ''}
                      error={!!errors.title}
                      inputProps={{
                        'data-cy': 'title-input',
                      }}
                      onBlur={setFocus}
                    />
                  </Grid>
                  <Grid container item xs={12} justify="space-between">
                    <Grid item xs={10}>
                      <StyledTabs
                        value={val}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                      >
                        <StyledTab
                          label="テキストメール"
                          {...a11yProps(0)}
                          className={`${classes.tab} ${classes.leftTab}`}
                          data-cy="text-mail-tab"
                        />
                        <StyledTab
                          label="HTMLメール"
                          {...a11yProps(1)}
                          className={`${classes.tab} ${classes.rightTab}`}
                          data-cy="html-mail-tab"
                        />
                      </StyledTabs>

                      <TabPanel value={val} index={0}>
                        <CustomTextField
                          type="text"
                          name="bodyInput"
                          size="small"
                          defaultValue={conditions.bodyInput}
                          multiline
                          rows={26}
                          autoComplete="off"
                          className={classes.bodyTextField}
                          helperText={
                            errors.bodyInput ? errors.bodyInput.message : ''
                          }
                          error={!!errors.bodyInput}
                          inputProps={{
                            'data-cy': 'body-input',
                          }}
                          onBlur={setFocus}
                          onChange={handleInput}
                        />
                      </TabPanel>
                      <TabPanel value={val} index={1}>
                        <CustomTextField
                          type="text"
                          name="bodyHtmlInput"
                          size="small"
                          defaultValue={conditions.bodyHtmlInput}
                          multiline
                          rows={26}
                          autoComplete="off"
                          className={classes.bodyTextField}
                          helperText={
                            errors.bodyHtmlInput
                              ? errors.bodyHtmlInput.message
                              : ''
                          }
                          error={!!errors.bodyHtmlInput}
                          inputProps={{
                            'data-cy': 'bodyHtml-input',
                          }}
                          onBlur={setFocus}
                          onChange={handleInput}
                        />
                      </TabPanel>
                    </Grid>

                    <Grid item xs={2}>
                      <Grid container item xs={12} direction="column">
                        <Grid item xs={12} className={classes.buttonGrid}>
                          <Typography component="h2" variant="h5">
                            <Box component="span" fontWeight={600}>
                              プレースホルダ挿入
                            </Box>
                          </Typography>
                          <Box mt={1}>
                            <Divider />
                          </Box>
                        </Grid>

                        <Grid item xs={12} className={classes.buttonGrid}>
                          <ActionButton
                            color="primary"
                            data-cy="customerName-button"
                            fullWidth
                            onClick={() => {
                              insertPlaceholder('customerName');
                            }}
                          >
                            お客様氏名
                          </ActionButton>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonGrid}>
                          <ActionButton
                            color="primary"
                            data-cy="ecOrderNo-button"
                            fullWidth
                            onClick={() => {
                              insertPlaceholder('ecOrderNo');
                            }}
                          >
                            注文番号
                          </ActionButton>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonGrid}>
                          <ActionButton
                            color="primary"
                            data-cy="orderedAt-button"
                            fullWidth
                            onClick={() => {
                              insertPlaceholder('orderedAt');
                            }}
                          >
                            注文日
                          </ActionButton>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonGrid}>
                          <ActionButton
                            color="primary"
                            data-cy="deliveryDate-button"
                            fullWidth
                            onClick={() => {
                              insertPlaceholder('deliveryDate');
                            }}
                          >
                            お届け希望日
                          </ActionButton>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonGrid}>
                          <ActionButton
                            color="primary"
                            data-cy="siteUrl-button"
                            fullWidth
                            onClick={() => {
                              insertPlaceholder('siteUrl');
                            }}
                          >
                            サイトURL
                          </ActionButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} justify="flex-end">
                    <Grid item className={classes.registButtonGrid}>
                      <ActionButton
                        type="button"
                        color="primary"
                        data-cy="regist-button"
                        fullWidth
                        onClick={onSubmit}
                      >
                        登録
                      </ActionButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ContentsArea>
    </>
  );
};

export default CustomerMailPage;
