import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ReadTagPage from 'components/ReadTag/ReadTagPage';
import RegistResultPage from 'components/RegistResult/RegistResultPage';
import RegistWorkPage from './RegistWork/RegistWorkPage';
import RegistClothingPage from './RegistClothing/RegistClothingPage';

const StainRemoval: FC<{}> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/readtag`}>
        <ReadTagPage />
      </Route>
      <Route path={`${match.path}/registclothing`}>
        <RegistClothingPage />
      </Route>
      <Route path={`${match.path}/registwork`}>
        <RegistWorkPage />
      </Route>
      <Route path={`${match.path}/registresult`}>
        <RegistResultPage />
      </Route>
    </Switch>
  );
};

export default StainRemoval;
