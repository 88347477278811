/* eslint-disable max-classes-per-file */

import { ConfirmationResult, WorkType } from 'api/works/works.dto';

/**
 * メール送信結果
 */
export enum SendResult {
  /** 未送信 */
  NotYet = 'NotYet',
  /** 送信OK */
  Ok = 'Ok',
  /** 送信NG */
  Ng = 'Ng',
}

/**
 * 注文Idリスト
 */
export class OrderIdListDto {
  /** 注文Idリスト */
  orderIdList!: string[];
}

/**
 * 注文情報
 */
export class OrderDto {
  /** 注文Id */
  readonly orderId?: string;

  /** 受注番号 */
  readonly orderNo?: number;

  /** 申込書写真 */
  readonly formPhoto?: string | null;

  /** メール送信日時 */
  readonly sentAt?: Date | null;

  /** 検品開始日時 */
  readonly startedAt?: Date | null;

  /** 検品終了日時 */
  readonly endedAt?: Date | null;

  /** 登録者 */
  readonly createdBy?: string;

  /** 登録日時 */
  readonly createdAt?: Date;

  /** 更新者 */
  readonly updatedBy?: string;

  /** 更新日時 */
  readonly updatedAt?: Date | null;

  /** ECサイト注文番号 */
  readonly ecOrderNo?: string;

  /** お客様氏名 */
  readonly customerName?: string | null;

  /** メールアドレス */
  readonly email?: string | null;

  /** 衣類点数 */
  readonly qty?: number | null;

  /** 受注日時 */
  readonly orderedAt?: Date;

  /** 配達希望日 */
  readonly deliveryDate?: Date | null;

  /** 出荷予定日 */
  readonly shippingDate?: Date | null;

  /** 出荷日時 */
  readonly shippedAt?: Date | null;

  /** アクセス禁止フラグ */
  readonly isNoAccess?: boolean;
}

/**
 * 登録注文情報
 */
export class CreateOrderDto {
  /** 受注番号 */
  orderNo!: number;

  /** 申込書写真 */
  formPhoto?: string | null;

  /** 検品開始日時 */
  startedAt?: Date | null;

  /** 検品終了日時 */
  endedAt?: Date | null;
}

/**
 * 更新注文情報
 */
export class UpdateOrderDto {
  /** 申込書写真 */
  formPhoto?: string | null;

  /** 検品開始日時 */
  startedAt?: Date | null;

  /** 検品終了日時 */
  endedAt?: Date | null | 'NOW()';

  /** アクセス禁止フラグ */
  isNoAccess?: boolean | null;
}

/**
 * 一括更新注文情報
 */
export class UpdateOrdersDto extends UpdateOrderDto {
  /** 注文Id */
  readonly orderId!: string;
}

/**
 * 一括更新注文情報リスト
 */
export class UpdateOrdersListDto {
  readonly updateOrders!: UpdateOrdersDto[];
}

/**
 * 一括更新エラー
 */
export class ErrorOrdersDto {
  /** 注文Id */
  readonly orderId!: string;

  /** エラー */
  readonly error!: unknown;
}

/**
 * 注文情報取得 検索条件（管理画面用）
 */
export type OrderConditions = {
  /** ページ */
  page: number;
  /** 1ページあたりの件数 */
  perPage: number;
  /** 作業種別 */
  workType?: WorkType[];
  /** 作業残 */
  isRemaining?: boolean;
  /** 担当者名 */
  workedByName?: string;
  /** 注文日（FROM） */
  orderedAtFrom?: string | null;
  /** 注文日（TO） */
  orderedAtTo?: string | null;
  /** 検品終了日時（FROM） */
  inspectedAtFrom?: string | null;
  /** 検品終了日時（TO） */
  inspectedAtTo?: string | null;
  /** 検品結果 */
  confirmationResult?: ConfirmationResult[];
  /** 受付番号（FROM） */
  orderNoFrom?: string | null;
  /** 受付番号（TO） */
  orderNoTo?: string | null;
  /** タグ番号（FROM） */
  tagNoFrom?: string | null;
  /** タグ番号（TO） */
  tagNoTo?: string | null;
  /** 希望納期（FROM） */
  deliveryDateFrom?: string | null;
  /** 希望納期（TO） */
  deliveryDateTo?: string | null;
  /** 検査完了日時（FROM） ※全衣類の全作業の検査完了した日時 */
  confirmedAtFrom?: string | null;
  /** 検査完了日時（TO）  ※全衣類の全作業の検査完了した日時 */
  confirmedAtTo?: string | null;
  /** ECサイト注文番号 */
  ecOrderNo?: string | null;
};

/**
 * 注文情報（管理画面用）
 */
export class OrderAdminDto {
  /** 注文Id */
  readonly orderId!: string;

  /** 受付番号 */
  readonly orderNo!: number;

  /** ECサイト注文番号 */
  readonly ecOrderNo!: string | null;

  /** 注文日時（受注日時） */
  readonly orderedAt!: Date | null;

  /** お客様氏名 */
  readonly customerName!: string | null;

  /** メールアドレス */
  readonly email!: string | null;

  /** 申込書写真 */
  readonly formPhoto!: string | null;

  /** 衣類点数 */
  readonly qty!: number | null;

  /** 検品終了日時 */
  readonly inspectedAt!: Date | null;

  /** 検品担当者名 */
  readonly inspectedByName!: string | null;

  /** 毛玉取り件数 */
  readonly lintRemovalCount!: number;

  /** 毛玉取り実施数 */
  readonly lintRemovaledCount!: number;

  /** 修理件数 */
  readonly repairCount!: number;

  /** 修理実施数 */
  readonly repairedCount!: number;

  /** 染み抜き件数 */
  readonly stainRemovalCount!: number;

  /** 染み抜き実施数 */
  readonly stainRemovaledCount!: number;

  /** 検査総数 */
  readonly confirmationCount!: number;

  /** 検査完了数 */
  readonly confirmedCount!: number;

  /** 検査完了日時 */
  readonly confirmedAt!: Date | null;

  /** 検査担当者 */
  readonly confirmedByName!: string | null;

  /** アクセス禁止フラグ */
  readonly isNoAccess!: boolean;

  /** メール送信日時 */
  readonly sentAt!: Date | null;

  /** メール送信結果 */
  readonly sendResult!: SendResult;
}

/**
 * 注文情報一覧（管理画面用）
 */
export class OrderAdminListDto {
  /** 件数 */
  readonly count!: number;

  /** 注文情報一覧 */
  readonly orderAdminList!: OrderAdminDto[];
}

/**
 * 注文情報（管理画面用）
 */
export class EditOrderInfoDto {
  /** 注文Id */
  readonly orderId!: string;

  /** 受付番号 */
  readonly orderNo!: number;

  /** お客様氏名 */
  readonly customerName?: string | null;

  /** 注文日時 */
  readonly orderedAt?: Date;

  /** 配達希望日 */
  readonly deliveryDate?: Date | null;

  /** 衣類点数 */
  readonly qty?: number | null;

  /** 検品開始日時 */
  readonly startedAt!: Date;

  /** 検品終了日時 */
  readonly endedAt!: Date | null;

  /** アクセス禁止フラグ */
  readonly isNoAccess!: boolean;

  /** 申込書写真 */
  readonly formPhoto!: string | null;
}

/**
 * メール送信エラー
 */
export class SendMailErrorDto {
  /** 注文Id */
  readonly orderId!: string;

  /** エラー */
  readonly error!: unknown;
}
