import { API, Auth } from 'aws-amplify';
import { API_NAME } from 'app/constants';

export const trimQueryStringParameters = <T>(params: T) => {
  const param = params;
  if (param) {
    // queryParameterの場合、不要な検索パラメータを削除しておかないとエラーになる
    Object.keys(param).forEach(key => {
      const val = param[key as keyof T];
      if (
        val == null ||
        (typeof val === 'string' && val === '') ||
        (Array.isArray(val) && val.length === 0)
      ) {
        delete param[key as keyof T];
      }
    });
  }

  return param;
};

export const callGetApi = async (path: string, init: unknown) => {
  await Auth.currentAuthenticatedUser();

  return API.get(API_NAME, path, init);
};
