import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { currentTheme, adminTheme } from 'components/common/Theme';
import Login from 'components/Login/LoginPage';
import RegisterUser from 'components/Login/RegisterUserPage';
import Header from 'components/common/Header';
import Inspection from 'components/Inspection';
import Repair from 'components/Repair';
import StainRemoval from 'components/StainRemoval';
import Confirmation from 'components/Confirmation';
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CustomSnackbar from 'components/common/CustomSnackbar';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import ErrorDialog from 'components/common/ErrorDialog';
import Admin from 'components/Admin';
import { RootState } from './rootReducer';
import { version } from '../../package.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 9999,
      color: '#fff',
    },
  }),
);

const App: React.FC = () => {
  const classes = useStyles();
  const { isLoading } = useSelector((state: RootState) => state.common);

  // Reactアプリ キャッシュ対策
  useEffect(() => {
    (() => {
      const currentVersion = version;
      const storageVersion = localStorage.getItem('version');

      // ローカルストレージとpackage.jsonのバージョン情報（リリース時に更新）を比較
      // 一致しない場合はキャッシュクリアのためブラウザをスーパーリロード
      if (!storageVersion || storageVersion !== currentVersion) {
        localStorage.setItem('version', currentVersion);
        window.location.reload(true);
      }
    })();
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <MuiThemeProvider theme={currentTheme(WorkProcess.Inspection)}>
            <Login />
            <ErrorDialog />
          </MuiThemeProvider>
        </Route>
        <Route exact path="/registeruser">
          <MuiThemeProvider theme={currentTheme(WorkProcess.Inspection)}>
            <RegisterUser />
            <ErrorDialog />
          </MuiThemeProvider>
        </Route>
        <Route path="/">
          <Switch>
            <Route path="/inspection">
              <MuiThemeProvider theme={currentTheme(WorkProcess.Inspection)}>
                <Header />
                <Inspection />
                <CustomSnackbar />
                <ErrorDialog />
              </MuiThemeProvider>
            </Route>
            <Route path="/repair">
              <MuiThemeProvider theme={currentTheme(WorkProcess.Repair)}>
                <Header />
                <Repair />
                <CustomSnackbar />
                <ErrorDialog />
              </MuiThemeProvider>
            </Route>
            <Route path="/stainremoval">
              <MuiThemeProvider theme={currentTheme(WorkProcess.StainRemoval)}>
                <Header />
                <StainRemoval />
                <CustomSnackbar />
                <ErrorDialog />
              </MuiThemeProvider>
            </Route>
            <Route path="/confirmation">
              <MuiThemeProvider theme={currentTheme(WorkProcess.Confirmation)}>
                <Header />
                <Confirmation />
                <CustomSnackbar />
                <ErrorDialog />
              </MuiThemeProvider>
            </Route>
            <Route path="/admin">
              <MuiThemeProvider theme={adminTheme()}>
                <Admin />
                <CustomSnackbar />
                <ErrorDialog />
              </MuiThemeProvider>
            </Route>
          </Switch>
        </Route>
      </Switch>
      <Backdrop className={classes.backdrop} open={isLoading} data-cy="loading">
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default App;
