import React, { FC, useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { WorkingResultDto } from 'api/aggregate/aggregate.dto';
import { WorkType } from 'api/works/works.dto';
import { colors } from 'components/common/Theme';
import { Box, Typography, Theme } from '@material-ui/core';
import { workTypes, workingResults } from 'app/constants';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pieChartBox: {
      textAlign: 'center',
    },
    pieChartInner: {
      position: 'relative',
      display: 'inline-block',
    },
    pieChart: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    workTypesTitle: {
      position: 'absolute',
      top: '75px',
      left: '75px',
      transform: 'translate(-50%, -50%)',
    },
    CustomTooltip: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${grey[500]}`,
      position: 'relative',
    },
  }),
);

export type DataType = {
  name: string;
  value: string;
  color: string;
};

interface PropsType {
  workType: WorkType;
  workingResult?: WorkingResultDto;
}
const PieGraph: FC<PropsType> = ({ workType, workingResult }) => {
  const classes = useStyles();
  const data = useMemo(() => {
    if (workingResult) {
      return [
        {
          name: workingResults.Perfect.label,
          value: workingResult.perfectCount,
          color: colors.workingResults.Perfect,
        },
        {
          name: workingResults.Almost.label,
          value: workingResult.almostCount,
          color: colors.workingResults.Almost,
        },
        {
          name: workingResults.Part.label,
          value: workingResult.partCount,
          color: colors.workingResults.Part,
        },
        {
          name: workingResults.CouldNot.label,
          value: workingResult.couldNotCount,
          color: colors.workingResults.CouldNot,
        },
        {
          name: workingResults.NotRequired.label,
          value: workingResult.notRequiredCount,
          color: colors.workingResults.NotRequired,
        },
      ];
    }

    return null;
  }, [workingResult]);

  const CustomTooltip = ({ active, workTypeTitle, dispDataList }: any) => {
    if (active) {
      return (
        <Box className={classes.CustomTooltip} p={2}>
          <Box mb={1}>
            <Typography component="p" variant="h4">
              {workTypeTitle}
            </Typography>
          </Box>
          {dispDataList.map((dispData: DataType) => (
            <Box mb={1} key={dispData.name}>
              <Typography component="p" variant="h4">
                <Box
                  component="span"
                  color={dispData.color}
                >{`${dispData.name} : ${dispData.value}`}</Box>
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      {data && (
        <>
          <Box className={classes.pieChartBox}>
            <Box className={classes.pieChartInner}>
              <PieChart width={150} height={150} className={classes.pieChart}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={75}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  content={
                    <CustomTooltip
                      workTypeTitle={workTypes[workType].title}
                      dispDataList={data}
                    />
                  }
                  wrapperStyle={{ zIndex: 9999, textAlign: 'left' }}
                />
              </PieChart>
              <Box className={classes.workTypesTitle}>
                {workTypes[workType].title}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default PieGraph;
