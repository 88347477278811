import React, { FC } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import { workingResults, confirmationResults, workTypes } from 'app/constants';
import {
  WorkingResult,
  ConfirmationResult,
  WorkType,
} from 'api/works/works.dto';
import { Box } from '@material-ui/core';
import { colors } from 'components/common/Theme';

const ColorlibConnector = withStyles(() => ({
  alternativeLabel: {
    top: 30,
    left: 15,
    right: 15,
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: colors.disabled.main,
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    stepper: {
      padding: 0,
      justifyContent: 'space-between',
      position: 'relative',
    },
    step: {
      flex: 0,
      position: 'unset',
    },
    iconRoot: {
      backgroundColor: colors.disabled.main,
      zIndex: 1,
      color: '#fff',
      width: 60,
      height: 60,
      display: 'flex',
      borderRadius: '50%',
      border: '2px solid',
      borderColor: colors.disabled.main,
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: theme.shadows[1],
    },
    status01: {
      backgroundColor: theme.palette.info.dark,
    },
    status02: {
      backgroundColor: theme.palette.warning.dark,
    },
    status03: {
      backgroundColor: theme.palette.error.dark,
    },
  }),
);

interface PropsType {
  workingResult?: WorkingResult;
  confirmationResult?: ConfirmationResult;
  workType?: WorkType;
}
const WorkStatus: FC<PropsType> = props => {
  const { workingResult, confirmationResult, workType } = props;
  const classes = useStyles();
  const [activeStep] = React.useState(1);

  const InspectionIcon = () => (
    <div
      className={`${classes.iconRoot} ${classes.status01}`}
      data-cy="work-status-inspection-icon"
    >
      <Typography
        component="p"
        variant="h4"
        data-cy="work-status-inspection-label"
      >
        検品
      </Typography>
    </div>
  );

  const WorkIcon = () => (
    <div
      className={clsx(classes.iconRoot, {
        [classes.status01]:
          workingResult === WorkingResult.Perfect ||
          workingResult === WorkingResult.Almost,
        [classes.status02]: workingResult === WorkingResult.Part,
        [classes.status03]:
          workingResult === WorkingResult.CouldNot ||
          workingResult === WorkingResult.NotRequired,
      })}
      data-cy="work-status-working-icon"
    >
      <Typography
        component="p"
        variant="h4"
        data-cy="work-status-working-label"
      >
        {workType && workTypes[workType].label}
      </Typography>
    </div>
  );

  const ConfirmationIcon = () => (
    <div
      className={clsx(classes.iconRoot, {
        [classes.status01]: confirmationResult === ConfirmationResult.Ok,
        [classes.status03]: confirmationResult === ConfirmationResult.Ng,
      })}
      data-cy="work-status-confirmation-icon"
    >
      <Typography
        component="p"
        variant="h4"
        data-cy="work-status-confirmation-label"
      >
        検査
      </Typography>
    </div>
  );

  interface ResultPropsType {
    resultLabel: string;
  }
  const ResultLabel: FC<ResultPropsType> = ({ resultLabel }) => {
    return (
      <Typography component="p">
        <Box
          component="span"
          fontWeight={700}
          fontSize="1.5rem"
          color={(() => {
            if (
              resultLabel === workingResults.Perfect.icon ||
              resultLabel === workingResults.Almost.icon ||
              resultLabel === confirmationResults.Ok.icon
            ) {
              return 'info.dark';
            }
            if (resultLabel === workingResults.Part.icon) {
              return 'warning.dark';
            }
            if (
              resultLabel === workingResults.CouldNot.icon ||
              resultLabel === workingResults.NotRequired.icon ||
              resultLabel === confirmationResults.Ng.icon
            ) {
              return 'error.dark';
            }

            return null;
          })()}
          data-cy="work-status-result-label"
        >
          {resultLabel}
        </Box>
      </Typography>
    );
  };

  return (
    <Box className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}
      >
        <Step className={classes.step}>
          <StepLabel StepIconComponent={InspectionIcon}>
            <ResultLabel resultLabel={workingResults.Perfect.icon} />
          </StepLabel>
        </Step>
        <Step className={classes.step}>
          <StepLabel StepIconComponent={WorkIcon}>
            {workingResult && (
              <ResultLabel resultLabel={workingResults[workingResult].icon} />
            )}
          </StepLabel>
        </Step>
        <Step className={classes.step}>
          <StepLabel StepIconComponent={ConfirmationIcon}>
            {confirmationResult && (
              <ResultLabel
                resultLabel={confirmationResults[confirmationResult].icon}
              />
            )}
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};
export default WorkStatus;
