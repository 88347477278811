import React, { FC } from 'react';
import { Grid, Box } from '@material-ui/core';

const WorkArea: FC<{}> = ({ children }) => {
  return (
    <Box pl={2} pr={2} pt={1} mx="auto" minWidth={1240} maxWidth={1600}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </Box>
  );
};

export default WorkArea;
