import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import DashBoardPage from 'components/Admin/DashBoard/DashBoardPage';
import RegisterUserPage from 'components/Admin/RegisterUser/RegisterUserPage';
import OrderSearchPage from 'components/Admin/OrderSearch/OrderSearchPage';
import CustomerMailPage from 'components/Admin/CustomerMail/CustomerMailPage';

const Admin: FC<{}> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/dashboard`}>
        <DashBoardPage />
      </Route>
      <Route path={`${match.path}/registeruser`}>
        <RegisterUserPage />
      </Route>
      <Route path={`${match.path}/ordersearch`}>
        <OrderSearchPage />
      </Route>
      <Route path={`${match.path}/customermail`}>
        <CustomerMailPage />
      </Route>
    </Switch>
  );
};

export default Admin;
