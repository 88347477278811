import { callGetApi } from 'utils/apiUtils';
import {
  ProgressDto,
  AggregateConditionsDto,
  ResultDto,
  ImplementationDto,
} from './aggregate.dto';

const path = '/aggregate';

/**
 * 工程別 作業進捗状況を取得
 * @param conditions 検索条件
 */
export const getProgress = async (conditions?: AggregateConditionsDto) => {
  try {
    const progress: ProgressDto = await callGetApi(`${path}/process`, {
      queryStringParameters: conditions,
    });

    return progress;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};

/**
 * 工程別 作業結果状況を取得
 * @param conditions 検索条件
 */
export const getResult = async (conditions?: AggregateConditionsDto) => {
  try {
    const workResult: ResultDto = await callGetApi(`${path}/result`, {
      queryStringParameters: conditions,
    });

    return workResult;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};

/**
 * 日別工程別 作業実施状況を取得
 * @param conditions 検索条件
 */
export const getImplementation = async (
  conditions?: AggregateConditionsDto,
) => {
  try {
    const implementation: ImplementationDto = await callGetApi(
      `${path}/implementation`,
      {
        queryStringParameters: conditions,
      },
    );

    return implementation;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};
