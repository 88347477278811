import { callGetApi } from 'utils/apiUtils';
import { ClothingTypeCategoryDto } from './clothingTypes.dto';

/**
 * 衣類種別一覧を取得
 */
export const getClothingTypeList = async () => {
  const clothingTypeCategoryDto: ClothingTypeCategoryDto[] = await callGetApi(
    '/clothingTypeCategories',
    {},
  );

  return clothingTypeCategoryDto;
};
