import { API, Auth } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { callGetApi } from 'utils/apiUtils';
import {
  UrlNotificationMailDto,
  UpdateUrlNotificationMailDto,
} from './settings.dto';

const path = '/settings';

/**
 * URL通知メール情報を取得（管理画面用）
 */
export const getUrlNotificationMail = async () => {
  const urlNotificationMail: UrlNotificationMailDto = await callGetApi(
    `${path}/urlnotificationmail`,
    {},
  );

  return urlNotificationMail;
};

/**
 * URL通知メール情報を更新（管理画面用）
 * @param updateUrlNotificationMailDto URL通知メール情報
 */
export const updateUrlNotificationMail = async (
  updateUrlNotificationMailDto: UpdateUrlNotificationMailDto,
) => {
  const user = await Auth.currentAuthenticatedUser();
  const urlNotificationMailDto: UrlNotificationMailDto = await API.patch(
    API_NAME,
    `${path}/urlnotificationmail`,
    {
      body: { ...updateUrlNotificationMailDto, updatedBy: user.getUsername() },
    },
  );

  return urlNotificationMailDto;
};
