import React, { FC } from 'react';
import {
  withStyles,
  FormControl,
  InputLabel,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { WorkProcess } from 'api/workHistories/workHistories.dto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    InputLabel: {
      color: theme.palette.text.primary,
    },
    toggleButtonGroup: {
      width: '100%',
    },
    toggleButton: {
      height: '37px',
    },
  }),
);

/**
 * material-uiコンポーネントの既存スタイルに上書き
 */
const StyledFormControl = withStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    marginTop: theme.spacing(1),
    '& fieldset': {
      top: '-5px',
      left: 0,
      right: 0,
      bottom: 0,
      margin: 0,
      padding: 0,
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '1px',
      paddingLeft: '8px',
      borderRadius: 'inherit',
      pointerEvents: 'none',
    },
    '& label': {
      transform: 'translate(0, -14px) scale(0.75)',
      zIndex: 2,
    },
    '& legend': {
      width: 0,
      height: '11px',
      padding: 0,
      fontSize: '0.75rem',
      textAlign: 'left',
      visibility: 'hidden',
    },
  },
}))(FormControl);

/**
 * material-uiコンポーネントの既存スタイルに上書き
 */
const ColorToggleButton = withStyles(theme => ({
  root: {
    fontWeight: 600,
    flexGrow: 1,
    borderColor: theme.palette.divider,
    borderTop: 'none',
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(ToggleButton);

interface PropsType {
  label: string;
  dataList: {}[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (dataList: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  exclusive?: boolean;
}
const CustomToggleButtons: FC<PropsType> = ({
  label,
  dataList,
  onChange,
  value: currentData,
  exclusive,
}) => {
  const classes = useStyles();

  /**
   * 検索条件stateに選択中の担当工程をセット
   */
  const handleFilter = (
    e: React.MouseEvent<HTMLElement>,
    workProcess: WorkProcess[],
  ) => {
    onChange(workProcess);
  };

  return (
    <>
      <StyledFormControl>
        <InputLabel htmlFor="my-input" className={classes.InputLabel}>
          {label}
        </InputLabel>
        <fieldset aria-hidden="true">
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>
        <ToggleButtonGroup
          onChange={handleFilter}
          value={currentData}
          exclusive={!!exclusive}
          className={classes.toggleButtonGroup}
          data-cy="process-buttons"
        >
          {dataList.map((data: any) => (
            <ColorToggleButton
              name="workProcess"
              key={data.value}
              value={data.value}
              className={classes.toggleButton}
              data-cy={data.dataCy}
            >
              {data.buttonLabel}
            </ColorToggleButton>
          ))}
        </ToggleButtonGroup>
      </StyledFormControl>
    </>
  );
};

export default CustomToggleButtons;
