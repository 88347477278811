import { createMuiTheme } from '@material-ui/core/styles';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { WorkingResult } from 'api/works/works.dto';

const theme = createMuiTheme();

export const colors = {
  inspection: {
    light: '#7DAEF2',
    main: '#5495ef',
    dark: '#5382C1',
    contrastText: '#ffffff',
  },
  repair: {
    light: '#E88E97',
    main: '#E47782',
    dark: '#CC656F',
    contrastText: '#ffffff',
  },
  stainRemoval: {
    light: '#9AD19E',
    main: '#79B97D',
    dark: '#72A775',
    contrastText: '#ffffff',
  },
  confirmation: {
    light: '#E99745',
    main: '#E88A2D',
    dark: '#C8731E',
    contrastText: '#ffffff',
  },
  delete: {
    main: '#a9a9a9',
    dark: '#989898',
    contrastText: '#ffffff',
  },
  logout: {
    light: '#5D5D5D',
    main: '#4c4c4c',
    contrastText: '#ffffff',
  },
  gray: {
    contrastText: '#9a9a9a',
  },
  disabled: {
    main: '#CCCCCC',
    contrastText: '#888888',
  },
  workSideFront: {
    main: '#ffffff',
    border: '#494949',
    contrastText: '#494949',
  },
  workSideBack: {
    main: '#494949',
    border: '#494949',
    contrastText: '#ffffff',
  },
  admin: {
    sideBar: {
      light: '#313740',
      main: '#202020',
    },
    table: {
      head: '#202020',
      body: {
        even: '#FFF',
        odd: '#ecf4ff',
      },
    },
  },
  workingResults: {
    [WorkingResult.Perfect]: theme.palette.info.main,
    [WorkingResult.Almost]: theme.palette.info.light,
    [WorkingResult.Part]: theme.palette.warning.dark,
    [WorkingResult.CouldNot]: theme.palette.error.main,
    [WorkingResult.NotRequired]: theme.palette.error.light,
    [WorkingResult.NotYet]: '#CCCCCC',
  },
};

// 検品（毛玉取り）担当者テーマカラー
const themes = {
  Inspection: {
    primary: {
      light: colors.inspection.light,
      main: colors.inspection.main,
      dark: colors.inspection.dark,
      contrastText: '#ffffff',
    },
  },
  Repair: {
    primary: {
      light: colors.repair.light,
      main: colors.repair.main,
      dark: colors.repair.dark,
      contrastText: '#ffffff',
    },
  },
  StainRemoval: {
    primary: {
      light: colors.stainRemoval.light,
      main: colors.stainRemoval.main,
      dark: colors.stainRemoval.dark,
      contrastText: '#ffffff',
    },
  },
  Confirmation: {
    primary: {
      light: colors.confirmation.light,
      main: colors.confirmation.main,
      dark: colors.confirmation.dark,
      contrastText: '#ffffff',
    },
  },
};

// ログインユーザー（担当種別）に応じたテーマ取得
export const currentTheme = (
  userType:
    | WorkProcess.Inspection
    | WorkProcess.Repair
    | WorkProcess.StainRemoval
    | WorkProcess.Confirmation,
) => {
  return createMuiTheme({
    palette: {
      ...themes[userType],
      background: {
        default: '#F9FAFE',
      },
      divider: '#BFBFBF',
      text: {
        primary: '#494949',
        disabled: '#494949',
      },
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      htmlFontSize: 14,
      h1: {
        fontSize: '1.3rem',
      },
      h2: {
        fontSize: '1.2rem',
      },
      h3: {
        fontSize: '1.1rem',
      },
      h4: {
        fontSize: '1.0rem',
      },
      h5: {
        fontSize: '0.9rem',
      },
      h6: {
        fontSize: '0.8rem',
      },
      caption: {
        fontSize: '0.8rem',
      },
      fontFamily: [
        '游ゴシック Medium',
        'Yu Gothic Medium',
        '游ゴシック体',
        'YuGothic',
        'ヒラギノ角ゴ ProN W3',
        'Hiragino Kaku Gothic ProN',
        'メイリオ',
        'Meiryo',
        'verdana',
      ].join(','),
    },
  });
};

// 管理画面用テーマ取得
export const adminTheme = () => {
  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1500,
      },
    },
    palette: {
      primary: {
        light: colors.inspection.light,
        main: colors.inspection.main,
        dark: colors.inspection.dark,
        contrastText: '#ffffff',
      },
      background: {
        default: '#F9FAFE',
      },
      divider: '#BFBFBF',
      text: {
        primary: '#494949',
        disabled: '#494949',
      },
      common: {
        black: '#202020',
      },
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: 5,
    },
    typography: {
      htmlFontSize: 19,
      h1: {
        fontSize: '1.2rem',
      },
      h2: {
        fontSize: '1.1rem',
      },
      h3: {
        fontSize: '1.0rem',
      },
      h4: {
        fontSize: '0.9rem',
      },
      h5: {
        fontSize: '0.8rem',
      },
      h6: {
        fontSize: '0.7rem',
      },
      caption: {
        fontSize: '0.7rem',
      },
      fontFamily: [
        '游ゴシック Medium',
        'Yu Gothic Medium',
        '游ゴシック体',
        'YuGothic',
        'ヒラギノ角ゴ ProN W3',
        'Hiragino Kaku Gothic ProN',
        'メイリオ',
        'Meiryo',
        'verdana',
      ].join(','),
    },
  });
};
