import React, { FC, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  DialogActions,
  Box,
  Typography,
} from '@material-ui/core';
import { WorkContentDto } from 'api/workContents/workContents.dto';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkContentList } from 'modules/mastersModule';
import { RootState } from 'app/rootReducer';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ActionButton from 'components/common/ActionButton';
import { WorkType } from 'api/works/works.dto';

const useStyles = makeStyles(() =>
  createStyles({
    cancelBtn: {
      width: '10%',
      minWidth: '120px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

type WorkContentDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSelect: (type: WorkContentDto) => void;
};

const WorkContentDialog: FC<WorkContentDialogProps> = ({
  open,
  handleClose,
  handleSelect,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { workContentList } = useSelector((state: RootState) => state.masters);

  useEffect(() => {
    dispatch(getWorkContentList());
  }, [dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      data-cy="work-content-dialog"
    >
      <Grid container justify="space-between">
        <DialogTitle
          id="scroll-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          <Typography
            component="h2"
            variant="h2"
            data-cy="work-content-dialog-title-label"
          >
            作業内容を選択してください。
          </Typography>
        </DialogTitle>
        <DialogActions>
          <ActionButton
            onClick={handleClose}
            color="delete"
            autoFocus
            size="small"
            className={classes.cancelBtn}
            data-cy="work-content-dialog-close-button"
          >
            キャンセル
          </ActionButton>
        </DialogActions>
      </Grid>
      <DialogContent dividers>
        <Grid container spacing={1}>
          {workContentList
            .filter(workContent => workContent.workType === WorkType.Repair)
            .map(workContent => (
              <Grid
                item
                xs={3}
                key={workContent.workContentId}
                data-cy={`work-content-${workContent.workContentCode}`}
              >
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => handleSelect(workContent)}
                >
                  <Box py={4}>{workContent.workContentName}</Box>
                </Button>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WorkContentDialog;
