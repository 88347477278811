import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CardMedia, Grid, CardActionArea } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CustomCard from 'components/common/CustomCard';
import { WorkType } from 'api/works/works.dto';
import { NO_IMAGE, workTypes } from 'app/constants';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { colors } from 'components/common/Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 0,
      paddingTop: '100%',
      backgroundColor: 'rgba(255,255,255,0.8)',
      backgroundBlendMode: 'lighten',
    },
    cardContents: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      width: '80%',
    },
    tagNo: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface PropsType {
  workProcess: WorkProcess;
  orderId?: string;
  clothingId?: string;
  tagNo: string;
  clothingTypeName?: string;
  clothingTypePicture?: string;
  lintRemovalCount: number;
  repairCount: number;
  stainRemovalCount: number;
}
const ClothingDetail: FC<PropsType> = props => {
  const {
    workProcess,
    orderId,
    clothingId,
    tagNo,
    clothingTypeName,
    clothingTypePicture,
    lintRemovalCount,
    repairCount,
    stainRemovalCount,
  } = props;

  const workIcons = [
    { workType: WorkType.LintRemoval, isWorkFlg: lintRemovalCount > 0 },
    { workType: WorkType.Repair, isWorkFlg: repairCount > 0 },
    { workType: WorkType.StainRemoval, isWorkFlg: stainRemovalCount > 0 },
  ];
  const classes = useStyles();
  const history = useHistory();

  const registClothing = () => {
    history.push('/inspection/registclothing', {
      workProcess,
      orderId,
      clothingId,
      tagNo,
      // 衣類IDが存在しない衣類明細は、syncClothingにのみ存在する衣類情報と判断する
      syncClothingOnly: !clothingId,
    });
  };

  interface IconPropsType {
    workType: WorkType;
    isWorkFlg: boolean;
  }
  const WorkIcon: FC<IconPropsType> = ({ workType, isWorkFlg }) => {
    return (
      <Box
        position="relative"
        bgcolor={
          isWorkFlg ? workTypes[workType].mainColor : colors.disabled.main
        }
        borderRadius="50%"
        width="25%"
        height="25%"
        padding="15%"
        data-cy={`clothing-type-${workType.toLowerCase()}-icon`}
      >
        {isWorkFlg && (
          <Box
            position="absolute"
            left="0"
            top="50%"
            margin="-.7em 0 0"
            width="100%"
            textAlign="center"
            component="span"
            color="white"
            fontWeight={700}
            fontSize="1rem"
            data-cy={`clothing-type-${workType.toLowerCase()}-icon-label`}
          >
            {workTypes[workType].label}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <CustomCard onClick={registClothing}>
        <CardActionArea>
          <Box p={2}>
            <CardMedia
              className={classes.media}
              image={clothingTypePicture || NO_IMAGE}
              data-cy="clothing-detail-clothing-type-image"
            />
          </Box>
          <Box
            position="absolute"
            className={classes.cardContents}
            textAlign="center"
          >
            <Typography component="p" variant="h2">
              <Box
                component="span"
                color="text.secondary"
                fontWeight={700}
                data-cy="clothing-detail-clothing-type-label"
              >
                {clothingId ? clothingTypeName : '未登録'}
              </Box>
            </Typography>
            <Typography component="p" variant="h5" className={classes.tagNo}>
              <Box
                component="span"
                color="text.secondary"
                fontWeight={700}
                data-cy="clothing-detail-tag-no-label"
              >
                {tagNo}
              </Box>
            </Typography>
            <Grid container justify="space-between">
              {clothingId &&
                workIcons.map(workIcon => (
                  <WorkIcon
                    key={workIcon.workType}
                    workType={workIcon.workType}
                    isWorkFlg={workIcon.isWorkFlg}
                  />
                ))}
            </Grid>
          </Box>
        </CardActionArea>
      </CustomCard>
    </>
  );
};
export default ClothingDetail;
