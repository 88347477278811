import React, { useCallback } from 'react';
import {
  TextField,
  TextFieldProps,
  withStyles,
  Theme,
} from '@material-ui/core';

/**
 * material-uiコンポーネントの既存スタイルに上書き
 */
const StyledTextField = withStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    '& label': {
      transform: 'translate(0, -14px) scale(0.75) !important',
      color: theme.palette.text.primary,
    },
    '& legend': {
      width: 0,
    },
  },
}))(TextField);

interface PropsType {
  name?: string;
  label?: string;
  onChange?: any;
}
const CustomTextField = (
  props: PropsType & Omit<TextFieldProps, keyof PropsType>,
) => {
  const { ...other } = props;
  const { onChange } = props;
  /**
   * 検索条件stateに入力値をセット
   */
  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = e.currentTarget;
      props.onChange(name, value, e.currentTarget);
    },
    [props],
  );

  return (
    <>
      {onChange ? (
        <StyledTextField
          {...other}
          variant="outlined"
          fullWidth
          onChange={handleInput}
          size="small"
        />
      ) : (
        <StyledTextField {...other} variant="outlined" fullWidth size="small" />
      )}
    </>
  );
};

export default CustomTextField;
