import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CustomCard from 'components/common/CustomCard';
import { WorkHistoryDto } from 'api/workHistories/workHistories.dto';
import moment from 'moment';
import { workProcesses } from 'app/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHead: {
      backgroundColor: theme.palette.primary.main,
    },
    headCell: {
      color: '#FFF',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: '5px',
      height: '40px',
    },
    bodyCell: {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: '5px',
      borderBottomColor: theme.palette.divider,
      height: '40px',
    },
  }),
);

interface PropsType {
  workHistoryList?: WorkHistoryDto[];
}
const WorkHistoryTable: FC<PropsType> = ({ workHistoryList }) => {
  const classes = useStyles();

  return (
    <CustomCard>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.headCell}>工程</TableCell>
              <TableCell className={classes.headCell} align="left">
                担当者
              </TableCell>
              <TableCell className={classes.headCell} align="left">
                作業日時
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workHistoryList?.map(workHistory => (
              <TableRow
                key={workHistory.workHistoryId}
                data-cy="work-history-list"
              >
                <TableCell
                  className={classes.bodyCell}
                  component="th"
                  scope="row"
                  data-cy="work-history-work-process"
                >
                  {workProcesses[workHistory.workProcess].title}
                </TableCell>
                <TableCell
                  className={classes.bodyCell}
                  align="left"
                  data-cy="work-history-created-by-name"
                >
                  {workHistory.createdByName}
                </TableCell>
                <TableCell
                  className={classes.bodyCell}
                  align="left"
                  data-cy="work-history-created-at"
                >
                  {moment(workHistory.createdAt).format('YYYY-MM-DD HH:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomCard>
  );
};
export default WorkHistoryTable;
