import React, { FC, useRef, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setTitle,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { deleteTestData } from 'api/orders/orders.api';

type FormData = {
  orderNo: string;
};

type LocationState = {
  workProcess: WorkProcess;
};
const ReadLabelPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues } = useForm<FormData>({
    reValidateMode: 'onSubmit',
  });

  const location = useLocation<LocationState>();
  const { workProcess } = location.state;
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  dispatch(setTitle('管理ラベル読取'));

  const onSubmit = handleSubmit(({ orderNo }) => {
    history.push('/inspection/registorder', { workProcess, orderNo });
  });

  const readTag = () => {
    history.push('/inspection/readtag', { workProcess });
  };

  const executeDeleteTestData = async () => {
    if (process.env.REACT_APP_ENV !== 'production') {
      try {
        dispatch(startLoading());
        const { orderNo } = getValues();
        await deleteTestData(parseInt(orderNo, 10));
        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('テストデータを削除できませんでした。', e, true),
          ),
        );
      }
    }
  };

  const onBlurEvent = useCallback(() => {
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    (() => {
      // 入力エラー発生時は入力フォームをクリア
      if (errors.orderNo) {
        if (textFieldRef.current) {
          textFieldRef.current.value = '';
        }
      }
    })();
  }, [errors.orderNo]);

  return (
    <form onSubmit={onSubmit} noValidate>
      <WorkArea>
        <ContentsArea>
          <Grid container spacing={5}>
            <Grid container item xs={6} spacing={2}>
              <Grid item xs={8}>
                <TextField
                  name="orderNo"
                  label="受付番号"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  onBlur={onBlurEvent}
                  required
                  autoComplete="off"
                  inputProps={{
                    inputMode: 'numeric',
                    maxLength: 7,
                    'data-cy': 'order-no-input',
                  }}
                  inputRef={e => {
                    register(e, {
                      required: '管理ラベルのバーコードを読み取ってください。',
                      pattern: {
                        value: /[0-9]{6}/,
                        message: '管理ラベルのバーコードを読み取ってください。',
                      },
                      maxLength: {
                        value: 6,
                        message: '管理ラベルのバーコードを読み取ってください。',
                      },
                    });
                    textFieldRef.current = e;
                  }}
                  helperText={errors.orderNo ? errors.orderNo.message : ''}
                  error={!!errors.orderNo}
                />
              </Grid>
            </Grid>
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            <ActionButton
              color="primary"
              type="submit"
              endIcon={<ArrowForwardIosIcon />}
              data-cy="regist-Order-button"
            >
              注文情報登録へ
            </ActionButton>
            {process.env.REACT_APP_ENV !== 'production' && (
              <ActionButton
                onClick={executeDeleteTestData}
                data-cy="delete-test-data-button"
                style={{ display: 'none' }}
              >
                テストデータ削除
              </ActionButton>
            )}
          </Grid>
          <Grid item>
            <ActionButton
              onClick={readTag}
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              data-cy="read-tag-button"
            >
              タグ読取へ
            </ActionButton>
          </Grid>
        </ButtonsArea>
      </WorkArea>
    </form>
  );
};

export default ReadLabelPage;
