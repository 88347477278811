import {
  WorkType,
  WorkSide,
  WorkingResult,
  ConfirmationResult,
} from 'api/works/works.dto';
import { colors } from 'components/common/Theme';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { SendResult } from 'api/orders/orders.dto';

export const API_NAME = 'OsekkaiAPI';

export const NO_IMAGE = '/noimage.png';
export const NO_IMAGE_CAMERA = '/noimage_camera.png';
export const ERROR_IMAGE =
  'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-8 -18 40 64"><path fill="%23ff8888" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"></path></svg>';

export const IMAGE_MIME = 'image/jpeg';
export const IMAGE_BASE64_PREFIX = `data:${IMAGE_MIME};base64,`;
export const IMAGE_QUALITY = 1;

export const workTypes = {
  [WorkType.LintRemoval]: {
    title: '毛玉取り',
    label: '毛',
    lightColor: colors.inspection.light,
    mainColor: colors.inspection.main,
    darkColor: colors.inspection.dark,
  },
  [WorkType.Repair]: {
    title: '修理',
    label: '修',
    lightColor: colors.repair.light,
    mainColor: colors.repair.main,
    darkColor: colors.repair.dark,
  },
  [WorkType.StainRemoval]: {
    title: '染み/汚れ',
    label: '染',
    lightColor: colors.stainRemoval.light,
    mainColor: colors.stainRemoval.main,
    darkColor: colors.stainRemoval.dark,
  },
};

export const workProcesses = {
  [WorkProcess.Inspection]: {
    title: '検品',
  },
  [WorkProcess.LintRemoval]: {
    title: '毛玉取り',
  },
  [WorkProcess.Repair]: {
    title: '修理',
  },
  [WorkProcess.StainRemoval]: {
    title: '染み/汚れ',
  },
  [WorkProcess.Confirmation]: {
    title: '検査',
  },
  [WorkProcess.Admin]: {
    title: '管理者',
  },
};

export const workSides = {
  [WorkSide.Front]: {
    label: '表',
    mainColor: colors.workSideFront.main,
    border: colors.workSideFront.border,
    contrastText: colors.workSideFront.contrastText,
  },
  [WorkSide.Back]: {
    label: '裏',
    mainColor: colors.workSideBack.main,
    border: colors.workSideBack.border,
    contrastText: colors.workSideBack.contrastText,
  },
};

export const workingResults = {
  [WorkingResult.Perfect]: {
    label: '完璧にできた',
    icon: '◎',
  },
  [WorkingResult.Almost]: {
    label: 'ほぼできた',
    icon: '○',
  },
  [WorkingResult.Part]: {
    label: '一部できた',
    icon: '△',
  },
  [WorkingResult.CouldNot]: {
    label: 'できなかった',
    icon: '×',
  },
  [WorkingResult.NotRequired]: {
    label: 'する必要無し',
    icon: '－',
  },
  [WorkingResult.NotYet]: {
    label: '実施前',
    icon: '　',
  },
};

export const confirmationResults = {
  [ConfirmationResult.Ok]: {
    label: 'OK',
    icon: '◎',
  },
  [ConfirmationResult.Ng]: {
    label: 'NG',
    icon: '×',
  },
  [ConfirmationResult.NotYet]: {
    label: '実施前',
    icon: '　',
  },
};

export const sendResults = {
  [SendResult.Ok]: '送信成功',
  [SendResult.Ng]: '送信失敗',
  [SendResult.NotYet]: '未送信',
};
