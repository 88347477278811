import React, { FC, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Typography,
  CardMedia,
  CardActionArea,
  CardContent,
  Box,
} from '@material-ui/core';
import { ClothingTypeDto } from 'api/clothingTypes/clothingTypes.dto';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { getClothingTypeList } from 'modules/mastersModule';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CustomCard from 'components/common/CustomCard';
import ActionButton from 'components/common/ActionButton';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      minWidth: '1280px',
    },
    row: {
      marginBottom: '3px',
    },
    clothingTypeCategoryName: {
      fontWeight: 700,
    },
    media: {
      height: 0,
      paddingTop: '100%',
    },
    cardContent: {
      textAlign: 'center',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    clothingBox: {
      width: '10%',
      flexBasis: '10%',
      maxWidth: '10%',
    },
    clothingTypeName: {
      fontWeight: 700,
    },
    cancelBtn: {
      width: '10%',
      minWidth: '120px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

type ClothingTypeDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSelect: (type: ClothingTypeDto) => void;
  isAdmin?: boolean; // 管理画面からコールした場合のフラグ。フラグの有無でダイアログの最大幅を切り替える
};

const ClothingTypeDialog: FC<ClothingTypeDialogProps> = ({
  open,
  handleClose,
  handleSelect,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { clothingTypeList } = useSelector((state: RootState) => state.masters);

  useEffect(() => {
    dispatch(getClothingTypeList());
  }, [dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth={isAdmin ? 'lg' : 'xl'}
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.dialog}
      data-cy="clothing-dialog"
    >
      <Grid container justify="space-between">
        <DialogTitle
          id="scroll-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          <Typography
            component="h2"
            variant="h2"
            data-cy="clothing-dialog-title-label"
          >
            衣類種別を選択してください。
          </Typography>
        </DialogTitle>
        <DialogActions>
          <ActionButton
            onClick={handleClose}
            color="delete"
            autoFocus
            size="small"
            className={classes.cancelBtn}
            data-cy="clothing-dialog-close-button"
          >
            キャンセル
          </ActionButton>
        </DialogActions>
      </Grid>
      <DialogContent dividers>
        <Grid container spacing={1}>
          {clothingTypeList.map(category => (
            <Grid
              item
              xs={12}
              spacing={2}
              container
              key={category.clothingTypeCategoryId}
              className={classes.row}
            >
              <Grid item xs={1}>
                <Typography
                  gutterBottom
                  variant="h5"
                  align="left"
                  className={classes.clothingTypeCategoryName}
                >
                  {category.clothingTypeCategoryName}
                </Typography>
              </Grid>
              {category.clothingTypes.map(clothing => (
                <Grid
                  item
                  xs={1}
                  key={clothing.clothingTypeId}
                  className={classes.clothingBox}
                >
                  <CustomCard>
                    <CardActionArea
                      onClick={() => handleSelect(clothing)}
                      data-cy={`clothing-type-${clothing.clothingTypeCode}`}
                    >
                      <Box pl={2} pr={2} pt={2}>
                        <CardMedia
                          image={clothing.clothingTypePictureFront}
                          className={classes.media}
                        />
                      </Box>
                      <CardContent className={classes.cardContent}>
                        <Box>
                          <Typography
                            variant="caption"
                            className={classes.clothingTypeName}
                          >
                            {clothing.clothingTypeName}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </CustomCard>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ClothingTypeDialog;
