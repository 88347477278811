import { API, Auth } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { callGetApi } from 'utils/apiUtils';
import { CreateWorkHistoryDto, WorkHistoryDto } from './workHistories.dto';

const path = '/workHistories';

/**
 * 作業履歴一覧を取得
 * @param workId 作業Id
 */
export const getWorkHistoriesByWorkId = async (workId: string) => {
  const workHistoryDtoList: WorkHistoryDto[] = await callGetApi(
    `${path}/list/${workId}`,
    {},
  );

  return workHistoryDtoList;
};

/**
 * 作業履歴を登録
 * @param createWorkHistoryDto 作業履歴
 */
export const createWorkHistory = async (
  createWorkHistoryDto: CreateWorkHistoryDto,
  createdByName?: string,
  createdBy?: string,
  createdAt?: Date,
) => {
  const user = await Auth.currentAuthenticatedUser();
  const workHistoryDto: WorkHistoryDto = await API.post(API_NAME, path, {
    body: {
      ...createWorkHistoryDto,
      createdByName: createdByName || user.attributes.name,
      createdBy: createdBy || user.getUsername(),
      createdAt,
    },
  });

  return workHistoryDto;
};
