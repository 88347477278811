import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Box,
  Typography,
  withStyles,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import ActionButton from 'components/Admin/common/ActionButton';
import { UpdateOrderDto, OrderDto } from 'api/orders/orders.dto';
import moment from 'moment';
import {
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
  openSnackbar,
} from 'modules/commonModule';
import { updateOrder, getOrder } from 'api/orders/orders.api';
import Photo from 'components/common/Photo';
import { useForm } from 'react-hook-form';
import {
  uploadS3Base64Image,
  isBase64Image,
  removeS3Image,
} from 'utils/awsUtils';
import { Auth } from 'aws-amplify';
import CustomTextField from 'components/Admin/common/CustomTextField';
import FileUplodeButton from 'components/Admin/common/FileUplodeButton';
import { useHistory } from 'react-router-dom';
import CustomToggleButtons from '../common/CustomToggleButtons';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContentArea: {
      margin: 0,
    },
    cancelBtn: {
      width: '10%',
      minWidth: '120px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    toggleGroup: {
      width: '100%',
    },
    inputFileBtnHide: {},
  }),
);

/* material-uiコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const StyledIconButton = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(IconButton);

type FormData = {
  orderId: string;
  orderNo: number;
  ecOrderNo: string;
  customerName: string;
  orderedAt: string;
  deliveryDate: string;
  qty: number;
  startedAt: string;
  endedAt: string;
  siteUrl: string;
  isNoAccess: boolean;
  formPhoto: string;
};
type PropsType = {
  open: boolean;
  handleClose: (reloadFlg: boolean) => void;
  orderId?: string;
};
const OrderEditDialog: FC<PropsType> = ({ open, handleClose, orderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, setValue } = useForm<FormData>();
  const [selectedIsNoAccess, setSelectedSiteAccessible] = useState<boolean>();
  const [formPhotoName, setFormPhotoName] = useState<string>('');
  const [formPhoto, setFormPhoto] = useState<string>('');
  const [defaultFormPhoto, setDefaultFormPhoto] = useState<string>('');
  const [orderNo, setOrderNo] = useState<number>();

  // ToggleButton用 データリスト
  const accessStatusList = useMemo(() => {
    return [
      {
        value: true,
        buttonLabel: '禁止',
        dataCy: 'dialog-accessDeny-button',
      },
      {
        value: false,
        buttonLabel: '許可',
        dataCy: 'dialog-accessAllow-button',
      },
    ];
  }, []);

  /**
   * ラジオボタン選択値をstateにセット
   */
  const handleIsNoAccess = useCallback((isNoAccess: boolean) => {
    // ボタンの未選択状態を許可しない
    if (isNoAccess !== null) {
      setSelectedSiteAccessible(isNoAccess);
    }
  }, []);

  /**
   * ダイアログ（エクスプローラー）で選択した画像ファイルの情報をstateにセット
   */
  const setFormPhotoInfo = useCallback(
    (fileName: string, base64Image: string) => {
      setFormPhotoName(fileName);
      setFormPhoto(base64Image);
    },
    [],
  );

  /**
   * ダイアログを閉じる
   */
  const onClose = useCallback(
    (reloadFlg: boolean) => {
      setFormPhotoInfo('', '');
      handleClose(reloadFlg);

      // ダイアログをブラウザバックで閉じる対応
      history.goBack();
    },
    [handleClose, history, setFormPhotoInfo],
  );

  // ブラウザバックでダイアログを閉じる
  useEffect(() => {
    if (open) {
      window.onpopstate = () => {
        handleClose(false);
      };
    }
  }, [open, handleClose]);

  /**
   * 申込書のアップロード
   */
  const uploadFormPhoto = useCallback(
    async (uploadOrderId: string) => {
      let uploadFormPhotoDto: UpdateOrderDto | undefined;
      const fileName = `${uploadOrderId}/${uploadOrderId}`;

      if (isBase64Image(formPhoto)) {
        const formPhotoKey = await uploadS3Base64Image(
          formPhoto,
          fileName,
          'protected',
        );
        if (formPhotoKey) {
          const credentials = await Auth.currentCredentials();
          uploadFormPhotoDto = {
            formPhoto: `${credentials.identityId},${formPhotoKey}`,
          };
        }
      } else if (formPhoto === '' && formPhoto !== defaultFormPhoto) {
        await removeS3Image(fileName, 'protected');
        uploadFormPhotoDto = { formPhoto: null };
      }

      return uploadFormPhotoDto;
    },
    [defaultFormPhoto, formPhoto],
  );

  /**
   * 注文情報の更新
   */
  const onSubmit = handleSubmit(async (props: FormData) => {
    try {
      dispatch(startLoading());

      // 写真が登録されている場合はアップロードして更新する
      const uploadFormPhotoDto = await uploadFormPhoto(props.orderId);
      const updateOrderDto: UpdateOrderDto = {
        isNoAccess: selectedIsNoAccess,
        ...uploadFormPhotoDto,
      };

      const order = await updateOrder(props.orderId, updateOrderDto);

      dispatch(endLoading());

      if (order) {
        dispatch(
          openSnackbar({
            text: '注文情報を更新しました。',
          }),
        );
      }

      onClose(true);
    } catch (e) {
      dispatch(
        failLoading(
          createErrorObject('注文情報変更でエラーが発生しました。', e),
        ),
      );
    }
  });

  useEffect(() => {
    (async () => {
      if (open) {
        try {
          dispatch(startLoading());

          if (orderId) {
            // 注文情報を取得
            const orderInfo = await getOrder(orderId);

            if (orderInfo) {
              setSelectedSiteAccessible(orderInfo?.isNoAccess);
              setDefaultFormPhoto(orderInfo.formPhoto || '');
              setFormPhoto(orderInfo.formPhoto || '');
              setOrderNo(orderInfo.orderNo);

              // 入力フォームの初期値設定
              Object.keys(orderInfo).forEach(key => {
                const value = orderInfo[key as keyof OrderDto];
                if (key === 'startedAt' || key === 'endedAt') {
                  setValue(
                    key,
                    moment(value as Date).format('YYYY-MM-DDTHH:mm'),
                  );
                } else if (key === 'orderedAt' || key === 'deliveryDate') {
                  setValue(key, moment(value as Date).format('YYYY-MM-DD'));
                } else if (key === 'orderId') {
                  setValue(key, value as string);
                  setValue(
                    'siteUrl',
                    `${process.env.REACT_APP_CUSTOMER_SITE_URL}/${value}`,
                  );
                } else {
                  setValue(key, value);
                }
              });
            }
          }

          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('注文情報を取得できませんでした。', e, true),
            ),
          );
        }
      }
    })();
  }, [dispatch, setValue, orderId, open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        scroll="paper"
        maxWidth="md"
        data-cy="orderEdit-dialog"
      >
        <Grid container justify="space-between">
          <DialogTitle
            id="dialogTitle"
            className={classes.dialogTitle}
            disableTypography
          >
            <Typography
              component="h2"
              variant="h2"
              data-cy="orderEdit-dialog-title-label"
            >
              注文情報の変更
            </Typography>
          </DialogTitle>
          <DialogActions>
            <StyledIconButton
              onClick={() => onClose(false)}
              data-cy="orderEdit-dialog-close-button"
            >
              <CancelIcon />
            </StyledIconButton>
          </DialogActions>
        </Grid>
        <DialogContent dividers>
          <form onSubmit={onSubmit} noValidate>
            <Grid
              container
              item
              xs={12}
              justify="space-between"
              alignItems="flex-start"
              className={classes.dialogContentArea}
            >
              <Grid container item xs={7} spacing={2}>
                <Grid item xs={12}>
                  <CustomTextField
                    name="orderNo"
                    label="受付番号"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-orderNo-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="ecOrderNo"
                    label="ECサイト注文番号"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-ecOrderNo-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="customerName"
                    label="お客様氏名"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-customerName-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    type="date"
                    name="orderedAt"
                    label="注文日"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-orderAt-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    type="date"
                    name="deliveryDate"
                    label="希望納期"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-deliveryDate-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="qty"
                    label="衣類点数"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-qty-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    type="datetime-local"
                    name="startedAt"
                    label="検品開始日時"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-startedAt-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    type="datetime-local"
                    name="endedAt"
                    label="最終検品日時"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-endedAt-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="siteUrl"
                    label="お客様ページURL"
                    disabled
                    size="small"
                    inputRef={register}
                    inputProps={{
                      'data-cy': 'dialog-siteUrl-input',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomToggleButtons
                    label="サイトアクセス許可"
                    value={selectedIsNoAccess}
                    exclusive
                    dataList={accessStatusList}
                    onChange={handleIsNoAccess}
                    data-cy="dialog-isNoAccess-buttons"
                  />
                </Grid>
              </Grid>
              <Grid container item xs={5} spacing={2}>
                <Grid item xs={12}>
                  <Photo
                    caption="申込書"
                    image={formPhoto}
                    setImage={setFormPhoto}
                    level="protected"
                    isAdmin
                    nameOption={`000000${orderNo}`.slice(-6)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="formPhoto"
                    label="アップロード画像"
                    size="small"
                    value={formPhotoName || ''}
                    inputProps={{
                      'data-cy': 'dialog-formPhoto-input',
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FileUplodeButton setPhotoInfo={setFormPhotoInfo} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box textAlign="right" mt={4}>
              <Box display="inline-block" width="130px">
                <ActionButton
                  type="button"
                  color="primary"
                  data-cy="dialog-regist-button"
                  fullWidth
                  onClick={onSubmit}
                >
                  登録
                </ActionButton>
              </Box>
            </Box>
            <input type="hidden" name="orderId" ref={register} />
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderEditDialog;
