import React, { FC, useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ActionButton from 'components/common/ActionButton';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { useForm } from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { getUsersByUnAuth } from 'api/users/users.api';
import { Autocomplete } from '@material-ui/lab';
import { UserInfoDto } from 'api/users/users.dto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      width: '100%',
      maxWidth: '100%',
      padding: 0,
    },
    loginWrap: {
      height: '100vh',
      position: 'relative',
    },
    loginBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    typo: {
      display: 'inline-block',
      position: 'relative',
      zIndex: 1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: 'white',
    },
    item: {
      width: '20%',
      minWidth: '165px',
    },
    toggleGroup: {
      width: '100%',
    },
    loginBtn: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const ColorToggleButton = withStyles(theme => ({
  root: {
    fontWeight: 600,
    flexGrow: 1,
    borderColor: theme.palette.divider,
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(ToggleButton);

type FormData = {
  username: string;
  password: string;
};

const LoginPage: FC<{}> = () => {
  const history = useHistory();
  const classes = useStyles();
  const [userDto, setUserDto] = useState<UserInfoDto | null>(null);
  const [userIdDto, setUserIdDto] = useState<string | null>(null);
  const [userListDto, setUserListDto] = useState<UserInfoDto[]>();
  const [selectedWorkProcess, setSelectedWorkProcess] = useState<WorkProcess>();
  const { register, handleSubmit, errors, setError } = useForm<FormData>({
    defaultValues: {
      username: '',
      password: '',
    },
    reValidateMode: 'onSubmit',
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { openDialog, dialogProps } = useDialog();

  /**
   * ログイン
   */
  const onSubmit = handleSubmit(async ({ password }) => {
    let user;
    try {
      dispatch(startLoading());

      const userId = userDto?.userId || userIdDto;

      if (userId) {
        user = await Auth.signIn(userId, password);
        dispatch(endLoading());
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          history.push('/registeruser', { userId, password });

          return;
        }

        let workProcess = selectedWorkProcess;
        if (!workProcess) {
          workProcess = user.attributes['custom:workProcess'];
        }

        if (workProcess === WorkProcess.Inspection) {
          history.push('/inspection/readlabel', { workProcess });
        } else if (workProcess === WorkProcess.Repair) {
          history.push('/repair/readtag', { workProcess });
        } else if (workProcess === WorkProcess.StainRemoval) {
          history.push('/stainremoval/readtag', { workProcess });
        } else if (workProcess === WorkProcess.Confirmation) {
          history.push('/confirmation/readtag', { workProcess });
        } else if (workProcess === WorkProcess.Admin) {
          history.push('/admin/dashboard', { workProcess });
        }
      }
    } catch (e) {
      dispatch(endLoading());
      if (e.code === 'NotAuthorizedException') {
        setError([
          {
            type: 'required',
            name: 'username',
            message: '作業者名またはパスワードが間違っています。',
          },
          {
            type: 'required',
            name: 'password',
            message: '作業者名またはパスワードが間違っています。',
          },
        ]);
      } else {
        if (e.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
        } else if (e.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
        } else if (e.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
        }

        openDialog({
          title: 'ログインでエラーが発生しました。',
          content: (
            <>
              code:{e.code}
              <br />
              name:{e.name}
              <br />
              message:{e.message}
            </>
          ),
        });
      }
    } finally {
      dispatch(endLoading());
    }
  });

  const changeWorkProcess = (
    event: React.MouseEvent<HTMLElement>,
    workProcess: WorkProcess,
  ) => {
    setSelectedWorkProcess(workProcess);
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());

        // ユーザー名プルダウン用にユーザー情報を取得（管理者ユーザーはプルダウンに表示しない）
        const users = await getUsersByUnAuth({
          workProcess: [
            WorkProcess.Inspection,
            WorkProcess.LintRemoval,
            WorkProcess.Repair,
            WorkProcess.StainRemoval,
            WorkProcess.Confirmation,
          ],
        });
        setUserListDto(users);
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('ユーザー情報を取得できませんでした。', e, true),
          ),
        );
      } finally {
        dispatch(endLoading());
      }
    })();
  }, [dispatch]);

  return (
    <form onSubmit={onSubmit} noValidate>
      <CustomDialog {...dialogProps} />
      <Container component="main" className={classes.container}>
        <CssBaseline />
        <Box className={classes.loginWrap}>
          <Box className={classes.loginBox} minWidth="450px">
            <Box textAlign="center" mb={1}>
              <img src="/logo.png" alt="Logo" data-cy="logo-image" />
            </Box>
            <Box textAlign="center" mb={6}>
              <Typography variant="caption" noWrap>
                作業用アプリ
              </Typography>
            </Box>
            <Box mb={2}>
              <Autocomplete
                options={userListDto}
                value={userDto}
                getOptionLabel={option => option.userName}
                getOptionSelected={(option, value) => option === value}
                noOptionsText="該当するユーザー名がありません"
                freeSolo
                onChange={(
                  event: React.ChangeEvent<{}>,
                  selectedValue: UserInfoDto | null,
                ) => {
                  setUserDto(selectedValue);
                }}
                onInputChange={(
                  event: React.ChangeEvent<{}>,
                  value: string | null,
                ) => {
                  setUserIdDto(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="username"
                    label="作業者名"
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    helperText={errors.username ? errors.username.message : ''}
                    error={!!errors.username}
                    inputRef={register({
                      required: '作業者名を入力してください。',
                    })}
                  />
                )}
                data-cy="username-autocomplete"
              />
            </Box>
            <Box mb={3}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={!!errors.password}
              >
                <InputLabel htmlFor="password">パスワード</InputLabel>
                <OutlinedInput
                  name="password"
                  id="password"
                  type={isShowPassword ? 'text' : 'password'}
                  autoComplete="off"
                  inputRef={register({
                    required: 'パスワードを入力してください。',
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        data-cy="mask-button"
                      >
                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  aria-describedby="passwordHelperText"
                  inputProps={{
                    'data-cy': 'password-input',
                    'aria-label': 'weight',
                  }}
                  labelWidth={100}
                />
                {!!errors.password && (
                  <FormHelperText id="passwordHelperText">
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                type="password"
                name="dummy"
                value=""
                autoComplete="off"
                disabled
                style={{ display: 'none' }}
              />
            </Box>
            <Box position="relative" textAlign="center" mb={1}>
              <Typography variant="h5" noWrap className={classes.typo}>
                担当工程
              </Typography>
              <Box position="absolute" width="100%" top="50%">
                <Divider />
              </Box>
            </Box>
            <Box mb={4}>
              <ToggleButtonGroup
                size="large"
                exclusive
                onChange={changeWorkProcess}
                value={selectedWorkProcess}
                className={classes.toggleGroup}
                data-cy="process-buttons"
              >
                <ColorToggleButton
                  key={WorkProcess.Inspection}
                  value={WorkProcess.Inspection}
                  data-cy="inspection-button"
                >
                  検品
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.Repair}
                  value={WorkProcess.Repair}
                  data-cy="repair-button"
                >
                  修理
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.StainRemoval}
                  value={WorkProcess.StainRemoval}
                  data-cy="stainRemoval-button"
                >
                  染み/汚れ
                </ColorToggleButton>
                <ColorToggleButton
                  key={WorkProcess.Confirmation}
                  value={WorkProcess.Confirmation}
                  data-cy="confirmation-button"
                >
                  検査
                </ColorToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              ml="auto"
              mr="auto
          "
            >
              <ActionButton
                data-cy="login-button"
                type="submit"
                fullWidth
                color="primary"
                className={classes.loginBtn}
              >
                ログイン
              </ActionButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </form>
  );
};
export default LoginPage;
