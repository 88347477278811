import { API, Auth } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { trimQueryStringParameters, callGetApi } from 'utils/apiUtils';
import {
  OrderDto,
  CreateOrderDto,
  UpdateOrderDto,
  OrderConditions,
  OrderAdminListDto,
  OrderIdListDto,
  SendMailErrorDto,
  UpdateOrdersDto,
  ErrorOrdersDto,
} from './orders.dto';

const path = '/orders';

/**
 * 注文情報を取得
 * @param orderNo 受注番号
 */
export const getOrderByNo = async (orderNo: number) => {
  try {
    const order: OrderDto = await callGetApi(path, {
      queryStringParameters: {
        orderNo,
      },
    });

    return order;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};

/**
 * 注文情報を取得
 * @param orderId 注文Id
 */
export const getOrder = async (orderId: string) => {
  const order: OrderDto = await callGetApi(`${path}/${orderId}`, {});

  return order;
};

/**
 * 注文情報を登録
 * @param createOrderDto 注文情報
 */
export const createOrder = async (createOrderDto: CreateOrderDto) => {
  const user = await Auth.currentAuthenticatedUser();
  const order: OrderDto = await API.post(API_NAME, path, {
    body: { ...createOrderDto, createdBy: user.getUsername() },
  });

  return order;
};

/**
 * 注文情報を更新
 * @param orderId 注文Id
 * @param updateOrderDto 注文情報
 */
export const updateOrder = async (
  orderId: string,
  updateOrderDto: UpdateOrderDto,
) => {
  const user = await Auth.currentAuthenticatedUser();
  const order: OrderDto = await API.patch(API_NAME, `${path}/${orderId}`, {
    body: { ...updateOrderDto, updatedBy: user.getUsername() },
  });

  return order;
};

/**
 * 検品終了日時を更新
 * @param orderId 注文Id
 */
export const updateOrderEndedAt = async (orderId?: string) => {
  if (orderId) {
    return updateOrder(orderId, { endedAt: 'NOW()' });
  }

  return undefined;
};

/**
 * 注文情報を削除
 * @param orderId 注文Id
 */
export const deleteOrder = async (orderId: string) => {
  const user = await Auth.currentAuthenticatedUser();
  await API.del(API_NAME, `${path}/${orderId}`, {
    body: { deletedBy: user.getUsername() },
  });
};

/**
 * 注文情報を削除
 * @param orderNo 受注番号
 */
export const deleteTestData = async (orderNo: number) => {
  await API.del(API_NAME, path, {
    body: { orderNo, isHardDelete: true },
  });
};

/**
 * 検索条件から注文情報一覧を取得（管理画面用）
 * @param page ページ
 * @param perPage 1ページあたりの件数
 * @param conditions 検索条件
 */
export const getOrderListByConditions = async (
  conditions?: OrderConditions,
) => {
  try {
    const orderList: OrderAdminListDto = await callGetApi(`${path}/admin`, {
      queryStringParameters: trimQueryStringParameters(conditions),
    });

    return orderList;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};

/**
 * URL通知メール送信登録
 * @param orderIdList 注文Idリスト
 */
export const sendUrlNotificationMail = async (orderIdList: OrderIdListDto) => {
  try {
    const sendMailError: SendMailErrorDto[] = await API.post(
      API_NAME,
      `${path}/urlnotification`,
      {
        body: { ...orderIdList },
      },
    );

    return sendMailError;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};

/**
 * 一括で注文情報を更新
 * @param updateOrdersDto 注文情報リスト
 */
export const updateOrders = async (updateOrdersDto: UpdateOrdersDto[]) => {
  try {
    const orders: ErrorOrdersDto[] = await API.put(API_NAME, `${path}/bulk`, {
      body: {
        updateOrders: updateOrdersDto,
      },
    });

    return orders;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};
