import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import CustomDialog, { useMsgDialog } from 'components/common/CustomDialog';

interface PropsType {
  setPhotoInfo: (fileName: string, base64Image: any) => void;
}

const FileUplodeButton: FC<PropsType> = ({ setPhotoInfo }) => {
  const { openMsgDialog, msgDialogProps } = useMsgDialog();

  /**
   * ファイル選択時のイベント
   */
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        const file = files[0];
        const reader = new FileReader();

        if (file.type === 'image/jpeg') {
          reader.readAsDataURL(file);
          reader.onload = () => {
            const fileInfo = {
              name: file.name,
              type: file.type,
              size: `${Math.round(file.size / 1000)} kB`,
              base64: reader.result,
              file,
            };

            setPhotoInfo(fileInfo.name, fileInfo.base64);
          };
        } else {
          openMsgDialog({
            title: 'ファイルアップロード結果',
            content: 'jpg/jpeg拡張子以外のファイルはアップロードできません。',
          });
        }
      }
    },
    [openMsgDialog, setPhotoInfo],
  );

  return (
    <>
      <CustomDialog {...msgDialogProps} />
      <Button component="label" style={{ display: 'contents' }}>
        <input
          type="file"
          accept="image/jpeg"
          className="inputFileBtnHide"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <PublishOutlinedIcon />
      </Button>
    </>
  );
};

export default FileUplodeButton;
