import { callGetApi } from 'utils/apiUtils';
import { WorkContentDto } from './workContents.dto';

/**
 * 作業内容一覧を取得
 */
export const getWorkContentList = async () => {
  const workContentDto: WorkContentDto[] = await callGetApi(
    '/workContents',
    {},
  );

  return workContentDto;
};
