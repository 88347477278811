import React, { FC, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  TextField,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { OrderDto } from 'api/orders/orders.dto';
import AssignmentIcon from '@material-ui/icons/Assignment';
import moment from 'moment';
import { getOrderByNo } from 'api/orders/orders.api';
import ActionButton from './ActionButton';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContentArea: {
      margin: 0,
    },
    cancelBtn: {
      width: '10%',
      minWidth: '120px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface PropsType {
  orderNo?: number;
}
const OrderNoField: FC<PropsType> = ({ orderNo }) => {
  const classes = useStyles();
  const [orderDto, setOrderDto] = useState<OrderDto>();
  const [isOpenOrderInfoDialog, setIsOpenOrderInfoDialog] = useState(false);

  /**
   * ダイアログを表示
   */
  const openOrderInfoDialog = () => {
    setIsOpenOrderInfoDialog(true);

    (async () => {
      if (orderNo) {
        const order = await getOrderByNo(orderNo);
        setOrderDto(order);
      }
    })();
  };

  /**
   * ダイアログを閉じる
   */
  const closeOrderInfoDialog = () => {
    setIsOpenOrderInfoDialog(false);
  };

  return (
    <>
      <TextField
        name="order_no"
        label="受付番号"
        variant="outlined"
        fullWidth
        disabled
        value={orderNo ? `000000${orderNo}`.slice(-6) : ' '}
        onClick={openOrderInfoDialog}
        inputProps={{
          'data-cy': 'order-no-input',
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <AssignmentIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Dialog
        open={isOpenOrderInfoDialog}
        onClose={closeOrderInfoDialog}
        scroll="paper"
        maxWidth="sm"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        data-cy="orderInfo-dialog"
      >
        <Grid container justify="space-between">
          <DialogTitle
            id="scroll-dialog-title"
            className={classes.dialogTitle}
            disableTypography
          >
            <Typography
              component="h2"
              variant="h2"
              data-cy="orderInfo-dialog-title-label"
            >
              注文情報
            </Typography>
          </DialogTitle>
          <DialogActions>
            <ActionButton
              onClick={closeOrderInfoDialog}
              color="delete"
              autoFocus
              size="small"
              className={classes.cancelBtn}
              data-cy="orderInfo-dialog-close-button"
            >
              キャンセル
            </ActionButton>
          </DialogActions>
        </Grid>
        <DialogContent dividers>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            justify="space-between"
            className={classes.dialogContentArea}
          >
            <Grid container item xs={6} spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="order_no"
                  label="受付番号"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.orderNo
                      ? `000000${orderDto?.orderNo}`.slice(-6)
                      : ' '
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="ec_order_no"
                  label="ECサイト注文番号"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={orderDto?.ecOrderNo || ' '}
                  inputProps={{
                    'data-cy': 'ec-order-no-input',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="お客様氏名"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={orderDto?.customerName || ' '}
                  inputProps={{
                    'data-cy': 'customer-name-input',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="order_tm"
                  label="注文日時"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.orderedAt
                      ? moment(orderDto?.orderedAt).format('YYYY-MM-DD HH:mm')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'ordered-at-input',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="delivery_tm"
                  label="希望納期"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.deliveryDate
                      ? moment(orderDto?.deliveryDate).format('YYYY-MM-DD')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'delivery-date-input',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="clothes_count"
                  label="衣類点数"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={orderDto?.qty || ' '}
                  inputProps={{
                    'data-cy': 'qty-input',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="check_start_tm"
                  label="検品開始日時"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.startedAt
                      ? moment(orderDto?.startedAt).format('YYYY-MM-DD HH:mm')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'started-at-input',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="check_end_tm"
                  label="検品終了日時"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.endedAt
                      ? moment(orderDto?.endedAt).format('YYYY-MM-DD HH:mm')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'ended-at-input',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default OrderNoField;
