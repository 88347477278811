import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CustomCard from 'components/common/CustomCard';
import Typography from '@material-ui/core/Typography';
import { Box, CardActionArea, Theme, Grid } from '@material-ui/core';
import {
  WorkType,
  WorkSide,
  WorkingResult,
  ConfirmationResult,
} from 'api/works/works.dto';
import {
  workSides,
  workTypes,
  workingResults,
  confirmationResults,
  NO_IMAGE,
} from 'app/constants';
import { fabric } from 'fabric';
import {
  WORKAREA_WIDTH,
  WORKAREA_HEIGHT,
  WORKAREA_IMAGE_WIDTH,
  WORKAREA_IMAGE_TOP,
  WORKAREA_IMAGE_LEFT,
  createCircle,
  setBackgroundText,
} from './ClothingImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      overflow: 'unset',
    },
    header: {
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'center',
    },
    mediaWrap: {
      borderBottom: `1px solid${theme.palette.divider}`,
    },
    cardContent: {
      borderTop: `1px solid${theme.palette.divider}`,
      textAlign: 'center',
      padding: theme.spacing(0),
    },
    cardContentInner: {
      '& >div:last-of-type': {
        borderLeft: `1px solid${theme.palette.divider}`,
      },
    },
    result: {
      fontFamily: theme.typography.fontFamily,
      '& >div:first-of-type': {
        fontSize: theme.typography.h5.fontSize,
        paddingTop: theme.spacing(1),
      },
    },
  }),
);

interface PropsType {
  workId: string;
  workType: WorkType;
  workContentName?: string | null;
  workSide?: WorkSide | null;
  workLocation?: string | null;
  clothingTypePicture?: string | null;
  workingResult: WorkingResult;
  confirmationResult: ConfirmationResult;
  onClick: (workType: WorkType, workId: string) => void | Promise<void>;
}
const WorkDetail: FC<PropsType> = ({
  workId,
  workType,
  workContentName,
  workSide,
  workLocation: propsWorkLocation,
  clothingTypePicture,
  workingResult,
  confirmationResult,
  onClick,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [workLocation, setWorkLocation] = useState<any>();
  const workAreaRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabric.StaticCanvas | null>(null);
  interface ResultPropsType {
    resultLabel: string;
  }
  const ResultLabel: FC<ResultPropsType> = ({ resultLabel }) => {
    return (
      <Box
        component="span"
        fontWeight={700}
        fontSize="1.8rem"
        color={(() => {
          if (
            resultLabel === workingResults.Perfect.icon ||
            resultLabel === workingResults.Almost.icon ||
            resultLabel === confirmationResults.Ok.icon
          ) {
            return 'info.dark';
          }
          if (resultLabel === workingResults.Part.icon) {
            return 'warning.dark';
          }
          if (
            resultLabel === workingResults.CouldNot.icon ||
            resultLabel === workingResults.NotRequired.icon ||
            resultLabel === confirmationResults.Ng.icon
          ) {
            return 'error.dark';
          }

          return null;
        })()}
      >
        {resultLabel}
      </Box>
    );
  };

  useEffect(() => {
    if (workAreaRef.current) {
      const htmlCanvas = document.getElementById(
        `canvas${workId}`,
      ) as HTMLCanvasElement;
      htmlCanvas.width = workAreaRef.current.clientWidth;
      htmlCanvas.height = htmlCanvas.width * (WORKAREA_HEIGHT / WORKAREA_WIDTH);
      const canvas = new fabric.StaticCanvas(`canvas${workId}`);
      canvas.setZoom(htmlCanvas.width / WORKAREA_WIDTH);
      canvasRef.current = canvas;
    }
  }, [workId]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const setBackground = () => {
        fabric.Image.fromURL(
          clothingTypePicture || NO_IMAGE,
          (img: fabric.Image) => {
            img.scaleToWidth(WORKAREA_IMAGE_WIDTH);
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
              left: WORKAREA_IMAGE_LEFT,
              top: clothingTypePicture ? WORKAREA_IMAGE_TOP : -16,
            });
            setBackgroundText(canvas);
          },
        );
      };

      if (workLocation) {
        if (workLocation.x) {
          canvas.add(
            createCircle(
              {
                x: workLocation.x * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_LEFT,
                y: workLocation.y * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_TOP,
              },
              workType,
            ),
          );
        } else {
          canvas.loadFromDatalessJSON(workLocation, setBackground);

          return;
        }
      }
      setBackground();
    }
  }, [clothingTypePicture, workLocation, workType]);

  useEffect(() => {
    setWorkLocation(propsWorkLocation && JSON.parse(propsWorkLocation));
  }, [propsWorkLocation]);

  const onWorkDetailClick = useCallback(async () => {
    if (onClick) {
      await onClick(workType, workId);
    }
  }, [onClick, workId, workType]);

  return (
    <CustomCard className={classes.card} onClick={onWorkDetailClick}>
      <CardActionArea>
        <Box
          bgcolor={workTypes[workType].mainColor}
          position="relative"
          borderRadius="10px 10px 0 0"
          data-cy="work-detail-header"
        >
          <Box
            position="absolute"
            bgcolor={workTypes[workType].mainColor}
            borderRadius="50%"
            border={1}
            borderColor={workTypes[workType].darkColor}
            width="20%"
            height="20%"
            padding="10%"
            top="-10px"
            left="-10px"
          >
            <Box
              position="absolute"
              left="0"
              top="50%"
              margin="-.7em 0 0"
              width="100%"
              textAlign="center"
              component="span"
              color="common.white"
              fontWeight={700}
              data-cy="work-type-icon-label"
            >
              {workTypes[workType].label}
            </Box>
          </Box>
          <Typography
            component="p"
            variant="caption"
            className={classes.header}
          >
            <Box
              component="span"
              fontWeight={700}
              color="common.white"
              data-cy="work-content-label"
            >
              {workContentName || '未設定'}
            </Box>
          </Typography>
        </Box>
        <Box position="relative">
          <div ref={workAreaRef}>
            <canvas id={`canvas${workId}`} data-cy="work-area" />
          </div>
          {workSide && (
            <Box
              position="absolute"
              bgcolor={workSides[workSide].mainColor}
              borderRadius="50%"
              border={2}
              borderColor={workSides[workSide].border}
              width="15%"
              height="15%"
              padding="8%"
              top="10px"
              right="10px"
            >
              <Box
                position="absolute"
                left="0"
                top="50%"
                margin="-.7em 0 0"
                width="100%"
                textAlign="center"
                component="span"
                color={workSides[workSide].contrastText}
                fontWeight={700}
                data-cy="workside-label"
              >
                {workSides[workSide].label}
              </Box>
            </Box>
          )}
        </Box>
        <CardContent className={classes.cardContent}>
          <Grid container className={classes.cardContentInner}>
            <Grid item xs={6} className={classes.result}>
              <Box>作業結果</Box>
              <Box data-cy="work-result-label">
                <ResultLabel resultLabel={workingResults[workingResult].icon} />
              </Box>
            </Grid>
            <Grid item xs={6} className={classes.result}>
              <Box>検査結果</Box>
              <Box data-cy="confirmation-result-label">
                <ResultLabel
                  resultLabel={confirmationResults[confirmationResult].icon}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </CustomCard>
  );
};
export default WorkDetail;
