import { IMAGE_BASE64_PREFIX, IMAGE_MIME } from 'app/constants';
import { Storage, Auth } from 'aws-amplify';
import moment from 'moment';

/**
 * 画像データがBase64かどうか
 * @param imageData 画像データ
 */
export const isBase64Image = (imageData: string) => {
  return imageData.startsWith('data:');
};

/**
 * Base64画像のアップロード
 * @param base64ImageData 画像データ
 * @param fileName ファイル名
 * @param level アクセスレベル
 */
export const uploadS3Base64Image = async (
  base64ImageData: string,
  fileName: string,
  level?: 'private' | 'protected' | 'public',
) => {
  const s3PutResponse: { key?: string } = await Storage.put(
    `${fileName}.jpg`,
    Buffer.from(base64ImageData.replace(IMAGE_BASE64_PREFIX, ''), 'base64'),
    {
      level,
      contentType: IMAGE_MIME,
    },
  );

  return s3PutResponse.key;
};

/**
 * 画像の削除
 * @param fileName ファイル名
 * @param level アクセスレベル
 */
export const removeS3Image = async (
  fileName: string,
  level?: 'private' | 'protected' | 'public',
) => {
  return Storage.remove(`${fileName}.jpg`, { level });
};

/**
 * エラーログのアップロード
 * @param errorObject エラーオブジェクト
 */
export const uploadErrorLog = async (
  screenTitle: string,
  errorObject: {
    message: string;
    summary: string;
    detail: string;
  },
) => {
  try {
    const now = new Date();
    const user = await Auth.currentAuthenticatedUser();
    const errorText = `日時:\n${moment(now).format(
      'YYYY/MM/DD HH:mm:ss.SSS',
    )}\n\nUA:\n${window.navigator.userAgent}\n\nユーザー:\n${
      user.username
    }\n\n画面:\n${screenTitle}\n\nメッセージ:\n${
      errorObject.message
    }\n\n概要:\n${errorObject.summary}\n\n詳細:\n${errorObject.detail}`;

    // eslint-disable-next-line no-console
    console.log(errorText);

    const s3PutResponse: { key?: string } = await Storage.put(
      `.logs/osekkaiWork/${moment(now).format('YYYYMMDD')}/${moment(now).format(
        'YYYYMMDDHHmmssSSS',
      )}.log`,
      errorText,
    );

    return s3PutResponse.key;
  } catch (e) {
    // 何もしない
  }

  return undefined;
};
