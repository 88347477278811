import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { RootState } from 'app/rootReducer';
import { useHistory } from 'react-router-dom';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Grid, Box } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import ActionButton from './ActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    pageTitle: {
      fontWeight: 700,
    },
    userWrap: {
      marginRight: theme.spacing(4),
    },
    account: {
      color: 'white',
      marginRight: theme.spacing(1),
    },
    userName: {
      display: 'inline-block',
    },
    logoutBtn: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '160px',
    },
  }),
);

const Header: FC<{}> = () => {
  const history = useHistory();
  const classes = useStyles();
  const { title } = useSelector((state: RootState) => state.common);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUserName(user.attributes.name);
    })();
  }, []);

  /**
   * ログアウト
   */
  const logout = async () => {
    await Auth.signOut();
    history.push('/');
  };

  return (
    <>
      <Box minWidth={1240}>
        <AppBar position="static" className={classes.appBar} data-cy="header">
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography
                className={classes.pageTitle}
                component="h1"
                variant="h2"
                noWrap
                data-cy="title-label"
              >
                {title}
              </Typography>
            </Grid>

            <Grid item container justify="flex-end" alignItems="center" xs={6}>
              <Grid item className={classes.userWrap}>
                <AccountCircle className={classes.account} />
                <Typography
                  className={classes.userName}
                  component="p"
                  variant="caption"
                  noWrap
                  data-cy="user-name-label"
                >
                  {userName}
                </Typography>
              </Grid>
              <Grid item>
                <Box>
                  <ActionButton
                    onClick={logout}
                    color="logout"
                    fullWidth
                    endIcon={<MeetingRoomIcon />}
                    className={classes.logoutBtn}
                    data-cy="logout-button"
                  >
                    ログアウト
                  </ActionButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </AppBar>
      </Box>
    </>
  );
};
export default Header;
