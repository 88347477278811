import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  CardActionArea,
  CardMedia,
  CardContent,
  Dialog,
  Box,
} from '@material-ui/core';
import CustomCard from 'components/common/CustomCard';
import Viewer from 'viewerjs';
import { Storage } from 'aws-amplify';
import {
  NO_IMAGE,
  NO_IMAGE_CAMERA,
  IMAGE_MIME,
  IMAGE_BASE64_PREFIX,
  IMAGE_QUALITY,
  ERROR_IMAGE,
} from 'app/constants';
import axios from 'axios';
import { isBase64Image, uploadErrorLog } from 'utils/awsUtils';
import { createErrorObject } from 'modules/commonModule';
import GetAppIcon from '@material-ui/icons/GetApp';
import CustomDialog, { useMsgDialog } from 'components/common/CustomDialog';
import Camera from './Camera';
import 'viewerjs/dist/viewer.css';
import './Photo.css';

const useStyles = makeStyles(() =>
  createStyles({
    trim: {
      '&::before': {
        content: "''",
        display: 'block',
        paddingTop: '160%',
      },
    },
    cardMedia: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
    },
    cardContent: {
      padding: '5% 0',
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.7)',
      '&:last-child': {
        paddingBottom: '5%',
      },
    },
    caption: {
      marginBottom: 0,
      color: 'white',
    },
    downloadIconBox: {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      zIndex: 2,
    },
    downloadIcon: {
      fontSize: '30px',
      color: 'white',
    },
  }),
);

// 使用可能なビデオデバイスの取得
const videoDevicesMap: { deviceId: string; label: string }[] = [];
(async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  devices.forEach(mediaDevice => {
    if (mediaDevice.kind === 'videoinput') {
      videoDevicesMap.push({
        deviceId: mediaDevice.deviceId,
        label: mediaDevice.label,
      });
    }
  });
})();

interface PhotoProps {
  caption: string;
  image?: string | null;
  setImage?: (image: string) => void;
  readonly?: boolean;
  level?: 'private' | 'protected' | 'public';
  isAdmin?: boolean;
  nameOption?: string;
}
const Photo: FC<PhotoProps> = ({
  caption,
  image: imageProp,
  setImage: setImageProp,
  readonly,
  level,
  isAdmin,
  nameOption,
}) => {
  const classes = useStyles();
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [image, setImage] = useState('');
  const [viewerImg, setViewerImg] = useState<HTMLImageElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const viewerRef = useRef<Viewer | null>(null);
  const { openMsgDialog, msgDialogProps } = useMsgDialog();

  useEffect(() => {
    (async () => {
      if (imageProp) {
        setIsLoading(true);
        if (isBase64Image(imageProp)) {
          setImage(imageProp);
        } else {
          try {
            // 画像を変換できるようaxiosで取得しておく
            let imageUrl: string;
            if (level === 'protected' || level === 'private') {
              const spilitedImageProp = imageProp.split(',');
              imageUrl = (
                await Storage.get(spilitedImageProp[1], {
                  level,
                  identityId: spilitedImageProp[0],
                })
              ).toString();
            } else {
              imageUrl = (await Storage.get(imageProp, { level })).toString();
            }

            const s3ImageResponse = await axios.get(imageUrl, {
              responseType: 'arraybuffer',
            });
            setImage(
              `${IMAGE_BASE64_PREFIX}${Buffer.from(
                s3ImageResponse.data,
                'binary',
              ).toString('base64')}`,
            );
          } catch (e) {
            setIsError(true);
            await uploadErrorLog(
              'Photo',
              createErrorObject('画像を取得できませんでした。', e),
            );
          }
        }
        setIsLoading(false);
      } else {
        setImage('');
      }
    })();

    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
    };
  }, [imageProp, level]);

  /**
   * カメラ画面を表示
   */
  const openCamera = useCallback(() => {
    // デバイスが接続されている場合のみカメラモード起動
    if (videoDevicesMap.length) {
      setIsOpenCamera(true);
    } else {
      openMsgDialog({
        title: 'カメラモード起動失敗',
        content: (
          <>
            カメラデバイスが接続されていません。
            <br />
            カメラデバイスを接続し、アプリを再起動してください。
          </>
        ),
      });
    }
  }, [openMsgDialog]);

  /**
   * 写真を回転させる
   * @param imageElement イメージ
   * @param degree 角度(0/90/180/270)
   */
  const rotateImage = (imageElement: HTMLImageElement, degree: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
      if (degree % 180) {
        canvas.width = imageElement.height;
        canvas.height = imageElement.width;
      } else {
        canvas.width = imageElement.width;
        canvas.height = imageElement.height;
      }
      switch (degree) {
        case 90:
          ctx.transform(0, 1, -1, 0, imageElement.height, 0);
          break;
        case 180:
          ctx.transform(-1, 0, 0, -1, imageElement.width, imageElement.height);
          break;
        case 270:
          ctx.transform(0, -1, 1, 0, 0, imageElement.width);
          break;
        default:
          break;
      }
      ctx.drawImage(imageElement, 0, 0);
    }

    return canvas.toDataURL(IMAGE_MIME, IMAGE_QUALITY);
  };

  /**
   * 撮影写真の決定処理
   */
  const onClickOk = useCallback(
    (img: HTMLImageElement) => {
      // 回転数に応じた角度の取得
      const cssTransforms = Array.from(
        document.getElementsByClassName('viewer-move') as HTMLCollectionOf<
          HTMLElement
        >,
      );
      const { transform } = cssTransforms[cssTransforms.length - 1].style;
      const degree =
        ((Number(transform.replace(/[^0-9-]/g, '')) % 360) + 360) % 360;

      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
      const rotatedImage = rotateImage(img, degree);

      if (setImageProp) {
        setImageProp(rotatedImage);
      }
    },
    [setImageProp],
  );

  /**
   * 撮影写真の撮り直し処理
   */
  const onClickRetake = useCallback(() => {
    if (viewerRef.current) {
      viewerRef.current.destroy();
    }
    openCamera();
  }, [openCamera]);

  useEffect(() => {
    document.onkeydown = e => {
      // 「Y」キー入力時に、決定ボタン押下時の処理を実行
      if (e.code === 'KeyY' && viewerImg) {
        onClickOk(viewerImg);
      } else if (e.code === 'KeyN') {
        // 「N」キー入力時に、撮り直しボタン押下時の処理を実行
        onClickRetake();
      }
    };
  }, [onClickOk, onClickRetake, openCamera, viewerImg]);

  /**
   * ビューア画面の表示
   * @param viewerImage ビューア表示画像
   * @param isInvertCamera カメラの上下反転指定
   */
  const openViewer = async (viewerImage: string) => {
    // 閉じるボタン/マスク押下時
    if (viewerRef.current) {
      viewerRef.current.destroy();
    }

    const img = new Image();
    img.src = viewerImage;
    setViewerImg(img);

    const customButtons = !readonly && {
      // custom 写真のリテイク
      customRetake: {
        show: true,
        size: 'large',
        click: onClickRetake,
      },
      // custom 写真の登録
      customOk: {
        show: true,
        size: 'large',
        click: () => onClickOk(img),
      },
      // custom 写真の削除
      customDelete: {
        show: true,
        size: 'large',
        click: () => {
          if (viewerRef.current) {
            viewerRef.current.destroy();
          }
          if (setImageProp) {
            setImageProp('');
          }
        },
      },
    };
    viewerRef.current = new Viewer(img, {
      navbar: 0,
      title: 0,
      hidden: () => {
        if (viewerRef.current) {
          viewerRef.current.destroy();
        }
      },
      toolbar: {
        zoomIn: { show: true, size: 'large' },
        zoomOut: { show: true, size: 'large' },
        reset: { show: true, size: 'large' },
        rotateLeft: { show: true, size: 'large' },
        rotateRight: { show: true, size: 'large' },
        oneToOne: false,
        prev: false,
        next: false,
        play: false,
        flipHorizontal: false,
        flipVertical: false,
        ...customButtons,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    });

    viewerRef.current.show();
  };

  return (
    <>
      <CustomDialog {...msgDialogProps} />
      <CustomCard>
        <CardActionArea
          disabled={isLoading}
          data-cy="photo-button"
          className={classes.trim}
          onClick={() => {
            if (!isLoading && !isError) {
              if (image) {
                openViewer(image);
              } else if (!readonly) {
                openCamera();
              }
            }
          }}
        >
          <CardMedia
            data-cy="photo-image"
            ref={imageRef}
            component="img"
            image={
              !isError
                ? image || (readonly ? NO_IMAGE : NO_IMAGE_CAMERA)
                : ERROR_IMAGE
            }
            className={classes.cardMedia}
          />
        </CardActionArea>
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            className={classes.caption}
          >
            {caption}
          </Typography>
          {!!(image && isAdmin) && (
            <Box className={classes.downloadIconBox} data-cy="download-icon">
              <a
                href={image}
                download={`${caption}_${nameOption}.jpg`}
                className={classes.downloadIcon}
              >
                <GetAppIcon />
              </a>
            </Box>
          )}
        </CardContent>
      </CustomCard>

      <Dialog fullScreen open={isOpenCamera} data-cy="camera-dialog">
        <Camera
          closeCamera={() => {
            setIsOpenCamera(false);
          }}
          openViewer={cameraImage => {
            setIsOpenCamera(false);
            openViewer(cameraImage);
          }}
        />
      </Dialog>
    </>
  );
};
export default Photo;
