import React, { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';
import {
  setTitle,
  openSnackbar,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import Photo from 'components/common/Photo';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  createOrder,
  getOrderByNo,
  deleteOrder,
  updateOrder,
  updateOrderEndedAt,
} from 'api/orders/orders.api';
import moment from 'moment';
import { OrderDto, UpdateOrderDto } from 'api/orders/orders.dto';
import {
  uploadS3Base64Image,
  isBase64Image,
  removeS3Image,
} from 'utils/awsUtils';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { Auth } from 'aws-amplify';

type LocationState = {
  workProcess: WorkProcess;
  orderNo: string;
};
const RegistOrderPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderDto, setOrderDto] = useState<OrderDto>();
  const [formPhoto, setFormPhoto] = useState('');
  const { openDialog, dialogProps } = useDialog();
  const location = useLocation<LocationState>();
  const { workProcess } = location.state;
  const orderNo = parseInt(location.state?.orderNo, 10);

  dispatch(setTitle('注文情報登録'));

  useEffect(() => {
    (async () => {
      if (orderNo) {
        try {
          dispatch(startLoading());
          const order = await getOrderByNo(orderNo);
          if (order) {
            if (!order.orderId) {
              setOrderDto({ ...order, startedAt: new Date() });
            } else {
              setOrderDto(order);
            }

            setFormPhoto(order.formPhoto || '');
          } else {
            setOrderDto({ orderNo, startedAt: new Date() });
            setFormPhoto('');
          }
          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('注文情報を取得できませんでした。', e, true),
            ),
          );
        }
      } else {
        history.push('/inspection/readlabel');
      }
    })();
  }, [dispatch, history, orderNo]);

  /**
   * 入力項目が変更されているか
   */
  const wasChanged = useCallback(() => {
    return formPhoto !== (orderDto?.formPhoto || '');
  }, [formPhoto, orderDto]);

  /**
   * 申込書のアップロード
   */
  const uploadFormPhoto = useCallback(
    async (uploadOrderId: string) => {
      let uploadFormPhotoDto: UpdateOrderDto | undefined;
      const fileName = `${uploadOrderId}/${uploadOrderId}`;

      if (isBase64Image(formPhoto)) {
        const formPhotoKey = await uploadS3Base64Image(
          formPhoto,
          fileName,
          'protected',
        );
        if (formPhotoKey) {
          const credentials = await Auth.currentCredentials();
          uploadFormPhotoDto = {
            formPhoto: `${credentials.identityId},${formPhotoKey}`,
          };
        }
      } else if (
        formPhoto === '' &&
        formPhoto !== (orderDto?.formPhoto || '')
      ) {
        await removeS3Image(fileName, 'protected');
        uploadFormPhotoDto = { formPhoto: null };
      }

      return uploadFormPhotoDto;
    },
    [formPhoto, orderDto],
  );

  /**
   * タグ読み取りへ
   */
  const readTag = useCallback(async () => {
    try {
      dispatch(startLoading());
      let order: OrderDto | undefined;
      if (!orderDto?.orderId) {
        // orderDtoがない(Order・SyncOrderともになし)
        // またはorderDto.orderIdがない(Orderなし・SyncOrderあり)場合、新規登録
        order = await createOrder({
          orderNo,
          startedAt: orderDto?.startedAt,
        });
        if (order.orderId) {
          // 写真が登録されている場合はアップロードして更新する
          const uploadFormPhotoDto = await uploadFormPhoto(order.orderId);
          if (uploadFormPhotoDto) {
            order = await updateOrder(order.orderId, uploadFormPhotoDto);
          }
          dispatch(openSnackbar({ text: '注文情報を登録しました。' }));
        }
      } else if (orderDto.orderId) {
        // orderDto.orderIdがある(Orderあり)場合で写真が変更されている場合、更新
        const uploadFormPhotoDto = await uploadFormPhoto(orderDto.orderId);
        if (uploadFormPhotoDto) {
          order = await updateOrder(orderDto.orderId, uploadFormPhotoDto);
          await updateOrderEndedAt(orderDto.orderId);
          dispatch(openSnackbar({ text: '注文情報を更新しました。' }));
        } else {
          // 変更なし
          order = orderDto;
        }
      }

      history.push('/inspection/readtag', {
        workProcess,
        orderId: order?.orderId,
        orderNo: order?.orderNo,
      });
      dispatch(endLoading());
    } catch (e) {
      dispatch(
        failLoading(createErrorObject('注文情報を登録できませんでした。', e)),
      );
    }
  }, [dispatch, history, orderDto, orderNo, uploadFormPhoto, workProcess]);

  /**
   * 戻る
   */
  const backReadLabel = useCallback(() => {
    history.push('/inspection/readlabel', { workProcess });
  }, [history, workProcess]);

  /**
   * 戻るボタン押下時の確認ダイアログを表示
   */
  const openBackConfirmDialog = useCallback(() => {
    if (wasChanged()) {
      openDialog({
        title: '管理ラベル読取へ戻ります。',
        content: '入力中の内容を破棄し、管理ラベル読取へ戻りますか？',
        buttonTitle: '戻る',
        action: backReadLabel,
      });
    } else {
      backReadLabel();
    }
  }, [backReadLabel, openDialog, wasChanged]);

  /**
   * 削除実行
   */
  const executeDelete = async () => {
    if (orderDto?.orderId) {
      try {
        dispatch(startLoading());
        await deleteOrder(orderDto.orderId);
        dispatch(
          openSnackbar({
            text: '注文情報を削除しました。',
          }),
        );
        dispatch(endLoading());
        backReadLabel();
      } catch (e) {
        dispatch(
          failLoading(createErrorObject('注文情報を削除できませんでした。', e)),
        );
      }
    }
  };

  /**
   * 削除確認ダイアログを表示
   */
  const openDeleteConfirmDialog = () => {
    openDialog({
      title: '注文情報を削除します。',
      content: (
        <>
          削除した注文情報を元に戻すことはできません。
          <br />
          削除してもよろしいですか？
        </>
      ),
      buttonTitle: '削除',
      action: executeDelete,
    });
  };

  return (
    <>
      <CustomDialog {...dialogProps} />
      <WorkArea>
        <ContentsArea>
          <Grid
            container
            spacing={5}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid container item xs={6} spacing={2}>
              <Grid item xs={8}>
                <TextField
                  name="order_no"
                  label="受付番号"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.orderNo
                      ? `000000${orderDto?.orderNo}`.slice(-6)
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'order-no-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="ec_order_no"
                  label="ECサイト注文番号"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={orderDto?.ecOrderNo || ' '}
                  inputProps={{
                    'data-cy': 'ec-order-no-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="お客様氏名"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={orderDto?.customerName || ' '}
                  inputProps={{
                    'data-cy': 'customer-name-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="order_tm"
                  label="注文日時"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.orderedAt
                      ? moment(orderDto?.orderedAt).format('YYYY-MM-DD HH:mm')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'ordered-at-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="delivery_tm"
                  label="希望納期"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.deliveryDate
                      ? moment(orderDto?.deliveryDate).format('YYYY-MM-DD')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'delivery-date-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="clothes_count"
                  label="衣類点数"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={orderDto?.qty || ' '}
                  inputProps={{
                    'data-cy': 'qty-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="check_start_tm"
                  label="検品開始日時"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.startedAt
                      ? moment(orderDto?.startedAt).format('YYYY-MM-DD HH:mm')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'started-at-input',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="check_end_tm"
                  label="検品終了日時"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={
                    orderDto?.endedAt
                      ? moment(orderDto?.endedAt).format('YYYY-MM-DD HH:mm')
                      : ' '
                  }
                  inputProps={{
                    'data-cy': 'ended-at-input',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} container spacing={2} justify="flex-end">
              <Grid item xs={11} container spacing={2} justify="flex-end">
                <Grid item xs={10}>
                  <Photo
                    caption="申込書"
                    image={formPhoto}
                    setImage={setFormPhoto}
                    level="protected"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            <ActionButton
              onClick={readTag}
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              data-cy="read-tag-button"
            >
              タグ読取へ
            </ActionButton>
          </Grid>

          <Grid item>
            {orderDto?.orderId && (
              <ActionButton
                onClick={openDeleteConfirmDialog}
                color="delete"
                endIcon={<DeleteIcon />}
                data-cy="delete-order-button"
              >
                注文情報削除
              </ActionButton>
            )}
            <ActionButton
              onClick={openBackConfirmDialog}
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              data-cy="back-button"
            >
              戻る
            </ActionButton>
          </Grid>
        </ButtonsArea>
      </WorkArea>
    </>
  );
};

export default RegistOrderPage;
