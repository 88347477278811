/* eslint-disable max-classes-per-file */
import {
  WorkSide,
  WorkingResult,
  ConfirmationResult,
  WorkType,
} from 'api/works/works.dto';
import { WorkContentDto } from 'api/workContents/workContents.dto';

/**
 * 作業工程
 */
export enum WorkProcess {
  /** 検品 */
  Inspection = 'Inspection',
  /** 毛玉取り */
  LintRemoval = 'LintRemoval',
  /** 修理 */
  Repair = 'Repair',
  /** 染み抜き */
  StainRemoval = 'StainRemoval',
  /** 検査 */
  Confirmation = 'Confirmation',
  /** 管理者 */
  Admin = 'Admin',
}

/**
 * 作業履歴
 */
export class WorkHistoryDto {
  /** 作業履歴Id */
  readonly workHistoryId!: string;

  /** 作業Id */
  readonly workId!: string;

  /** 作業工程 */
  readonly workProcess!: WorkProcess;

  /** 作業種別 */
  readonly workType!: WorkType;

  /** 作業内容Id */
  readonly workContentId!: string | null;

  /** 作業内容 */
  readonly workContent?: WorkContentDto;

  /** 作業箇所表裏 */
  readonly workSide!: WorkSide | null;

  /** 作業箇所 */
  readonly workLocation!: string | null;

  /** 作業前写真 */
  readonly beforePhoto!: string | null;

  /** 作業後写真 */
  readonly afterPhoto!: string | null;

  /** 作業結果 */
  readonly workingResult!: WorkingResult;

  /** 作業終了日時 */
  readonly workedAt!: Date | null;

  /** 検査結果 */
  readonly confirmationResult!: ConfirmationResult;

  /** 検査終了日時 */
  readonly confirmedAt!: Date | null;

  /** 登録者名 */
  readonly createdByName!: string;

  /** 登録者 */
  readonly createdBy!: string;

  /** 登録日時 */
  readonly createdAt!: Date;
}

/**
 * 登録作業履歴情報
 */
export class CreateWorkHistoryDto {
  /** 作業Id */
  workId!: string;

  /** 作業工程 */
  workProcess!: WorkProcess | WorkType;

  /** 作業種別 */
  workType!: WorkType;

  /** 作業内容Id */
  workContentId?: string | null;

  /** 作業箇所表裏 */
  workSide?: WorkSide | null;

  /** 作業箇所 */
  workLocation?: string | null;

  /** 作業前写真 */
  beforePhoto?: string | null;

  /** 作業後写真 */
  afterPhoto?: string | null;

  /** 作業結果 */
  workingResult?: WorkingResult;

  /** 作業終了日時 */
  workedAt?: Date | null;

  /** 検査結果 */
  confirmationResult?: ConfirmationResult;

  /** 検査終了日時 */
  confirmedAt?: Date | null;
}
