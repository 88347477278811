import React, { FC, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from 'recharts';
import moment from 'moment';
import { grey } from '@material-ui/core/colors';
import { colors } from 'components/common/Theme';

interface PropsType {
  implementation?: any;
  lineAreaWidth: number;
}
const LineGraph: FC<PropsType> = ({ implementation, lineAreaWidth }) => {
  const data = useMemo(() => {
    const dataArray: {
      workedDate: string;
      inspectedCount: number;
      lintRemovaledCount: number;
      repairedCount: number;
      stainRemovaledCount: number;
      confirmedCount: number;
    }[] = [];
    if (implementation) {
      Object.keys(implementation).forEach((key, index) => {
        dataArray.push({
          workedDate: moment(implementation[index].workedDate).format('M/D'),
          inspectedCount: implementation[index].inspectedCount,
          lintRemovaledCount: implementation[index].lintRemovaledCount,
          repairedCount: implementation[index].repairedCount,
          stainRemovaledCount: implementation[index].stainRemovaledCount,
          confirmedCount: implementation[index].confirmedCount,
        });
      });
    }

    return dataArray;
  }, [implementation]);

  return (
    <>
      {data && (
        <LineChart
          width={lineAreaWidth}
          height={280}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            minTickGap={30}
            dataKey="workedDate"
            interval="preserveStartEnd"
          />
          <YAxis interval="preserveStartEnd" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="inspectedCount"
            name="検品衣類点数"
            stroke={grey[600]}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="lintRemovaledCount"
            name="毛玉取り実施数"
            stroke={colors.inspection.main}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="repairedCount"
            name="修理実施数"
            stroke={colors.repair.main}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="stainRemovaledCount"
            name="染み抜き実施数"
            stroke={colors.stainRemoval.main}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="confirmedCount"
            name="検査実施数"
            stroke={colors.confirmation.main}
            dot={false}
          />
          <Brush height={25} stroke="#5495ef" />
        </LineChart>
      )}
    </>
  );
};
export default LineGraph;
