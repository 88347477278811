import React, { FC, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { WorkType } from 'api/works/works.dto';
import { InspectionProgress, WorkProgress } from 'api/aggregate/aggregate.dto';
import { workTypes } from 'app/constants';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() =>
  createStyles({
    barChart: {
      width: '100px',
    },
  }),
);

interface PropsType {
  workType?: WorkType;
  workProgress?: WorkProgress;
  inspectionProgress?: InspectionProgress;
}
const BarGraph: FC<PropsType> = ({
  workType,
  workProgress,
  inspectionProgress,
}) => {
  const classes = useStyles();

  const data = useMemo(() => {
    if (workType && workProgress) {
      return [
        {
          name: workTypes[workType].title,
          a: workProgress.workTotalCount,
          b: workProgress.workedCount,
          c: workProgress.inspectedOkCount,
        },
      ];
    }
    if (inspectionProgress) {
      return [
        {
          name: '全体状況',
          a: inspectionProgress.inspectedCount,
          b: inspectionProgress.resultOkCount,
          c: inspectionProgress.resultNgCount,
        },
      ];
    }

    return null;
  }, [inspectionProgress, workProgress, workType]);

  return (
    <>
      {data && (
        <BarChart
          width={185}
          height={240}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          className={classes.barChart}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="a"
            name={`${workType ? '作業総数' : '検品点数'}`}
            fill={`${workType ? workTypes[workType].darkColor : grey[700]}`}
          />
          <Bar
            dataKey="b"
            name={`${workType ? '作業完了' : '検査OK'}`}
            fill={`${workType ? workTypes[workType].mainColor : grey[600]}`}
          />
          <Bar
            dataKey="c"
            name={`${workType ? '検査OK' : '検査NG'}`}
            fill={`${workType ? workTypes[workType].lightColor : grey[400]}`}
          />
        </BarChart>
      )}
    </>
  );
};

export default BarGraph;
