import { API, Auth } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { callGetApi } from 'utils/apiUtils';
import {
  ClothingDto,
  ClothingListDto,
  CreateClothingDto,
  UpdateClothingDto,
} from './clothings.dto';

const path = '/clothings';

/**
 * 衣類情報を取得
 * @param clothingId 衣類Id
 */
export const getClothing = async (clothingId: string) => {
  const clothingDto: ClothingDto = await callGetApi(
    `${path}/${clothingId}`,
    {},
  );

  return clothingDto;
};

/**
 * 衣類情報取得検索条件
 */
export type GetClothingConditions = {
  tagNo: string;
  orderNo?: number;
};

/**
 * 衣類情報を取得
 * @param conditions 検索条件
 */
export const getClothingByConditions = async (
  conditions: GetClothingConditions,
) => {
  try {
    const clothingDto: ClothingDto = await callGetApi(path, {
      queryStringParameters: conditions,
    });

    return clothingDto;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined;
    }
    throw e;
  }
};

/**
 * 衣類情報を取得
 * @param orderId 注文Id
 */
export const getClothingsByOrderId = async (orderId: string) => {
  const clothingListDto: ClothingListDto[] = await callGetApi(
    `${path}/list/${orderId}`,
    {},
  );

  return clothingListDto;
};

/**
 * 衣類情報を登録
 * @param createClothingDto 衣類情報
 */
export const createClothing = async (createClothingDto: CreateClothingDto) => {
  const user = await Auth.currentAuthenticatedUser();
  const clothing: ClothingDto = await API.post(API_NAME, path, {
    body: { ...createClothingDto, createdBy: user.getUsername() },
  });

  return clothing;
};

/**
 * 衣類情報を更新
 * @param clothingId 衣類Id
 * @param updateClothingDto 衣類情報
 */
export const updateClothing = async (
  clothingId: string,
  updateClothingDto: UpdateClothingDto,
) => {
  const user = await Auth.currentAuthenticatedUser();
  const clothing: ClothingDto = await API.patch(
    API_NAME,
    `${path}/${clothingId}`,
    {
      body: { ...updateClothingDto, updatedBy: user.getUsername() },
    },
  );

  return clothing;
};

/**
 * 衣類情報を削除
 * @param clothingId 衣類Id
 */
export const deleteClothing = async (clothingId: string) => {
  const user = await Auth.currentAuthenticatedUser();
  await API.del(API_NAME, `${path}/${clothingId}`, {
    body: { deletedBy: user.getUsername() },
  });
};
