import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { closeSnackbar } from 'modules/commonModule';
import Fade from '@material-ui/core/Fade';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { colors } from './Theme';

const Alert = (props: AlertProps) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    background: colors.logout.main,
    padding: '20px',
  },
  snackbarText: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600,
  },
}));

const CustomSnackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state: RootState) => state.common);

  /**
   * ポップアップを閉じる
   */
  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <>
      <Snackbar
        open={snackbar.isOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          className={classes.alert}
        >
          <Box
            component="span"
            className={classes.snackbarText}
            data-cy="snackbar"
          >
            {snackbar.text}
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
};
export default CustomSnackbar;
