import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import {
  makeStyles,
  createStyles,
  MenuItem,
  Menu,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import LoopIcon from '@material-ui/icons/Loop';
import { IMAGE_MIME, IMAGE_QUALITY } from 'app/constants';

/** 画像サイズ(縦) */
const IMAGE_WIDTH = 1280;
/** 画像サイズ(横) */
const IMAGE_HEIGHT = 720;
/** カメラアスペクト比_A(仮) */
const VIDEO_ASPECT_RATIO_A = 16;
/** カメラアスペクト比_B(仮) */
const VIDEO_ASPECT_RATIO_B = 9;
/** カメラフレームレート */
const VIDEO_FRAME_RATE = 30;

const useStyles = makeStyles(() =>
  createStyles({
    cameraSettings: {
      display: 'block',
      position: 'relative',
      bottom: 0,
      right: 0,
      width: 'auto',
      height: 'auto',
      minHeight: '100%',
      minWidth: '100%',
      backgroundColor: '#000',
      zIndex: 1,
    },
    invertCamera: {
      transform: 'scale(-1, -1)',
    },
    closeCameraButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      zIndex: 2,
    },
    closeCameraButtonIcon: {
      display: 'inline-block',
      padding: '8px',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
    },
    shutterButton: {
      position: 'absolute',
      bottom: '5%',
      width: '100%',
      textAlign: 'center',
      zIndex: 2,
    },
    shutterButtonIcon: {
      display: 'inline-block',
      padding: '8px',
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      lineHeight: '80px',
      backgroundColor: 'rgb(200, 200, 200)',
    },
    switchButton: {
      position: 'absolute',
      top: '7px',
      left: '7px',
      zIndex: 2,
    },
    switchButtonIcon: {
      display: 'inline-block',
      padding: '8px',
      width: '60px',
      height: '60px',
      borderRadius: '50%',
      lineHeight: '60px',
      backgroundColor: 'rgb(200, 200, 200)',
    },
    upsideDownButton: {
      position: 'absolute',
      top: '83px',
      left: '7px',
      zIndex: 2,
    },
    upsideDownButtonIcon: {
      display: 'inline-block',
      padding: '8px',
      width: '60px',
      height: '60px',
      borderRadius: '50%',
      lineHeight: '60px',
      backgroundColor: 'rgb(200, 200, 200)',
    },
  }),
);

// 使用可能なビデオデバイスの取得
const videoDevicesMap: { deviceId: string; label: string }[] = [];
(async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  devices.forEach(mediaDevice => {
    if (mediaDevice.kind === 'videoinput') {
      videoDevicesMap.push({
        deviceId: mediaDevice.deviceId,
        label: mediaDevice.label,
      });
    }
  });
})();

interface CameraProps {
  closeCamera: () => void;
  openViewer: (imageBase64: string) => void;
}

const Camera: FC<CameraProps> = props => {
  const classes = useStyles();
  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [isInvertCamera, setIsInvertCamera] = useState(
    localStorage.getItem('VideoIsInvertCamera') === 'true',
  );
  // 動画トラック 設定
  const [videoConstraints, setVideoConstraints] = useState({
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    aspectRatio: VIDEO_ASPECT_RATIO_A / VIDEO_ASPECT_RATIO_B,
    frameRate: VIDEO_FRAME_RATE,
    deviceId:
      localStorage.getItem('VideoDeviceId') || videoDevicesMap[0].deviceId,
  });

  /**
   * 使用可能なビデオデバイス一覧を表示
   */
  const openVideoDeviceMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * ビデオデバイスの切り替え
   */
  const changeVideoDevice = (selectedDeviceId: string) => {
    localStorage.setItem('VideoDeviceId', selectedDeviceId);
    setSelectedDevice(selectedDeviceId);
    setAnchorEl(null);

    // 選択されたビデオデバイスへの接続
    setVideoConstraints({
      ...videoConstraints,
      deviceId: selectedDeviceId || videoDevicesMap[0].deviceId,
    });
  };

  /**
   * 使用可能なビデオデバイス一覧を閉じる
   */
  const closeVideoDeviceList = () => {
    setAnchorEl(null);
  };

  /**
   * カメラの上下反転
   */
  const invertCameraVertical = async () => {
    const inverted = await !isInvertCamera;
    setIsInvertCamera(inverted);
    localStorage.setItem('VideoIsInvertCamera', inverted.toString());
  };

  /**
   * ビューア画面の表示
   */
  const openViewer = useCallback(async () => {
    if (webcamRef.current) {
      // 画像の取得
      const imageBase64 = webcamRef.current.getScreenshot();

      if (imageBase64) {
        if (isInvertCamera) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx) {
            const img = new Image();
            img.src = imageBase64;
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.transform(-1, 0, 0, -1, img.width, img.height);
              ctx.drawImage(img, 0, 0);
              props.openViewer(canvas.toDataURL(IMAGE_MIME, 1));
            };
          }
        } else {
          props.openViewer(imageBase64);
        }
      }
    }
  }, [isInvertCamera, props]);

  useEffect(() => {
    document.onkeydown = e => {
      if (e.code === 'KeyY') {
        // 「Y」キー入力時に、撮影ボタン押下時の処理を実行
        openViewer();
      }
    };
  }, [openViewer]);

  return (
    <>
      <div className={classes.switchButton}>
        <Button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={openVideoDeviceMenu}
        >
          <SwitchCameraIcon className={classes.switchButtonIcon} />
        </Button>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeVideoDeviceList}
        >
          {videoDevicesMap.map(device => (
            <MenuItem
              key={device.deviceId}
              selected={device.deviceId === selectedDevice}
              onClick={() => changeVideoDevice(device.deviceId)}
            >
              {device.label}
            </MenuItem>
          ))}
        </Menu>
      </div>

      <div className={classes.upsideDownButton}>
        <Button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={invertCameraVertical}
        >
          <LoopIcon className={classes.upsideDownButtonIcon} />
        </Button>
      </div>

      <div className={classes.closeCameraButton}>
        <CloseIcon
          className={classes.closeCameraButtonIcon}
          data-cy="close-camera-button"
          onClick={() => props.closeCamera()}
          onKeyDown={() => props.closeCamera()}
          tabIndex={0}
        />
      </div>

      <Webcam
        className={
          isInvertCamera
            ? `${classes.invertCamera} ${classes.cameraSettings}`
            : classes.cameraSettings
        }
        audio={false}
        ref={webcamRef}
        screenshotFormat={IMAGE_MIME}
        screenshotQuality={IMAGE_QUALITY}
        videoConstraints={videoConstraints}
        forceScreenshotSourceSize
      />

      <div className={classes.shutterButton}>
        <PhotoCameraIcon
          data-cy="shutter-button"
          className={classes.shutterButtonIcon}
          onClick={openViewer}
          onKeyDown={openViewer}
          tabIndex={0}
        />
      </div>
    </>
  );
};

export default Camera;
