import React, { FC, useState, useCallback, useMemo } from 'react';
import { Grid, Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { colors } from 'components/common/Theme';
import { OrderAdminDto, UpdateOrdersDto } from 'api/orders/orders.dto';
import moment from 'moment';
import ActionButton from 'components/Admin/common/ActionButton';
import {
  failLoading,
  createErrorObject,
  startLoading,
  endLoading,
} from 'modules/commonModule';
import { useDispatch } from 'react-redux';
import CustomDialog, {
  useDialog,
  useMsgDialog,
} from 'components/common/CustomDialog';
import { sendResults } from 'app/constants';
import { sendUrlNotificationMail, updateOrders } from 'api/orders/orders.api';
import { Storage, Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import OrderEditDialog from './OrderEditDialog';
import CustomPagination from '../common/CustomPagination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conditionWrap: {
      '& >div:first-child': {
        marginRight: theme.spacing(5),
      },
    },
    toggleGroup: {
      width: '100%',
    },
    gridItem: {
      maxWidth: '450px',
    },
    gridFrom: {
      paddingRight: theme.spacing(1),
    },
    gridTo: {
      paddingLeft: theme.spacing(1),
    },
    paging: {
      '& div': {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
    activePage: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    buttonArea: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      width: '460px',
    },
    csvLink: {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    editIconButton: {
      '&:active': {
        border: 0,
        outline: 0,
      },
    },
  }),
);

/* react-data-table-componentコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const customStyles = {
  table: {
    style: {
      marginTop: '42px',
      '& .eGEGHg': {
        maxHeight: 'none',
        overflowY: 'auto',
      },
    },
  },
  header: {
    style: {
      display: 'none',
    },
  },
  headRow: {
    style: {
      backgroundColor: colors.admin.sideBar.main,
      minHeight: '37px',
    },
  },
  rows: {
    style: {
      minHeight: '37px',
    },
    stripedStyle: {
      '&:nth-of-type(even)': {
        backgroundColor: colors.admin.table.body.even,
        '& div[role="cell"]': {
          backgroundColor: colors.admin.table.body.even,
        },
      },
      '&:nth-of-type(odd)': {
        backgroundColor: colors.admin.table.body.odd,
        '& div[role="cell"]': {
          backgroundColor: colors.admin.table.body.odd,
        },
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: '#FFF',
    },
    activeSortStyle: {
      color: '#FFF',
      '&:focus': {
        outline: 'none',
      },
      '&:hover:not(:focus)': {
        color: '#FFF',
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#FFF',
      },
      '&:hover': {
        color: '#FFF',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

interface PropsType {
  orderInfoList: OrderAdminDto[];
  allCount: number;
  setNextPage: (pageNo: number) => void;
  setNewPerPage: (newPerPage: number) => void;
  perPage: number;
  orderSearch: () => void;
}
const OrderInfoTable: FC<PropsType> = ({
  orderInfoList,
  allCount,
  setNextPage,
  setNewPerPage,
  perPage,
  orderSearch,
}) => {
  const dispatch = useDispatch();
  const { openDialog, dialogProps } = useDialog();
  const { openMsgDialog, msgDialogProps } = useMsgDialog();
  const classes = useStyles();
  const [isOpenOrderDialog, setIsOpenOrderDialog] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>();
  const history = useHistory();

  const handlePageChange = useCallback(
    async (pageNo: number) => {
      setNextPage(pageNo);
    },
    [setNextPage],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number) => {
      setNewPerPage(newPerPage);
    },
    [setNewPerPage],
  );

  /**
   * 注文情報変更ダイアログを表示 */
  const openOrderDialog = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsOpenOrderDialog(true);
      setSelectedOrderId(
        event.currentTarget.getAttribute('data-orderid') ?? undefined,
      );
      // ダイアログをブラウザバックで閉じる対応
      history.push('/admin/ordersearch');
    },
    [history],
  );

  /**
   * 注文情報変更ダイアログを閉じる */
  const closeDialog = useCallback(
    (reloadFlg: boolean) => {
      // ダイアログで注文情報の更新をされた場合はデータテーブルをリロード
      if (reloadFlg) {
        orderSearch();
      }
      setIsOpenOrderDialog(false);
    },
    [orderSearch],
  );

  /**
   * メール送信実行
   */
  const sendExecute = useCallback(async () => {
    try {
      dispatch(startLoading());
      const orderIdList = orderInfoList.map(item => item.orderId);
      const sendMailError = await sendUrlNotificationMail({
        orderIdList,
      });

      // URL通知メールの送信結果にエラーの明細が含まれている場合
      if (sendMailError?.length) {
        let isAllError = true;
        for (let i = 0; i < orderIdList.length; i += 1) {
          const errorList = sendMailError.filter(
            item => item.orderId === orderIdList[i],
          );

          if (!errorList.length) {
            isAllError = false;
            break;
          }
        }

        if (isAllError) {
          // 全明細の送信処理に失敗した場合
          openMsgDialog({
            title: 'URL通知メール送信結果',
            content: (
              <>
                URL通知メールの一括送信に失敗しました。
                <br />
                注文情報をご確認ください。
              </>
            ),
          });
        } else {
          // 一部の明細で送信処理に失敗した場合
          openMsgDialog({
            title: 'URL通知メール送信結果',
            content: (
              <>
                URL通知メールの一括送信が完了しました。
                <br />
                一部送信に失敗した注文情報がありましたのでご確認ください。
              </>
            ),
          });
        }
      } else {
        // 全明細の送信処理が成功した場合
        openMsgDialog({
          title: 'URL通知メール送信結果',
          content: 'URL通知メールの一括送信が完了しました。',
        });
      }

      // データテーブル最新化
      orderSearch();
    } catch (e) {
      dispatch(
        failLoading(
          createErrorObject('URL通知メールの送信処理に失敗しました。', e),
        ),
      );
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, openMsgDialog, orderInfoList, orderSearch]);

  /**
   * 確認ダイアログを表示（URLメール再送）
   */
  const openSendConfirmDialog = useCallback(() => {
    openDialog({
      title: 'URL通知メールの一括送信',
      content: (
        <>
          現在一覧に表示されている注文情報に対し、URL通知メールを一括送信します。
          <br />
          本当に送信してもよろしいでしょうか？
        </>
      ),
      buttonTitle: '送信',
      action: sendExecute,
    });
  }, [openDialog, sendExecute]);

  /**
   * お客様用サイトへのアクセス一括設定の実行
   */
  const accessExecute = useCallback(
    async (isNoAccessFlg: boolean, text: string) => {
      try {
        dispatch(startLoading());

        const user = await Auth.currentAuthenticatedUser();
        const updateOrderDto: UpdateOrdersDto[] = orderInfoList.map(item => {
          return {
            orderId: item.orderId,
            isNoAccess: isNoAccessFlg,
            updatedBy: user.getUsername(),
          };
        });

        updateOrders(updateOrderDto);

        openMsgDialog({
          title: 'サイトアクセス一括設定結果',
          content: `サイトアクセス一括${text}設定が完了しました。`,
        });

        // データテーブル最新化
        orderSearch();
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('サイトアクセス一括設定処理に失敗しました。', e),
          ),
        );
      } finally {
        dispatch(endLoading());
      }
    },
    [dispatch, openMsgDialog, orderInfoList, orderSearch],
  );

  /**
   * 確認ダイアログを表示（お客様用サイトへのアクセス一括設定）
   */
  const openIsNoAccessConfirmDialog = useCallback(
    (isNoAccessFlg: boolean) => {
      const text = isNoAccessFlg ? '禁止' : '許可';

      openDialog({
        title: `お客様用サイトアクセス一括${text}設定`,
        content: (
          <>
            {`お客様用サイトへのアクセスを一括${text}します。`}
            <br />
            本当に実行してもよろしいでしょうか？
          </>
        ),
        buttonTitle: '設定',
        action: () => accessExecute(isNoAccessFlg, text),
      });
    },
    [accessExecute, openDialog],
  );

  // 写真をブラウザ別タブで表示
  const dispImage = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const photo = event.currentTarget.getAttribute('data-photo');
      if (photo) {
        const spilitedImageProp = photo.split(',');
        const imgUrl = (
          await Storage.get(spilitedImageProp[1], {
            level: 'protected',
            identityId: spilitedImageProp[0],
          })
        ).toString();
        window.open(imgUrl, '_blank');
      }
    },
    [],
  );

  // 写真URLリンクコンポーネント
  const formPhotoLink = useCallback(
    (row: OrderAdminDto) => {
      const { formPhoto } = row;
      if (formPhoto) {
        return (
          <button
            type="button"
            onClick={dispImage}
            data-photo={formPhoto}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              outline: 'none',
              padding: 0,
              appearance: 'none',
            }}
          >
            <span style={{ color: '#000' }}>登録済み</span>
          </button>
        );
      }

      return (
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            padding: 0,
            appearance: 'none',
          }}
          disabled
        >
          <span style={{ color: '#ccc' }}>未登録</span>
        </button>
      );
    },
    [dispImage],
  );

  // 編集ボタンコンポーネント
  const editButton = useCallback(
    (row: OrderAdminDto) => (
      <button
        type="button"
        style={{
          flex: '0 0 auto',
          color: 'rgba(0, 0, 0, 0.54)',
          overflow: 'visible',
          textAlign: 'center',
          transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          width: '100%',
          display: 'block',
          padding: 0,
          cursor: 'pointer',
          margin: 0,
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none',
          appearance: 'none',
        }}
        onClick={openOrderDialog}
        data-orderid={row.orderId}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          style={{
            fill: 'currentColor',
            width: '1em',
            height: '1em',
            display: 'inline-block',
            fontSize: '1.263157894736842rem',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            flexShrink: 0,
            userSelect: 'none',
          }}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
        </svg>
      </button>
    ),
    [openOrderDialog],
  );

  const columns = useMemo(() => {
    return [
      {
        name: '',
        selector: 'editOrderInfoButton',
        width: '80px',
        style: { position: 'sticky' },
        cell: editButton,
      },
      {
        name: '受付番号',
        selector: 'orderNo',
        sortable: true,
        width: '90px',
        format: (row: OrderAdminDto) => `000000${row.orderNo}`.slice(-6),
      },
      {
        name: 'ECサイト注文番号',
        selector: 'ecOrderNo',
        sortable: true,
        width: '160px',
      },
      {
        name: '注文日',
        selector: 'orderedAt',
        sortable: true,
        width: '100px',
        format: (row: OrderAdminDto) =>
          `${row.orderedAt ? moment(row.orderedAt).format('YYYY-MM-DD') : ''}`,
      },
      {
        name: 'お客様氏名',
        selector: 'customerName',
        sortable: true,
        width: '120px',
      },
      {
        name: 'お客様メールアドレス',
        selector: 'email',
        wrap: false,
        width: '200px',
      },
      {
        name: '申込書写真',
        selector: 'formPhoto',
        center: true,
        width: '80px',
        isExcludeCsv: true,
        cell: formPhotoLink,
      },
      {
        name: '衣類点数',
        selector: 'qty',
        sortable: true,
        right: true,
        width: '90px',
        format: (row: OrderAdminDto) =>
          `${row.qty != null ? `${row.qty}点` : ''}`,
      },
      {
        name: '検品担当者',
        selector: 'inspectedByName',
        sortable: true,
        width: '120px',
      },
      {
        name: '最終検品日時',
        selector: 'inspectedAt',
        sortable: true,
        width: '130px',
        format: (row: OrderAdminDto) =>
          `${
            row.inspectedAt
              ? moment(row.inspectedAt).format('YYYY-MM-DD HH:mm')
              : ''
          }`,
      },
      {
        name: '毛玉取り作業数',
        selector: 'lintRemovalCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.lintRemovalCount}件`,
      },
      {
        name: '毛玉取り実施数',
        selector: 'lintRemovaledCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.lintRemovaledCount}件`,
      },
      {
        name: '修理作業数',
        selector: 'repairCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.repairCount}件`,
      },
      {
        name: '修理実施数',
        selector: 'repairedCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.repairedCount}件`,
      },
      {
        name: '染み抜き作業数',
        selector: 'stainRemovalCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.stainRemovalCount}件`,
      },
      {
        name: '染み抜き実施数',
        selector: 'stainRemovaledCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.stainRemovaledCount}件`,
      },
      {
        name: '検査総数',
        selector: 'confirmationCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.confirmationCount}件`,
      },
      {
        name: '検査完了数',
        selector: 'confirmedCount',
        sortable: true,
        right: true,
        width: '120px',
        format: (row: OrderAdminDto) => `${row.confirmedCount}件`,
      },
      {
        name: '検査担当者',
        selector: 'confirmedByName',
        sortable: true,
        width: '120px',
      },
      {
        name: '検査完了日時',
        selector: 'confirmedAt',
        sortable: true,
        width: '130px',
        format: (row: OrderAdminDto) =>
          `${
            row.confirmedAt
              ? moment(row.confirmedAt).format('YYYY-MM-DD HH:mm')
              : ''
          }`,
      },
      {
        name: 'サイトアクセス許可',
        selector: 'isNoAccess',
        sortable: true,
        center: true,
        width: '150px',
        format: (row: OrderAdminDto) => {
          return row.isNoAccess ? '禁止' : '許可';
        },
      },
      {
        name: 'URLメール送信日時',
        selector: 'sentAt',
        sortable: true,
        width: '150px',
        format: (row: OrderAdminDto) =>
          `${row.sentAt ? moment(row.sentAt).format('YYYY-MM-DD HH:mm') : ''}`,
      },
      {
        name: 'URLメール送信結果',
        selector: 'sendResult',
        sortable: true,
        center: true,
        width: '150px',
        format: (row: OrderAdminDto) => sendResults[row.sendResult],
      },
    ];
  }, [editButton, formPhotoLink]);

  /**
   * CSVヘッダー
   */
  const csvHeader = useMemo(() => {
    return columns
      .filter(column => column.name && !column.isExcludeCsv)
      .map(column => {
        return {
          label: column.name,
          key: column.selector,
        };
      });
  }, [columns]);

  /**
   * CSVデータ
   */
  const csvData = useMemo(() => {
    return orderInfoList.map(orderInfo => {
      return {
        orderNo: orderInfo.orderNo,
        ecOrderNo: orderInfo.ecOrderNo,
        orderedAt:
          orderInfo.orderedAt &&
          moment(orderInfo.orderedAt).format('YYYY-MM-DD'),
        customerName: orderInfo.customerName,
        email: orderInfo.email,
        qty: orderInfo.qty,
        inspectedByName: orderInfo.inspectedByName,
        inspectedAt:
          orderInfo.inspectedAt &&
          moment(orderInfo.inspectedAt).format('YYYY-MM-DD HH:mm:ss'),
        lintRemovalCount: orderInfo.lintRemovalCount,
        lintRemovaledCount: orderInfo.lintRemovaledCount,
        repairCount: orderInfo.repairCount,
        repairedCount: orderInfo.repairedCount,
        stainRemovalCount: orderInfo.stainRemovalCount,
        stainRemovaledCount: orderInfo.stainRemovaledCount,
        confirmationCount: orderInfo.confirmationCount,
        confirmedCount: orderInfo.confirmedCount,
        confirmedByName: orderInfo.confirmedByName,
        confirmedAt:
          orderInfo.confirmedAt &&
          moment(orderInfo.confirmedAt).format('YYYY-MM-DD HH:mm:ss'),
        isNoAccess: orderInfo.isNoAccess ? '禁止' : '許可',
        sentAt:
          orderInfo.sentAt &&
          moment(orderInfo.sentAt).format('YYYY-MM-DD HH:mm:ss'),
        sendResult: sendResults[orderInfo.sendResult],
      };
    });
  }, [orderInfoList]);

  return (
    <>
      <CustomDialog {...msgDialogProps} />
      <CustomDialog {...dialogProps} />
      <Box position="relative">
        <Grid container justify="flex-end" className={classes.buttonArea}>
          <Grid item>
            <Box mb={1} mr={2}>
              <ActionButton
                color="primary"
                data-cy="sendMail-button"
                fullWidth
                size="small"
                onClick={openSendConfirmDialog}
              >
                URLメール再送
              </ActionButton>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={1} mr={2}>
              <ActionButton
                color="primary"
                data-cy="accessAllow-button"
                fullWidth
                size="small"
                onClick={() => openIsNoAccessConfirmDialog(false)}
              >
                アクセス許可
              </ActionButton>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={1} mr={2}>
              <ActionButton
                color="primary"
                data-cy="accessDeny-button"
                fullWidth
                size="small"
                onClick={() => openIsNoAccessConfirmDialog(true)}
              >
                アクセス禁止
              </ActionButton>
            </Box>
          </Grid>

          <Grid item>
            <Box mb={1}>
              <ActionButton
                color="primary"
                data-cy="csvOutput-button"
                fullWidth
                size="small"
              >
                <CSVLink
                  filename="注文情報.csv"
                  headers={csvHeader}
                  data={csvData}
                  className={classes.csvLink}
                >
                  CSV出力
                </CSVLink>
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container data-cy="orderList-dataTable">
        <DataTable
          columns={columns}
          data={orderInfoList}
          fixedHeader
          fixedHeaderScrollHeight="calc(100vh - 540px)"
          striped
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          customStyles={customStyles}
          pagination
          paginationTotalRows={allCount}
          paginationPerPage={perPage}
          paginationComponent={CustomPagination}
          paginationServer
        />
      </Grid>

      <OrderEditDialog
        open={isOpenOrderDialog}
        handleClose={closeDialog}
        orderId={selectedOrderId}
      />
    </>
  );
};
export default OrderInfoTable;
