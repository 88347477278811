import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

const ButtonsArea: FC<{}> = ({ children }) => {
  return (
    <Grid item xs={2} container direction="column" justify="space-between">
      {children}
    </Grid>
  );
};

export default ButtonsArea;
