/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ErrorObject = {
  message: string;
  summary: string;
  detail: string;
  isGoBack?: boolean;
};

type State = {
  title: string;
  isLoading: boolean;
  error?: ErrorObject;
  errorCount: number;
  snackbar: {
    isOpen: boolean;
    text: string;
  };
};

const initialState: State = {
  title: 'おせっかい作業用アプリ',
  isLoading: false,
  errorCount: 0,
  snackbar: {
    isOpen: false,
    text: '',
  },
};

const commonModule = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTitle(state, { payload }: PayloadAction<string>) {
      state.title = payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
      state.errorCount = 0;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    failLoading(state, action: PayloadAction<ErrorObject>) {
      state.isLoading = false;
      state.errorCount += 1;
      state.error = action.payload;
    },
    clearError(state) {
      state.error = undefined;
    },
    openSnackbar(
      state,
      action: PayloadAction<{
        text: string;
      }>,
    ) {
      state.snackbar.text = action.payload.text;
      state.snackbar.isOpen = true;
    },
    closeSnackbar(state) {
      state.snackbar.text = '';
      state.snackbar.isOpen = false;
    },
  },
});

export const {
  setTitle,
  startLoading,
  endLoading,
  failLoading,
  clearError,
  openSnackbar,
  closeSnackbar,
} = commonModule.actions;

export const createErrorObject = (
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
  isGoBack?: boolean,
) => {
  const res = e.response;
  if (res?.config?.headers['X-Amz-Security-Token']) {
    res.config.headers['X-Amz-Security-Token'] = '***';
  }
  if (res?.config?.headers?.Authorization) {
    res.config.headers.Authorization = '***';
  }

  return {
    message,
    summary: String(e),
    detail: JSON.stringify(res || e),
    isGoBack,
  };
};

export default commonModule;
