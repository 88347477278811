import React, { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';

import Photo from 'components/common/Photo';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setTitle,
  openSnackbar,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import ClothingImage from 'components/common/ClothingImage';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { WorkContentDto } from 'api/workContents/workContents.dto';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  WorkType,
  WorkDto,
  CreateWorkDto,
  UpdateWorkDto,
  WorkSide,
  WorkingResult,
} from 'api/works/works.dto';
import { workingResults, workTypes } from 'app/constants';
import { ClothingDto } from 'api/clothings/clothings.dto';
import { getClothing } from 'api/clothings/clothings.api';
import {
  getWork,
  createWork,
  updateWork,
  deleteWork,
} from 'api/works/works.api';
import { RootState } from 'app/rootReducer';
import { createWorkHistory } from 'api/workHistories/workHistories.api';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import {
  isBase64Image,
  uploadS3Base64Image,
  removeS3Image,
} from 'utils/awsUtils';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { updateOrderEndedAt } from 'api/orders/orders.api';
import OrderNoField from 'components/common/OrderNoField';
import { useForm } from 'react-hook-form';
import WorkContentDialog from './WorkContentDialog';

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      marginBottom: '40px',
    },
    container: {
      paddingRight: '24px !important',
    },
    rightContents: {
      paddingLeft: '0 !important',
    },
  }),
);

type FormData = {
  workContentName: string;
};

type LocationState = {
  workProcess: WorkProcess;
  orderId?: string;
  clothingId?: string;
  workId?: string;
  workType: WorkType;
};

const RegistWorkPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, errors, setError, clearError } = useForm<FormData>();
  const location = useLocation<LocationState>();
  const { workProcess, orderId, clothingId, workId, workType } = location.state;
  const [clothingDto, setClothingDto] = useState<ClothingDto>();
  const [workDto, setWorkDto] = useState<WorkDto>();
  const [workSide, setWorkSide] = useState<WorkSide>(WorkSide.Front);
  const [workLocation, setWorkLocation] = useState<string | null>();
  const [beforePhoto, setBeforePhoto] = useState('');
  const [afterPhoto, setAfterPhoto] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [
    isOpenSelectWorkContentDialog,
    setIsOpenSelectWorkContentDialog,
  ] = useState(false);
  const { workContentList } = useSelector((state: RootState) => state.masters);
  const [workContent, setWorkContent] = useState<WorkContentDto>();
  const { openDialog, dialogProps } = useDialog();

  const pageTitle = `作業内容登録 - ${workTypes[workType].title}`;
  dispatch(setTitle(pageTitle));

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());
        let clothing!: ClothingDto;
        let work: WorkDto | undefined;

        if (workId) {
          // 作業Id指定 (作業を選択)
          work = await getWork(workId);
          clothing = work.clothing;
        } else if (clothingId) {
          // 衣類Id指定 (作業登録へボタン)
          clothing = await getClothing(clothingId);
        }

        setClothingDto(clothing);
        setWorkDto(work);
        setBeforePhoto(work?.beforePhoto || '');
        setAfterPhoto(work?.afterPhoto || '');
        setWorkSide(work?.workSide || WorkSide.Front);
        setWorkLocation(work?.workLocation);

        setIsLoading(false);
        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('作業情報を取得できませんでした。', e, true),
          ),
        );
      }
    })();
  }, [clothingId, dispatch, workId]);

  useEffect(() => {
    let workContentDto: WorkContentDto | undefined;

    if (workDto?.workContent) {
      workContentDto = workDto.workContent;
    } else if (
      workType === WorkType.LintRemoval ||
      workType === WorkType.StainRemoval
    ) {
      workContentDto = workContentList.find(wc => wc.workType === workType);
    }

    setWorkContent(workContentDto);
  }, [workContentList, workDto, workType]);

  useEffect(() => {
    if (workContent?.workContentId) {
      clearError('workContentName');
    }
  }, [clearError, workContent]);

  /**
   * 入力項目が変更されているか
   */
  const wasChanged = useCallback(() => {
    return (
      workSide !== (workDto?.workSide || WorkSide.Front) ||
      workLocation !== workDto?.workLocation ||
      (workType !== WorkType.LintRemoval &&
        workType !== WorkType.StainRemoval &&
        workContent?.workContentId !== workDto?.workContent?.workContentId) ||
      beforePhoto !== (workDto?.beforePhoto || '')
    );
  }, [beforePhoto, workContent, workDto, workLocation, workSide, workType]);

  /**
   * 作業前写真のアップロード
   */
  const uploadBeforePhoto = useCallback(
    async (uploadWorkId: string) => {
      let uploadBeforePhotoDto: UpdateWorkDto | undefined;
      const fileName = `${clothingDto?.orderId}/${clothingDto?.clothingId}/${uploadWorkId}-before`;

      if (isBase64Image(beforePhoto)) {
        const beforePhotoKey = await uploadS3Base64Image(beforePhoto, fileName);
        if (beforePhotoKey) {
          uploadBeforePhotoDto = { beforePhoto: beforePhotoKey };
        }
      } else if (
        beforePhoto === '' &&
        beforePhoto !== (workDto?.beforePhoto || '')
      ) {
        await removeS3Image(fileName);
        uploadBeforePhotoDto = { beforePhoto: null };
      }

      return uploadBeforePhotoDto;
    },
    [beforePhoto, clothingDto, workDto],
  );

  /**
   * 作業後写真のアップロード
   */
  const uploadAfterPhoto = useCallback(
    async (uploadWorkId: string) => {
      let uploadAfterPhotoDto: UpdateWorkDto | undefined;
      const fileName = `${clothingDto?.orderId}/${clothingDto?.clothingId}/${uploadWorkId}-after`;

      if (isBase64Image(afterPhoto)) {
        const afterPhotoKey = await uploadS3Base64Image(afterPhoto, fileName);
        if (afterPhotoKey) {
          uploadAfterPhotoDto = { afterPhoto: afterPhotoKey };
        }
      } else if (
        afterPhoto === '' &&
        afterPhoto !== (workDto?.afterPhoto || '')
      ) {
        await removeS3Image(fileName);
        uploadAfterPhotoDto = { afterPhoto: null };
      }

      return uploadAfterPhotoDto;
    },
    [afterPhoto, clothingDto, workDto],
  );

  /**
   * 作業履歴情報登録
   * @param work 作業情報
   */
  const registerWorkHistory = async (work: WorkDto) => {
    await createWorkHistory({
      workId: work.workId,
      workProcess: WorkProcess.Inspection,
      workType: work.workType,
      workContentId: work.workContent?.workContentId,
      workSide: work.workSide,
      workLocation: work.workLocation,
      beforePhoto: work.beforePhoto,
      afterPhoto: work.afterPhoto,
      workingResult: work.workingResult,
      workedAt: work.workedAt,
      confirmationResult: work.confirmationResult,
      confirmedAt: work.confirmedAt,
    });
  };

  /**
   * 作業情報登録
   */
  const registerWork = useCallback(async () => {
    let work: WorkDto | undefined;
    try {
      if (!workContent?.workContentId) {
        setError([
          {
            type: 'required',
            name: 'workContentName',
            message: '作業内容を選んでください。',
          },
        ]);

        return undefined;
      }
      dispatch(startLoading());
      if (!workId) {
        // workIdがない場合、新規登録
        const createWorkDto: CreateWorkDto = {
          clothingId: clothingId || '',
          workType,
          workSide,
          workLocation,
          workContentId: workContent?.workContentId,
        };
        work = await createWork(createWorkDto);
        if (work.workId) {
          // 写真が登録されている場合はアップロードして更新する
          const uploadBeforePhotoDto = await uploadBeforePhoto(work.workId);
          if (uploadBeforePhotoDto) {
            work = await updateWork(work.workId, uploadBeforePhotoDto);
          }
          await registerWorkHistory(work);
          await updateOrderEndedAt(clothingDto?.orderId);
          dispatch(openSnackbar({ text: '作業内容を登録しました。' }));
        }
      } else if (wasChanged()) {
        // workIdがある場合で項目が変更されている場合、更新
        const uploadBeforePhotoDto = await uploadBeforePhoto(workId);
        const updateWorkDto: UpdateWorkDto = {
          workType,
          workSide,
          workLocation,
          workContentId: workContent?.workContentId,
          ...uploadBeforePhotoDto,
        };
        work = await updateWork(workId, updateWorkDto);
        await registerWorkHistory(work);
        await updateOrderEndedAt(clothingDto?.orderId);
        dispatch(openSnackbar({ text: '作業内容を更新しました。' }));
      } else {
        // 変更なし
        work = workDto;
      }
      dispatch(endLoading());

      return work;
    } catch (e) {
      dispatch(
        failLoading(createErrorObject('作業情報を登録できませんでした。', e)),
      );
    }

    return undefined;
  }, [
    clothingDto,
    clothingId,
    dispatch,
    setError,
    uploadBeforePhoto,
    wasChanged,
    workContent,
    workDto,
    workId,
    workLocation,
    workSide,
    workType,
  ]);

  /**
   * 結果登録しない
   */
  const workList = useCallback(async () => {
    const work = await registerWork();
    if (work) {
      if (workProcess === WorkProcess.Inspection) {
        history.push('/inspection/worklist', {
          workProcess,
          orderId,
          clothingId: work?.clothingId,
          workType,
        });
      } else {
        history.push(`/${workProcess.toLowerCase()}/registclothing`, {
          workProcess,
          tagNo: work?.clothing.tagNo,
        });
      }
    }
  }, [history, orderId, registerWork, workProcess, workType]);

  /**
   * 結果登録する
   */
  const registerResult = useCallback(async () => {
    const work = await registerWork();
    if (work) {
      history.push(`/${workProcess.toLowerCase()}/registresult`, {
        workProcess,
        orderId,
        clothingId: work?.clothingId,
        workId: work?.workId,
        workType,
      });
    }
  }, [history, orderId, registerWork, workProcess, workType]);

  /**
   * 作業結果登録ボタン押下時の処理
   */
  const registResult = useCallback(
    async (workingResult: WorkingResult) => {
      // 作業内容登録
      const work = await registerWork();

      try {
        if (work) {
          const updateWorkDto: UpdateWorkDto = {
            workingResult,
            workedAt: 'NOW()',
          };

          // 写真が登録されている場合はアップロードして作業結果を登録する
          const uploadAfterPhotoDto = await uploadAfterPhoto(work.workId);
          const registResultWork = await updateWork(work.workId, {
            ...updateWorkDto,
            ...uploadAfterPhotoDto,
          });

          // 作業履歴情報登録（作業結果の登録）
          await createWorkHistory({
            workId: registResultWork.workId,
            workProcess: registResultWork.workType,
            workType: registResultWork.workType,
            workContentId: registResultWork.workContent?.workContentId,
            workSide: registResultWork.workSide,
            workLocation: registResultWork.workLocation,
            beforePhoto: registResultWork.beforePhoto,
            afterPhoto: registResultWork.afterPhoto,
            workingResult: registResultWork.workingResult,
            workedAt: registResultWork.workedAt,
            confirmationResult: registResultWork.confirmationResult,
            confirmedAt: registResultWork.confirmedAt,
          });

          // 衣類情報登録へ遷移
          history.push(`/${workProcess.toLowerCase()}/registclothing`, {
            workProcess,
            tagNo: registResultWork?.clothing.tagNo,
          });

          dispatch(endLoading());
        }
      } catch (e) {
        dispatch(
          failLoading(createErrorObject('作業結果を登録できませんでした。', e)),
        );
      }
    },
    [dispatch, history, registerWork, uploadAfterPhoto, workProcess],
  );

  /**
   * 戻る
   */
  const backRegistClothing = useCallback(() => {
    history.push(`/${workProcess.toLowerCase()}/registclothing`, {
      workProcess,
      orderId,
      clothingId: clothingDto?.clothingId,
      tagNo: clothingDto?.tagNo,
    });
  }, [clothingDto, history, orderId, workProcess]);

  /**
   * 削除実行
   */
  const executeDelete = useCallback(async () => {
    if (workId) {
      try {
        dispatch(startLoading());
        await updateOrderEndedAt(clothingDto?.orderId);
        await deleteWork(workId);
        dispatch(
          openSnackbar({
            text: '作業内容を削除しました。',
          }),
        );
        dispatch(endLoading());
        backRegistClothing();
      } catch (e) {
        dispatch(
          failLoading(createErrorObject('作業情報を削除できませんでした。', e)),
        );
      }
    }
  }, [backRegistClothing, clothingDto, dispatch, workId]);

  /**
   * 削除確認ダイアログを表示
   */
  const openDeleteConfirmDialog = useCallback(() => {
    openDialog({
      title: '作業内容を削除します。',
      content: (
        <>
          削除した作業内容を元に戻すことはできません。
          <br />
          削除してもよろしいですか？
        </>
      ),
      buttonTitle: '削除',
      action: executeDelete,
    });
  }, [executeDelete, openDialog]);

  /**
   * 戻るボタン押下時の確認ダイアログを表示
   */
  const openBackConfirmDialog = useCallback(() => {
    if (wasChanged()) {
      openDialog({
        title: '衣類情報登録へ戻ります。',
        content: '入力中の内容を破棄し、衣類情報登録へ戻りますか？',
        buttonTitle: '戻る',
        action: backRegistClothing,
      });
    } else {
      backRegistClothing();
    }
  }, [backRegistClothing, openDialog, wasChanged]);

  /**
   * 作業内容選択ダイアログを表示
   */
  const openSelectWorkContentDialog = () => {
    setIsOpenSelectWorkContentDialog(true);
  };

  /**
   * 作業内容選択ダイアログを閉じる
   */
  const closeSelectWorkContentDialog = () => {
    setIsOpenSelectWorkContentDialog(false);
  };

  /**
   * 作業内容を選択
   * @param workContent 作業内容
   */
  const selectWorkContent = (workContentDto: WorkContentDto) => {
    setWorkContent(workContentDto);
    setIsOpenSelectWorkContentDialog(false);
  };

  return (
    <>
      <CustomDialog {...dialogProps} />
      <WorkArea>
        <ContentsArea>
          <Grid
            container
            spacing={5}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid container item xs={6} spacing={2}>
              <Grid container item xs={5} spacing={2}>
                <Grid item xs={12}>
                  <OrderNoField orderNo={clothingDto?.orderNo} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="tag_no"
                    label="タグ番号"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={clothingDto?.tagNo ? clothingDto?.tagNo : ' '}
                    inputProps={{
                      'data-cy': 'tag-no-input',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12}>
                  {workType === WorkType.LintRemoval ||
                  workType === WorkType.StainRemoval ? (
                    <TextField
                      label="作業内容"
                      variant="outlined"
                      fullWidth
                      value={workContent?.workContentName || ''}
                      disabled
                      data-cy="work-content-area"
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        'data-cy': 'work-content-input',
                      }}
                    />
                  ) : (
                    <TextField
                      name="workContentName"
                      label="作業内容"
                      variant="outlined"
                      fullWidth
                      autoFocus
                      required
                      value={workContent?.workContentName || ''}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility">
                              <ArrowDropDownIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register()}
                      helperText={
                        errors.workContentName
                          ? errors.workContentName.message
                          : ''
                      }
                      error={!!errors.workContentName}
                      onClick={openSelectWorkContentDialog}
                      data-cy="work-content-area"
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        'data-cy': 'work-content-input',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {isLoading ? (
                  <></>
                ) : (
                  <ClothingImage
                    clothingType={clothingDto?.clothingType}
                    workType={workType}
                    workSide={workSide}
                    workLocation={workDto?.workLocation}
                    setWorkSide={ws => setWorkSide(ws)}
                    setWorkLocation={wl => setWorkLocation(wl)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              container
              spacing={2}
              className={classes.rightContents}
              justify="flex-end"
            >
              <Grid
                item
                xs={12}
                container
                spacing={2}
                wrap="nowrap"
                justify="flex-end"
              >
                <Grid item xs={3}>
                  <Photo
                    image={clothingDto?.order?.formPhoto}
                    caption="申込書"
                    readonly
                    level="protected"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    image={clothingDto?.instructionPhoto1}
                    caption="指示書"
                    readonly
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    image={clothingDto?.instructionPhoto2}
                    caption="指示書"
                    readonly
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    caption="作業前"
                    image={beforePhoto}
                    setImage={setBeforePhoto}
                  />
                </Grid>
                {// ログインユーザーが修理担当者
                /// もしくは、染み/汚れ担当者の場合は作業後写真欄を表示
                (workProcess === WorkProcess.Repair ||
                  workProcess === WorkProcess.StainRemoval) && (
                  <Grid item xs={3}>
                    <Photo
                      caption="作業後"
                      image={afterPhoto}
                      setImage={setAfterPhoto}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            {// ログインユーザーが検品担当者
            /// または、検査担当者の場合はボタンを表示
            (workProcess === WorkProcess.Inspection ||
              workProcess === WorkProcess.Confirmation) && (
              <ActionButton
                onClick={workList}
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                data-cy="not-regist-result-button"
              >
                結果登録しない
              </ActionButton>
            )}
            {// ログインユーザーが検品担当者
            /// かつ、毛玉取り登録へボタンから遷移してきた場合はボタンを表示
            workProcess === WorkProcess.Inspection &&
              workType === WorkType.LintRemoval && (
                <ActionButton
                  onClick={registerResult}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="regist-result-button"
                >
                  結果登録する
                </ActionButton>
              )}

            {// ログインユーザーが修理担当者
            /// もしくは、染み/汚れ担当者の場合はボタンを表示
            (workProcess === WorkProcess.Repair ||
              workProcess === WorkProcess.StainRemoval) && (
              <>
                <ActionButton
                  onClick={() => {
                    registResult(WorkingResult.Perfect);
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-perfect-button"
                >
                  {workingResults[WorkingResult.Perfect].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    registResult(WorkingResult.Almost);
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-almost-button"
                >
                  {workingResults[WorkingResult.Almost].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    registResult(WorkingResult.Part);
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-part-button"
                >
                  {workingResults[WorkingResult.Part].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    registResult(WorkingResult.CouldNot);
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-couldnot-button"
                >
                  {workingResults[WorkingResult.CouldNot].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    registResult(WorkingResult.NotRequired);
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-notrequired-button"
                >
                  {workingResults[WorkingResult.NotRequired].label}
                </ActionButton>
              </>
            )}
          </Grid>

          <Grid item>
            {workId && (
              <ActionButton
                onClick={openDeleteConfirmDialog}
                color="delete"
                endIcon={<DeleteIcon />}
                data-cy="delete-work-button"
              >
                作業内容削除
              </ActionButton>
            )}
            <ActionButton
              onClick={openBackConfirmDialog}
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              data-cy="back-button"
            >
              戻る
            </ActionButton>
          </Grid>
        </ButtonsArea>
      </WorkArea>

      <WorkContentDialog
        open={isOpenSelectWorkContentDialog}
        handleClose={closeSelectWorkContentDialog}
        handleSelect={selectWorkContent}
      />
    </>
  );
};
export default RegistWorkPage;
