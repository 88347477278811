/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import * as ClothingTypesAPI from 'api/clothingTypes/clothingTypes.api';
import * as WorkContentsAPI from 'api/workContents/workContents.api';
import * as UsersAPI from 'api/users/users.api';
import { ClothingTypeCategoryDto } from 'api/clothingTypes/clothingTypes.dto';
import { WorkContentDto } from 'api/workContents/workContents.dto';
import { UserInfoDto } from 'api/users/users.dto';
import {
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from './commonModule';

type State = {
  clothingTypeList: ClothingTypeCategoryDto[];
  workContentList: WorkContentDto[];
  userInfoList: UserInfoDto[];
};

const initialState: State = {
  clothingTypeList: [],
  workContentList: [],
  userInfoList: [],
};

const mastersModule = createSlice({
  name: 'masters',
  initialState,
  reducers: {
    getClothingTypeListSuccess(
      state,
      { payload }: PayloadAction<ClothingTypeCategoryDto[]>,
    ) {
      state.clothingTypeList = payload;
    },
    getClothingTypeListFailure(state) {
      state.clothingTypeList = [];
    },
    getWorkContentListSuccess(
      state,
      { payload }: PayloadAction<WorkContentDto[]>,
    ) {
      state.workContentList = payload;
    },
    getWorkContentListFailure(state) {
      state.workContentList = [];
    },
    getUserInfoListSuccess(state, { payload }: PayloadAction<UserInfoDto[]>) {
      state.userInfoList = payload;
    },
    getUserInfoListFailure(state) {
      state.userInfoList = [];
    },
  },
});

export const {
  getClothingTypeListSuccess,
  getClothingTypeListFailure,
  getWorkContentListSuccess,
  getWorkContentListFailure,
  getUserInfoListSuccess,
  getUserInfoListFailure,
} = mastersModule.actions;

export default mastersModule;

export const getClothingTypeList = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { clothingTypeList } = getState().masters;
  if (clothingTypeList.length === 0) {
    try {
      dispatch(startLoading());
      const list = await ClothingTypesAPI.getClothingTypeList();
      dispatch(getClothingTypeListSuccess(list));
      dispatch(endLoading());
    } catch (e) {
      dispatch(getClothingTypeListFailure());
      dispatch(
        failLoading(createErrorObject('衣類種別を取得できませんでした。', e)),
      );
    }
  }
};

export const getWorkContentList = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { workContentList } = getState().masters;
  if (workContentList.length === 0) {
    try {
      dispatch(startLoading());
      const list = await WorkContentsAPI.getWorkContentList();
      dispatch(getWorkContentListSuccess(list));
      dispatch(endLoading());
    } catch (e) {
      dispatch(getWorkContentListFailure());
      dispatch(
        failLoading(createErrorObject('作業内容を取得できませんでした。', e)),
      );
    }
  }
};

export const getUserInfoList = (force?: boolean): AppThunk => async (
  dispatch,
  getState,
) => {
  const { userInfoList } = getState().masters;
  if (force || userInfoList.length === 0) {
    try {
      dispatch(startLoading());
      const list = await UsersAPI.getUsers();
      dispatch(getUserInfoListSuccess(list));
      dispatch(endLoading());
    } catch (e) {
      dispatch(getUserInfoListFailure());
      dispatch(
        failLoading(createErrorObject('作業者を取得できませんでした。', e)),
      );
    }
  }
};
