import React, { FC, useState, useCallback, useMemo } from 'react';
import { Grid, Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { colors } from 'components/common/Theme';
import moment from 'moment';
import { WorkAdminDto } from 'api/works/works.dto';
import ActionButton from 'components/Admin/common/ActionButton';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { CSVLink } from 'react-csv';
import { workTypes, workingResults, confirmationResults } from 'app/constants';
import { Storage } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import WorkEditDialog from './WorkEditDialog';
import CustomPagination from '../common/CustomPagination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conditionWrap: {
      '& >div:first-child': {
        marginRight: theme.spacing(5),
      },
    },
    toggleGroup: {
      width: '100%',
    },
    gridItem: {
      maxWidth: '450px',
    },
    gridFrom: {
      paddingRight: theme.spacing(1),
    },
    gridTo: {
      paddingLeft: theme.spacing(1),
    },
    paging: {
      '& div': {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
    activePage: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    buttonArea: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      width: '460px',
    },
    csvLink: {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    editIconButton: {
      flex: '0 0 auto',
      color: 'rgba(0, 0, 0, 0.54)',
      overflow: 'visible',
      textAlign: 'center',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      width: '100%',
      display: 'block',
      padding: 0,
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      margin: 0,
      '&:active': {
        border: 0,
        outline: 0,
      },
    },
    editIcon: {
      fill: 'currentColor',
      width: '1em',
      height: '1em',
      display: 'inline-block',
      fontSize: '1.263157894736842rem',
      transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      flexShrink: 0,
      userSelect: 'none',
    },
  }),
);

/* react-data-table-componentコンポーネント 既存スタイルの上書き
-------------------------------------------------------------- */
const customStyles = {
  table: {
    style: {
      marginTop: '42px',
      '& .eGEGHg': {
        maxHeight: 'none',
        overflowY: 'auto',
      },
    },
  },
  header: {
    style: {
      display: 'none',
    },
  },
  headRow: {
    style: {
      backgroundColor: colors.admin.sideBar.main,
      minHeight: '37px',
    },
  },
  rows: {
    style: {
      minHeight: '37px',
    },
    stripedStyle: {
      '&:nth-of-type(even)': {
        backgroundColor: colors.admin.table.body.even,
        '& div[role="cell"]': {
          backgroundColor: colors.admin.table.body.even,
        },
      },
      '&:nth-of-type(odd)': {
        backgroundColor: colors.admin.table.body.odd,
        '& div[role="cell"]': {
          backgroundColor: colors.admin.table.body.odd,
        },
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: '#FFF',
    },
    activeSortStyle: {
      color: '#FFF',
      '&:focus': {
        outline: 'none',
      },
      '&:hover:not(:focus)': {
        color: '#FFF',
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#FFF',
      },
      '&:hover': {
        color: '#FFF',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

interface PropsType {
  workInfoList: WorkAdminDto[];
  allCount: number;
  setNextPage: (pageNo: number, isWorkPage: boolean) => void;
  setNewPerPage: (newPerPage: number) => void;
  perPage: number;
  workSearch: () => void;
}
const WorkInfoTable: FC<PropsType> = ({
  workInfoList,
  allCount,
  setNextPage,
  setNewPerPage,
  perPage,
  workSearch,
}) => {
  const { dialogProps } = useDialog();
  const classes = useStyles();
  const history = useHistory();
  const [isOpenWorkDialog, setIsOpenWorkDialog] = useState(false);
  const [selectedWorkId, setSelectedWorkId] = useState<string>();

  const handlePageChange = useCallback(
    async (pageNo: number) => {
      setNextPage(pageNo, true);
    },
    [setNextPage],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number) => {
      setNewPerPage(newPerPage);
    },
    [setNewPerPage],
  );

  /**
   * 作業情報変更ダイアログを表示 */
  const openWorkDialog = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // ダイアログで注文情報の更新をされた場合はデータテーブルをリロード
      setIsOpenWorkDialog(true);
      setSelectedWorkId(
        event.currentTarget.getAttribute('data-workid') ?? undefined,
      );
      // ダイアログをブラウザバックで閉じる対応
      history.push('/admin/ordersearch');
    },
    [history],
  );

  /**
   * 作業情報変更ダイアログを閉じる */
  const closeWorkDialog = useCallback(
    (reloadFlg?: boolean) => {
      // ダイアログで注文情報の更新をされた場合はデータテーブルをリロード
      if (reloadFlg) {
        workSearch();
      }
      setIsOpenWorkDialog(false);
    },
    [workSearch],
  );

  // 編集ボタンコンポーネント
  const editButton = useCallback(
    (row: WorkAdminDto) => (
      <button
        type="button"
        style={{
          flex: '0 0 auto',
          color: 'rgba(0, 0, 0, 0.54)',
          overflow: 'visible',
          textAlign: 'center',
          transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          width: '100%',
          display: 'block',
          padding: 0,
          cursor: 'pointer',
          margin: 0,
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none',
          appearance: 'none',
        }}
        onClick={openWorkDialog}
        data-workid={row.workId}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          style={{
            fill: 'currentColor',
            width: '1em',
            height: '1em',
            display: 'inline-block',
            fontSize: '1.263157894736842rem',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            flexShrink: 0,
            userSelect: 'none',
          }}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
        </svg>
      </button>
    ),
    [openWorkDialog],
  );

  // 写真をブラウザ別タブで表示
  const dispImage = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const photo = event.currentTarget.getAttribute('data-photo');
      if (photo) {
        const imgUrl = (
          await Storage.get(photo, { level: 'public' })
        ).toString();
        window.open(imgUrl, '_blank');
      }
    },
    [],
  );

  // 作業前写真URLリンクコンポーネント
  const beforePhotoLink = useCallback(
    (row: WorkAdminDto) => {
      const { beforePhoto } = row;
      if (beforePhoto) {
        return (
          <button
            type="button"
            onClick={dispImage}
            data-photo={beforePhoto}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              outline: 'none',
              padding: 0,
              appearance: 'none',
            }}
          >
            <span style={{ color: '#000' }}>登録済み</span>
          </button>
        );
      }

      return (
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            outline: 'none',
            padding: 0,
            appearance: 'none',
          }}
          disabled
        >
          <span style={{ color: '#ccc' }}>未登録</span>
        </button>
      );
    },
    [dispImage],
  );

  // 作業後写真URLリンクコンポーネント
  const afterPhotoLink = useCallback(
    (row: WorkAdminDto) => {
      const { afterPhoto } = row;
      if (afterPhoto) {
        return (
          <button
            type="button"
            onClick={dispImage}
            data-photo={afterPhoto}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              outline: 'none',
              padding: 0,
              appearance: 'none',
            }}
          >
            <span style={{ color: '#000' }}>登録済み</span>
          </button>
        );
      }

      return (
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            padding: 0,
            appearance: 'none',
          }}
          disabled
        >
          <span style={{ color: '#ccc' }}>未登録</span>
        </button>
      );
    },
    [dispImage],
  );

  const columns = useMemo(() => {
    return [
      {
        name: '',
        selector: 'editWorkInfoButton',
        width: '80px',
        cell: editButton,
      },
      {
        name: '受付番号',
        selector: 'orderNo',
        sortable: true,
        width: '90px',
        format: (row: WorkAdminDto) => `000000${row.orderNo}`.slice(-6),
      },
      {
        name: 'ECサイト注文番号',
        selector: 'ecOrderNo',
        sortable: true,
        width: '160px',
      },
      {
        name: 'タグ番号',
        selector: 'tagNo',
        sortable: true,
        width: '90px',
      },
      {
        name: '衣類種別',
        selector: 'clothingTypeName',
        sortable: true,
        width: '150px',
      },
      {
        name: '作業種別',
        selector: 'workType',
        sortable: true,
        width: '120px',
        format: (row: WorkAdminDto) => workTypes[row.workType].title,
      },
      {
        name: '作業内容',
        selector: 'workContentName',
        sortable: true,
        width: '150px',
      },
      {
        name: '作業登録者',
        selector: 'inspectedByName',
        sortable: true,
        width: '120px',
      },
      {
        name: '作業登録日時',
        selector: 'inspectedAt',
        sortable: true,
        width: '130px',
        format: (row: WorkAdminDto) =>
          `${
            row.inspectedAt
              ? moment(row.inspectedAt).format('YYYY-MM-DD HH:mm')
              : ''
          }`,
      },
      {
        name: '作業結果',
        selector: 'workingResult',
        sortable: true,
        width: '120px',
        format: (row: WorkAdminDto) => workingResults[row.workingResult].label,
      },
      {
        name: '作業実施者',
        selector: 'workedByName',
        sortable: true,
        width: '120px',
      },
      {
        name: '作業実施日時',
        selector: 'workedAt',
        sortable: true,
        width: '130px',
        format: (row: WorkAdminDto) =>
          `${
            row.workedAt ? moment(row.workedAt).format('YYYY-MM-DD HH:mm') : ''
          }`,
      },
      {
        name: '検査結果',
        selector: 'confirmationResult',
        sortable: true,
        width: '90px',
        format: (row: WorkAdminDto) =>
          confirmationResults[row.confirmationResult].label,
      },
      {
        name: '検査実施者',
        selector: 'confirmedByName',
        sortable: true,
        width: '120px',
      },
      {
        name: '検査実施日時',
        selector: 'confirmedAt',
        sortable: true,
        width: '130px',
        format: (row: WorkAdminDto) =>
          `${
            row.confirmedAt
              ? moment(row.confirmedAt).format('YYYY-MM-DD HH:mm')
              : ''
          }`,
      },
      {
        name: '作業前写真',
        selector: 'beforePhoto',
        center: true,
        width: '120px',
        isExcludeCsv: true,
        cell: beforePhotoLink,
      },
      {
        name: '作業後写真',
        selector: 'afterPhoto',
        center: true,
        width: '120px',
        isExcludeCsv: true,
        cell: afterPhotoLink,
      },
    ];
  }, [afterPhotoLink, beforePhotoLink, editButton]);

  /**
   * CSVヘッダー
   */
  const csvHeader = useMemo(() => {
    return columns
      .filter(column => column.name && !column.isExcludeCsv)
      .map(column => {
        return {
          label: column.name,
          key: column.selector,
        };
      });
  }, [columns]);

  /**
   * CSVデータ
   */
  const csvData = useMemo(() => {
    return workInfoList.map(workInfo => {
      return {
        orderNo: workInfo.orderNo,
        ecOrderNo: workInfo.ecOrderNo,
        tagNo: workInfo.tagNo,
        clothingTypeName: workInfo.clothingTypeName,
        workType: workTypes[workInfo.workType].title,
        workContentName: workInfo.workContentName,
        inspectedByName: workInfo.inspectedByName,
        inspectedAt:
          workInfo.inspectedAt &&
          moment(workInfo.inspectedAt).format('YYYY-MM-DD HH:mm:ss'),
        workingResult: workingResults[workInfo.workingResult].label,
        workedByName: workInfo.workedByName,
        workedAt:
          workInfo.workedAt &&
          moment(workInfo.workedAt).format('YYYY-MM-DD HH:mm:ss'),
        confirmationResult:
          confirmationResults[workInfo.confirmationResult].label,
        confirmedByName: workInfo.confirmedByName,
        confirmedAt:
          workInfo.confirmedAt &&
          moment(workInfo.confirmedAt).format('YYYY-MM-DD HH:mm:ss'),
      };
    });
  }, [workInfoList]);

  return (
    <>
      <CustomDialog {...dialogProps} />
      <Box position="relative">
        <Grid container justify="flex-end" className={classes.buttonArea}>
          <Grid item>
            <Box mb={1}>
              <ActionButton
                color="primary"
                data-cy="search-button"
                fullWidth
                size="small"
              >
                <CSVLink
                  filename="作業情報.csv"
                  headers={csvHeader}
                  data={csvData}
                  className={classes.csvLink}
                >
                  CSV出力
                </CSVLink>
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container data-cy="workList-dataTable">
        <DataTable
          columns={columns}
          data={workInfoList}
          fixedHeader
          fixedHeaderScrollHeight="calc(100vh - 540px)"
          striped
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          customStyles={customStyles}
          pagination
          paginationTotalRows={allCount}
          paginationPerPage={perPage}
          paginationComponent={CustomPagination}
          paginationServer
        />
      </Grid>

      <WorkEditDialog
        open={isOpenWorkDialog}
        handleClose={closeWorkDialog}
        workId={selectedWorkId}
      />
    </>
  );
};
export default WorkInfoTable;
