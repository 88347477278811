import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ReadLabelPage from './ReadLabel/ReadLabelPage';
import RegistOrderPage from './RegistOrder/RegistOrderPage';
import ReadTagPage from './ReadTag/ReadTagPage';
import RegistClothingPage from './RegistClothing/RegistClothingPage';
import RegistWorkPage from './RegistWork/RegistWorkPage';
import RegistResultPage from './RegistResult/RegistResultPage';
import WorkListPage from './WorkList/WorkListPage';

const Inspection: FC<{}> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/readlabel`}>
        <ReadLabelPage />
      </Route>
      <Route path={`${match.path}/registorder`}>
        <RegistOrderPage />
      </Route>
      <Route path={`${match.path}/readtag`}>
        <ReadTagPage />
      </Route>
      <Route path={`${match.path}/registclothing`}>
        <RegistClothingPage />
      </Route>
      <Route path={`${match.path}/registwork`}>
        <RegistWorkPage />
      </Route>
      <Route path={`${match.path}/worklist`}>
        <WorkListPage />
      </Route>
      <Route path={`${match.path}/registresult`}>
        <RegistResultPage />
      </Route>
    </Switch>
  );
};

export default Inspection;
