import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CustomCard from 'components/common/CustomCard';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import { workTypes, NO_IMAGE } from 'app/constants';
import { WorkType, WorkSide } from 'api/works/works.dto';
import { ClothingTypeDto } from 'api/clothingTypes/clothingTypes.dto';
import { fabric } from 'fabric';

export const WORKAREA_WIDTH = 456;
export const WORKAREA_HEIGHT = 376;
export const WORKAREA_IMAGE_WIDTH = 296;
export const WORKAREA_IMAGE_TOP = 50;
export const WORKAREA_IMAGE_LEFT = 80;
export const WORKAREA_CIRCLE_RADIUS = 30;
export const WORKAREA_CIRCLE_STROKE_WIDTH = 6.5;

const a11yProps = (value: number) => ({
  id: `simple-tab-${value}`,
  'aria-controls': `simple-tabpanel-${value}`,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      flexGrow: 1,
      borderBottom: `1px solid${theme.palette.divider}`,
    },
    tab: {
      minWidth: '50%',
      flexGrow: 1,
      fontSize: theme.typography.h3.fontSize,
    },
    disabled: {
      pointerEvents: 'none',
    },
    guide: {
      paddingTop: '16px',
      paddingBottom: '16px',
      fontWeight: 700,
      color: theme.palette.text.secondary,
    },
    '@keyframes newAnimation': {
      '0%': { opacity: '0', transform: 'scale(1.3)' },
      '100%': { opacity: '1', transform: 'scale(1.0)' },
    },
    iconButton: {
      fontSize: '3rem',
    },
    adminIcon: {
      fontSize: '1.8rem',
    },
  }),
);

export const createCircle = (
  point: { x: number; y: number },
  workType?: WorkType,
) => {
  return new fabric.Circle({
    radius: WORKAREA_CIRCLE_RADIUS,
    fill: '',
    left: point.x,
    top: point.y,
    stroke: workType ? workTypes[workType].mainColor : '#000',
    strokeWidth: WORKAREA_CIRCLE_STROKE_WIDTH,
    originX: 'center',
    originY: 'center',
  });
};

const fontFamily = [
  '游ゴシック Medium',
  'Yu Gothic Medium',
  '游ゴシック体',
  'YuGothic',
  'ヒラギノ角ゴ ProN W3',
  'Hiragino Kaku Gothic ProN',
  'メイリオ',
  'Meiryo',
  'verdana',
].join(',');

export const setBackgroundText = (
  canvas: fabric.Canvas | fabric.StaticCanvas,
) => {
  const textOptions = {
    fontSize: 35,
    fontWeight: 500,
    fontFamily,
  };
  for (let i = 1; i <= 8; i += 1) {
    canvas.add(
      new fabric.Text(`${i}`, {
        ...textOptions,
        left: Math.floor((i - 1) / 4) * 374 + 32,
        top: ((i - 1) % 4) * 64 + 130,
      }),
    );
  }
  canvas.add(
    new fabric.Text('裏地', {
      ...textOptions,
      left: 16,
      top: 80,
      fontSize: 30,
    }),
  );
  canvas.add(
    new fabric.Text('全体', {
      ...textOptions,
      left: 16,
      top: 30,
      fontSize: 30,
    }),
  );
};

interface PropsType {
  clothingType?: ClothingTypeDto;
  workType?: WorkType;
  workSide?: WorkSide | null;
  workLocation?: string | null;
  setWorkSide?: (workSide: WorkSide) => void;
  setWorkLocation?: (workLocation?: string | null) => void;
  readOnly?: boolean;
  isAdmin?: boolean;
}

const ClothingImage: FC<PropsType> = ({
  clothingType,
  workType,
  workSide,
  workLocation: propsWorkLocation,
  setWorkSide: setOwnerWorkSide,
  setWorkLocation: setOwnerWorkLocation,
  readOnly,
  isAdmin,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [workLocation, setWorkLocation] = useState<any>();
  const workAreaRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabric.Canvas | fabric.StaticCanvas>();

  const workSideIndex = workSide === WorkSide.Back ? 1 : 0;

  useEffect(() => {
    if (!canvasRef.current && workAreaRef.current) {
      const htmlCanvas = document.getElementById('canvas') as HTMLCanvasElement;
      htmlCanvas.width = workAreaRef.current.clientWidth;
      htmlCanvas.height = htmlCanvas.width * (WORKAREA_HEIGHT / WORKAREA_WIDTH);
      if (!readOnly) {
        const canvas = new fabric.Canvas('canvas');
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width = 8;
        canvasRef.current = canvas;
      } else {
        canvasRef.current = new fabric.StaticCanvas('canvas');
      }
      canvasRef.current.setZoom(htmlCanvas.width / WORKAREA_WIDTH);
    }
  }, [readOnly, setOwnerWorkLocation]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      if (!readOnly) {
        canvas.freeDrawingBrush.color = workType
          ? workTypes[workType].mainColor
          : '#000';

        let mouseDownPointer: fabric.Point | undefined;

        canvas.on('mouse:down', (event: fabric.IEvent) => {
          mouseDownPointer = event.absolutePointer;
        });

        canvas.on('mouse:up', (event: fabric.IEvent) => {
          if (event.absolutePointer && mouseDownPointer) {
            if (
              Math.abs(event.absolutePointer.x - mouseDownPointer.x) < 5 &&
              Math.abs(event.absolutePointer.y - mouseDownPointer.y) < 5
            ) {
              const object = canvas.getObjects()[
                canvas.getObjects().length - 1
              ] as fabric.Path;
              if (object.path && object.path.length <= 2) {
                canvas.remove(object);
                canvas.add(createCircle(event.absolutePointer, workType));
              }
            }
          }
          if (setOwnerWorkLocation) {
            const location = canvas.toDatalessObject();
            // 衣類イメージは削除して保存
            delete location.backgroundImage;
            location.objects = location.objects.filter(
              // 数字とかの文字は削除して保存
              (obj: fabric.Object) => obj.type !== 'text',
            );
            setOwnerWorkLocation(JSON.stringify(location));
          }
        });

        return () => {
          canvas.off('mouse:down');
          canvas.off('mouse:up');
        };
      }
    }

    return undefined;
  }, [readOnly, setOwnerWorkLocation, workType]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      fabric.Image.fromURL(
        workSide === WorkSide.Front
          ? clothingType?.clothingTypePictureFront || NO_IMAGE
          : clothingType?.clothingTypePictureBack || NO_IMAGE,
        (img: fabric.Image) => {
          img.scaleToWidth(WORKAREA_IMAGE_WIDTH);
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            left: WORKAREA_IMAGE_LEFT,
            top: clothingType ? WORKAREA_IMAGE_TOP : -32,
          });
          setBackgroundText(canvas);
        },
      );
    }
  }, [clothingType, workSide]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      if (workLocation) {
        if (workLocation.x) {
          canvas.add(
            createCircle(
              {
                x: workLocation.x * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_LEFT,
                y: workLocation.y * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_TOP,
              },
              workType,
            ),
          );
        } else {
          canvas.loadFromDatalessJSON(
            workLocation,
            canvas.renderAll.bind(canvas),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workLocation]);

  useEffect(() => {
    setWorkLocation(propsWorkLocation && JSON.parse(propsWorkLocation));
  }, [propsWorkLocation]);

  /**
   * 作業箇所削除
   */
  const removeWorkLocation = useCallback(() => {
    if (!readOnly && setOwnerWorkLocation) {
      if (canvasRef.current) {
        const canvas = canvasRef.current;

        canvas.getObjects().forEach(object => {
          if (object.type !== 'text') {
            // 数字とかの文字は残して削除
            canvas.remove(object);
          }
        });
      }
      setOwnerWorkLocation(null);
    }
  }, [readOnly, setOwnerWorkLocation]);

  /**
   * 作業箇所 1つ前の状態に戻す
   */
  const undoWorkLocation = useCallback(() => {
    if (!readOnly && setOwnerWorkLocation) {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const object = canvas.getObjects()[
          canvas.getObjects().length - 1
        ] as fabric.Path;

        // 取得したオブジェクトの種類が数字とか文字の場合は、処理は実行しない
        if (object.type !== 'text') {
          // 数字とかの文字は残して削除
          canvas.remove(object);
          const location = canvas.toDatalessObject();
          // 衣類イメージは削除して保存
          delete location.backgroundImage;
          location.objects = location.objects.filter(
            // 数字とかの文字は削除して保存
            (obj: fabric.Object) => obj.type !== 'text',
          );
          setOwnerWorkLocation(JSON.stringify(location));
        }
      }
    }
  }, [readOnly, setOwnerWorkLocation]);

  /**
   * 表裏変更
   * @param event
   * @param newWorkSideIndex
   */
  const changeWorkSide = useCallback(
    (event: React.ChangeEvent<{}>, newWorkSideIndex: number) => {
      const newWorkSide =
        newWorkSideIndex === 0 ? WorkSide.Front : WorkSide.Back;
      if (workSide !== newWorkSide) {
        removeWorkLocation();
        if (setOwnerWorkSide) {
          setOwnerWorkSide(
            newWorkSideIndex === 0 ? WorkSide.Front : WorkSide.Back,
          );
        }
      }
    },
    [removeWorkLocation, setOwnerWorkSide, workSide],
  );

  return (
    <CustomCard>
      <Box>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={workSideIndex}
          onChange={changeWorkSide}
          className={classes.tabs}
        >
          <Tab
            label="表面"
            {...a11yProps(0)}
            className={`${classes.tab} ${readOnly && classes.disabled}`}
            data-cy="front-tab"
          />
          <Tab
            label="裏面"
            {...a11yProps(1)}
            className={`${classes.tab} ${readOnly && classes.disabled}`}
            data-cy="back-tab"
          />
        </Tabs>

        <Box>
          <Box position="absolute" width="100%">
            <Typography
              variant="h3"
              align="center"
              className={classes.guide}
              data-cy="work-area-label"
            >
              {readOnly || isAdmin
                ? '作業箇所'
                : '作業箇所をタップしてください。'}
            </Typography>
          </Box>

          <Box position="relative">
            <div
              ref={workAreaRef}
              style={{ width: '100%' }}
              data-cy="work-area"
            >
              <canvas id="canvas" />
            </div>
            {!readOnly && (
              <>
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  data-cy="delete-icon"
                >
                  <IconButton onClick={removeWorkLocation}>
                    <DeleteIcon
                      className={`${classes.iconButton} ${isAdmin &&
                        classes.adminIcon}`}
                    />
                  </IconButton>
                </Box>
                <Box
                  position="absolute"
                  top={isAdmin ? '40px' : '50px'}
                  right="0"
                  data-cy="undo-icon"
                >
                  <IconButton onClick={undoWorkLocation}>
                    <UndoIcon
                      className={`${classes.iconButton} ${isAdmin &&
                        classes.adminIcon}`}
                    />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};
export default ClothingImage;
