import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Box,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  UserInfoDto,
  CreateUserInfoDto,
  UpdateUserInfoDto,
  UserConditions,
} from 'api/users/users.dto';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import ActionButton from 'components/Admin/common/ActionButton';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import {
  endLoading,
  startLoading,
  openSnackbar,
  createErrorObject,
  failLoading,
} from 'modules/commonModule';
import { useForm } from 'react-hook-form';
import {
  createUser,
  updateUser,
  deleteUser,
  getUsers,
} from 'api/users/users.api';
import { workProcesses } from 'app/constants';
import { Auth } from 'aws-amplify';
import { getUserInfoList } from 'modules/mastersModule';
import { useHistory } from 'react-router-dom';
import CustomTextField from '../common/CustomTextField';
import CustomToggleButtons from '../common/CustomToggleButtons';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContentArea: {
      margin: 0,
    },
    cancelBtn: {
      width: '10%',
      minWidth: '120px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    toggleGroup: {
      width: '100%',
    },
  }),
);

/**
 * material-uiコンポーネントの既存スタイルに上書き
 */
const StyledIconButton = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(IconButton);

// ToggleButton用 データリスト
const workProcesDataList = [
  {
    value: WorkProcess.Inspection,
    buttonLabel: workProcesses[WorkProcess.Inspection].title,
    dataCy: 'dialog-inspection-button',
  },
  {
    value: WorkProcess.Repair,
    buttonLabel: workProcesses[WorkProcess.Repair].title,
    dataCy: 'dialog-repair-button',
  },
  {
    value: WorkProcess.StainRemoval,
    buttonLabel: workProcesses[WorkProcess.StainRemoval].title,
    dataCy: 'dialog-stainRemoval-button',
  },
  {
    value: WorkProcess.Confirmation,
    buttonLabel: workProcesses[WorkProcess.Confirmation].title,
    dataCy: 'dialog-confirmation-button',
  },
  {
    value: WorkProcess.Admin,
    buttonLabel: workProcesses[WorkProcess.Admin].title,
    dataCy: 'dialog-admin-button',
  },
];

type FormData = {
  userId: string;
  userName: string;
  password: string;
  workProcess: WorkProcess;
};
type UserEditDialogProps = {
  open: boolean;
  handleClose: (reloadFlg: boolean) => void;
  selectedUserId: string | null;
};
const UserEditDialog: FC<UserEditDialogProps> = ({
  open,
  handleClose,
  selectedUserId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { openDialog, dialogProps } = useDialog();
  const { register, handleSubmit, errors, setValue } = useForm<FormData>();
  const [selectedWorkProcess, setSelectedWorkProcess] = useState<WorkProcess>(
    WorkProcess.Inspection,
  );

  /**
   * ダイアログを閉じる
   */
  const onClose = useCallback(
    (reloadFlg: boolean) => {
      handleClose(reloadFlg);

      // ダイアログをブラウザバックで閉じる対応
      history.goBack();
    },
    [handleClose, history],
  );

  // ブラウザバックでダイアログを閉じる
  useEffect(() => {
    if (open) {
      window.onpopstate = () => {
        handleClose(false);
      };
    }
  }, [open, handleClose]);

  /**
   * サブミット処理 */
  const onSubmit = handleSubmit(async ({ userId, userName, password }) => {
    try {
      dispatch(startLoading());
      // データテーブルからユーザー情報が渡されている場合は更新処理、
      // 渡されていない場合は新規登録処理を行う
      if (selectedUserId) {
        const loginUser = await Auth.currentAuthenticatedUser();

        // ログイン中の作業者情報を変更しようとした場合は、変更処理を中断。
        if (loginUser.username === userId) {
          openDialog({
            title: '作業者登録でエラーが発生しました。',
            content: '現在ログインしている作業者情報は変更できません。',
          });

          return;
        }
        const updateUserInfoDto: UpdateUserInfoDto = {
          userName,
          password,
          workProcess: selectedWorkProcess,
        };

        // パスワードが入力されていなかった場合は、パスワードの更新はしない
        if (password === '') {
          delete updateUserInfoDto.password;
        }

        // ユーザー情報更新
        await updateUser(userId, updateUserInfoDto);

        dispatch(getUserInfoList(true));

        dispatch(
          openSnackbar({
            text: '作業者情報を変更しました。',
          }),
        );
      } else {
        const createUserInfoDto: CreateUserInfoDto = {
          userId,
          userName,
          password,
          workProcess: selectedWorkProcess,
        };

        // ユーザー情報登録
        const user = await createUser(createUserInfoDto);
        if (!user) {
          openDialog({
            title: '作業者登録でエラーが発生しました。',
            content: '既に使用されている作業者IDは登録できません。',
          });

          return;
        }

        dispatch(getUserInfoList(true));

        dispatch(
          openSnackbar({
            text: '作業者情報を登録しました。',
          }),
        );
      }

      // ダイアログを閉じる
      onClose(true);
    } catch (e) {
      openDialog({
        title: '作業者情報の登録でエラーが発生しました。',
        content: (
          <>
            code:{e.code}
            <br />
            name:{e.name}
            <br />
            message:{e.message}
          </>
        ),
      });
    } finally {
      dispatch(endLoading());
    }
  });

  /**
   * 削除実行
   */
  const executeDelete = useCallback(
    async (targetUserId: string) => {
      if (targetUserId) {
        const loginUser = await Auth.currentAuthenticatedUser();
        if (loginUser.username === targetUserId) {
          openDialog({
            title: '作業者登録でエラーが発生しました。',
            content: '現在ログインしている作業者情報は削除できません。',
          });
        } else {
          try {
            dispatch(startLoading());
            await deleteUser(targetUserId);
            dispatch(endLoading());
            dispatch(
              openSnackbar({
                text: '作業者情報を削除しました。',
              }),
            );

            dispatch(getUserInfoList(true));

            // ダイアログを閉じる
            onClose(true);
          } catch (e) {
            dispatch(
              failLoading(
                createErrorObject('作業者情報を削除できませんでした。', e),
              ),
            );
          }
        }
      }
    },
    [dispatch, onClose, openDialog],
  );

  /**
   * ラジオボタン選択値をstateにセット
   */
  const handleWorkProcess = useCallback((workProcess: WorkProcess) => {
    // ボタンの未選択状態を許可しない
    if (workProcess !== null) {
      setSelectedWorkProcess(workProcess);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (open) {
        try {
          dispatch(startLoading());

          if (selectedUserId) {
            // 作業者情報を取得
            const userConditions: UserConditions = {
              userId: selectedUserId,
            };
            const userInfo = await getUsers(userConditions);

            if (userInfo.length) {
              setSelectedWorkProcess(userInfo[0].workProcess);

              // 入力フォームの初期値設定
              Object.keys(userInfo[0]).forEach(key => {
                const value = userInfo[0][key as keyof UserInfoDto];
                setValue(key, value);
              });
            }
          } else {
            setSelectedWorkProcess(WorkProcess.Inspection);
          }

          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('作業者情報を取得できませんでした。', e, true),
            ),
          );
        }
      }
    })();
  }, [dispatch, open, selectedUserId, setValue]);

  return (
    <>
      <CustomDialog {...dialogProps} />
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        scroll="paper"
        maxWidth="sm"
        data-cy="userEdit-dialog"
      >
        <Grid container justify="space-between">
          <DialogTitle
            id="scroll-dialog-title"
            className={classes.dialogTitle}
            disableTypography
          >
            <Typography
              component="h2"
              variant="h2"
              data-cy="work-content-dialog-title-label"
            >
              作業者の編集
            </Typography>
          </DialogTitle>
          <DialogActions>
            <StyledIconButton
              onClick={() => onClose(false)}
              data-cy="userEdit-dialog-close-button"
            >
              <CancelIcon />
            </StyledIconButton>
          </DialogActions>
        </Grid>
        <DialogContent dividers>
          <form onSubmit={onSubmit} noValidate>
            <Grid
              container
              item
              xs={12}
              spacing={3}
              justify="space-between"
              className={classes.dialogContentArea}
            >
              <Grid item xs={8}>
                <CustomTextField
                  name="userId"
                  label="作業者ID"
                  disabled={!!selectedUserId}
                  autoFocus
                  autoComplete="off"
                  inputProps={{
                    maxLength: 20,
                    'data-cy': 'dialog-userId-input',
                  }}
                  inputRef={register({
                    required: '作業者IDを入力してください。',
                  })}
                  helperText={errors.userId ? errors.userId.message : ''}
                  error={!!errors.userId}
                />
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  name="userName"
                  label="作業者名"
                  autoFocus={!!selectedUserId}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 100,
                    'data-cy': 'dialog-userName-input',
                  }}
                  inputRef={register({
                    required: '作業者名を入力してください。',
                  })}
                  helperText={errors.userName ? errors.userName.message : ''}
                  error={!!errors.userName}
                />
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  name="password"
                  label="パスワード"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 20,
                    'data-cy': 'dialog-password-input',
                  }}
                  inputRef={register({
                    required: selectedUserId
                      ? false
                      : 'パスワードを入力してください。',
                    maxLength: {
                      value: 20,
                      message: '20文字以下で入力してください。',
                    },
                    minLength: {
                      value: 6,
                      message: '6文字以上で入力してください。',
                    },
                  })}
                  helperText={errors.password ? errors.password.message : ''}
                  error={!!errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomToggleButtons
                  label="担当工程"
                  value={selectedWorkProcess}
                  dataList={workProcesDataList}
                  exclusive
                  onChange={handleWorkProcess}
                />
              </Grid>
            </Grid>
            <Box textAlign="right" mt={4}>
              {selectedUserId ? (
                <>
                  <Box display="inline-block" width="130px" mr={2}>
                    <ActionButton
                      color="delete"
                      data-cy="dialog-delete-button"
                      fullWidth
                      type="button"
                      onClick={() => executeDelete(selectedUserId)}
                    >
                      削除
                    </ActionButton>
                  </Box>
                  <Box display="inline-block" width="130px">
                    <ActionButton
                      type="button"
                      color="primary"
                      data-cy="dialog-update-button"
                      fullWidth
                      onClick={onSubmit}
                    >
                      変更
                    </ActionButton>
                  </Box>
                </>
              ) : (
                <Box display="inline-block" width="130px">
                  <ActionButton
                    type="button"
                    color="primary"
                    data-cy="dialog-regist-button"
                    fullWidth
                    onClick={onSubmit}
                  >
                    登録
                  </ActionButton>
                </Box>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserEditDialog;
