import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Divider,
  TextField,
  Typography,
  Box,
  Theme,
} from '@material-ui/core';

import Photo from 'components/common/Photo';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setTitle,
  openSnackbar,
  startLoading,
  endLoading,
  failLoading,
  createErrorObject,
} from 'modules/commonModule';
import ActionButton from 'components/common/ActionButton';
import ContentsArea from 'components/common/ContentsArea';
import ButtonsArea from 'components/common/ButtonsArea';
import WorkArea from 'components/common/WorkArea';
import ClothingImage from 'components/common/ClothingImage';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  WorkType,
  WorkDto,
  UpdateWorkDto,
  WorkingResult,
  ConfirmationResult,
  WorkListDto,
} from 'api/works/works.dto';
import { workTypes, workingResults, confirmationResults } from 'app/constants';
import { getWork, getWorksByClothingId, updateWork } from 'api/works/works.api';
import WorkStatus from 'components/RegistResult/WorkStatus';
import WorkHistoryTable from 'components/RegistResult/WorkHistoryTable';
import {
  createWorkHistory,
  getWorkHistoriesByWorkId,
} from 'api/workHistories/workHistories.api';
import {
  WorkProcess,
  WorkHistoryDto,
} from 'api/workHistories/workHistories.dto';
import {
  isBase64Image,
  uploadS3Base64Image,
  removeS3Image,
} from 'utils/awsUtils';
import CustomDialog, { useDialog } from 'components/common/CustomDialog';
import { updateOrderEndedAt } from 'api/orders/orders.api';
import OrderNoField from 'components/common/OrderNoField';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(3),
    },
    container: {
      paddingLeft: '24px !important',
    },
    rightContents: {
      paddingLeft: '0 !important',
    },
  }),
);

type LocationState = {
  workProcess: WorkProcess;
  orderId?: string;
  workId: string;
  workType: WorkType;
};

const RegistResultPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation<LocationState>();
  const { workProcess, orderId, workId, workType } = location.state;
  const [workDto, setWorkDto] = useState<WorkDto>();
  const [notYetWorkListDto, setNotYetWorkListDto] = useState<WorkListDto[]>([]);
  const [beforePhoto, setBeforePhoto] = useState('');
  const [afterPhoto, setAfterPhoto] = useState('');
  const [workHistoryDtoList, setWorkHistoryDtoList] = useState<
    WorkHistoryDto[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const { openDialog, dialogProps } = useDialog();
  const workProcessName =
    workProcess === WorkProcess.Confirmation ? '検査' : '作業';

  const pageTitle = `${workProcessName}結果登録 - ${workTypes[workType].title}`;
  dispatch(setTitle(pageTitle));

  useEffect(() => {
    (async () => {
      // 作業Id指定 (作業を選択)
      try {
        dispatch(startLoading());
        const work = await getWork(workId);
        const workHistoryList = await getWorkHistoriesByWorkId(workId);
        setWorkDto(work);
        setBeforePhoto(work.beforePhoto || '');
        setAfterPhoto(work.afterPhoto || '');
        setIsLoading(false);
        setWorkHistoryDtoList(workHistoryList);

        // 検査工程の場合のみ、検査結果の連続登録のため未検査作業を取得する
        if (workProcess === WorkProcess.Confirmation) {
          const works = await getWorksByClothingId(work.clothingId);
          const notYetWorkList = works.filter(
            workItem =>
              workItem.confirmationResult === ConfirmationResult.NotYet &&
              workItem.workingResult !== WorkingResult.NotYet &&
              workItem.workId !== work.workId, // （現在表示中の作業情報は除く）
          );
          setNotYetWorkListDto(notYetWorkList);
        }

        dispatch(endLoading());
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject('作業情報を取得できませんでした。', e, true),
          ),
        );
      }
    })();
  }, [dispatch, workId, workProcess]);

  /**
   * 入力項目が変更されているか
   */
  const wasChanged = useCallback(() => {
    return (
      beforePhoto !== (workDto?.beforePhoto || '') ||
      afterPhoto !== (workDto?.afterPhoto || '')
    );
  }, [afterPhoto, beforePhoto, workDto]);

  type resultType = {
    workingResult?: WorkingResult;
    confirmationResult?: ConfirmationResult;
  };

  /**
   * 作業前・作業後写真のアップロード
   */
  const uploadPhotos = useCallback(async () => {
    let photosDto: UpdateWorkDto | undefined;

    const beforeFileName = `${workDto?.clothing.orderId}/${workDto?.clothingId}/${workId}-before`;
    if (isBase64Image(beforePhoto)) {
      const beforePhotoKey = await uploadS3Base64Image(
        beforePhoto,
        beforeFileName,
      );
      if (beforePhotoKey) {
        photosDto = { beforePhoto: beforePhotoKey };
      }
    } else if (
      beforePhoto === '' &&
      beforePhoto !== (workDto?.beforePhoto || '')
    ) {
      await removeS3Image(beforeFileName);
      photosDto = { beforePhoto: null };
    }

    const afterFileName = `${workDto?.clothing.orderId}/${workDto?.clothingId}/${workId}-after`;
    if (isBase64Image(afterPhoto)) {
      const afterPhotoKey = await uploadS3Base64Image(
        afterPhoto,
        afterFileName,
      );
      if (afterPhotoKey) {
        photosDto = { afterPhoto: afterPhotoKey, ...photosDto };
      }
    } else if (
      afterPhoto === '' &&
      afterPhoto !== (workDto?.afterPhoto || '')
    ) {
      await removeS3Image(afterFileName);
      photosDto = { afterPhoto: null, ...photosDto };
    }

    return photosDto;
  }, [afterPhoto, beforePhoto, workDto, workId]);

  /**
   * 作業結果登録
   */
  const registerResult = useCallback(
    async (result: resultType) => {
      try {
        dispatch(startLoading());

        // 日時更新対象の作業工程を判定
        const targetProcess =
          workProcess === WorkProcess.Confirmation ? 'confirmedAt' : 'workedAt';

        const updateWorkDto: UpdateWorkDto = {
          ...result,
          [targetProcess]: 'NOW()',
        };

        // 写真が登録されている場合はアップロードして更新する
        const uploadPhotosDto = await uploadPhotos();
        const work = await updateWork(workId, {
          ...updateWorkDto,
          ...uploadPhotosDto,
        });

        await createWorkHistory({
          workId: work.workId,
          workProcess:
            workProcess === WorkProcess.Confirmation
              ? workProcess
              : work.workType,
          workType: work.workType,
          workContentId: work.workContent?.workContentId,
          workSide: work.workSide,
          workLocation: work.workLocation,
          beforePhoto: work.beforePhoto,
          afterPhoto: work.afterPhoto,
          workingResult: work.workingResult,
          workedAt: work.workedAt,
          confirmationResult: work.confirmationResult,
          confirmedAt: work.confirmedAt,
        });
        if (workProcess === WorkProcess.Inspection) {
          await updateOrderEndedAt(workDto?.clothing.orderId);
        }
        dispatch(
          openSnackbar({ text: `${workProcessName}結果を登録しました。` }),
        );
        dispatch(endLoading());

        return work;
      } catch (e) {
        dispatch(
          failLoading(
            createErrorObject(
              `${workProcessName}結果を登録できませんでした。`,
              e,
            ),
          ),
        );
      }

      return undefined;
    },
    [dispatch, uploadPhotos, workDto, workId, workProcess, workProcessName],
  );

  /**
   * OK / NG
   */
  const workList = useCallback(
    async (result: resultType) => {
      const work = await registerResult(result);
      if (work) {
        if (workProcess === WorkProcess.Inspection) {
          history.push(`/${workProcess.toLowerCase()}/worklist`, {
            workProcess,
            orderId,
            clothingId: workDto?.clothingId,
            workType,
          });
        } else if (
          workProcess === WorkProcess.Confirmation &&
          notYetWorkListDto.length > 0
        ) {
          // 検査担当者かつ、まだ未検査の作業があれば続けて次の作業結果登録へ進む
          history.push(`/${workProcess.toLowerCase()}/registresult`, {
            workProcess,
            workType: notYetWorkListDto[0].workType,
            workId: notYetWorkListDto[0].workId, // 次の未検査作業のworkIdを渡す
          });
        } else {
          history.push(`/${workProcess.toLowerCase()}/registclothing`, {
            workProcess,
            tagNo: workDto?.clothing.tagNo,
          });
        }
      }
    },
    [
      history,
      notYetWorkListDto,
      orderId,
      registerResult,
      workDto,
      workProcess,
      workType,
    ],
  );

  /**
   * 戻る（検品ユーザー）
   */
  const backRegistWork = useCallback(() => {
    history.push(`/${workProcess.toLowerCase()}/registwork`, {
      workProcess,
      orderId,
      clothingId: workDto?.clothingId,
      workId,
      workType,
    });
  }, [history, orderId, workDto, workId, workProcess, workType]);

  /**
   * 戻る（修理・染み抜き・検査ユーザー）
   */
  const backRegistClothing = useCallback(() => {
    history.push(`/${workProcess.toLowerCase()}/registclothing`, {
      workProcess,
      clothingId: workDto?.clothingId,
      tagNo: workDto?.clothing.tagNo,
    });
  }, [history, workDto, workProcess]);

  /**
   * 戻るボタン押下時の確認ダイアログを表示
   */
  const openBackConfirmDialog = useCallback(() => {
    if (wasChanged()) {
      if (workProcess === WorkProcess.Inspection) {
        openDialog({
          title: '作業内容登録へ戻ります。',
          content: '入力中の内容を破棄し、作業内容登録へ戻りますか？',
          buttonTitle: '戻る',
          action: backRegistWork,
        });
      } else {
        openDialog({
          title: '衣類情報登録へ戻ります。',
          content: '入力中の内容を破棄し、衣類情報登録へ戻りますか？',
          buttonTitle: '戻る',
          action: backRegistClothing,
        });
      }
    } else if (workProcess === WorkProcess.Inspection) {
      backRegistWork();
    } else {
      backRegistClothing();
    }
  }, [backRegistClothing, backRegistWork, openDialog, wasChanged, workProcess]);

  return (
    <>
      <CustomDialog {...dialogProps} />
      <WorkArea>
        <ContentsArea>
          <Grid
            container
            spacing={5}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid container item xs={6} spacing={2}>
              <Grid container item xs={5} spacing={2}>
                <Grid item xs={12}>
                  <OrderNoField orderNo={workDto?.clothing.orderNo} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="tag_no"
                    label="タグ番号"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={
                      workDto?.clothing.tagNo ? workDto?.clothing.tagNo : ' '
                    }
                    inputProps={{
                      'data-cy': 'tag-no-input',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12}>
                  <TextField
                    label="作業内容"
                    variant="outlined"
                    fullWidth
                    value={workDto?.workContent?.workContentName || ''}
                    disabled
                    inputProps={{
                      'data-cy': 'work-content-input',
                    }}
                    data-cy="work-content-area"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {isLoading ? (
                  <></>
                ) : (
                  <ClothingImage
                    readOnly
                    clothingType={workDto?.clothing.clothingType}
                    workType={workType}
                    workSide={workDto?.workSide}
                    workLocation={workDto?.workLocation}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              container
              spacing={2}
              justify="flex-end"
              className={classes.rightContents}
            >
              <Grid
                item
                xs={12}
                container
                spacing={2}
                wrap="nowrap"
                justify="flex-end"
              >
                <Grid item xs={3}>
                  <Photo
                    caption="申込書"
                    image={workDto?.clothing?.order?.formPhoto}
                    readonly
                    level="protected"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    caption="指示書"
                    image={workDto?.clothing.instructionPhoto1}
                    readonly
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    caption="指示書"
                    image={workDto?.clothing.instructionPhoto2}
                    readonly
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    caption="作業前"
                    image={beforePhoto}
                    setImage={setBeforePhoto}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Photo
                    caption="作業後"
                    image={afterPhoto}
                    setImage={setAfterPhoto}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.container}>
                <Box mb={2} mt={1}>
                  <Typography component="p" variant="h3">
                    <Box component="span" fontWeight={600}>
                      作業状況
                    </Box>
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <WorkStatus
                  workingResult={workDto?.workingResult}
                  confirmationResult={workDto?.confirmationResult}
                  workType={workDto?.workType}
                />
              </Grid>
              <Grid item xs={12} className={classes.container}>
                <WorkHistoryTable workHistoryList={workHistoryDtoList} />
              </Grid>
            </Grid>
          </Grid>
        </ContentsArea>

        <ButtonsArea>
          <Grid item>
            {workProcess === WorkProcess.Confirmation ? (
              <>
                <ActionButton
                  onClick={() => {
                    workList({
                      confirmationResult: ConfirmationResult.Ok,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={
                    !workDto?.workingResult ||
                    workDto?.workingResult === WorkingResult.NotYet
                  }
                  data-cy="result-ok-button"
                >
                  {confirmationResults[ConfirmationResult.Ok].label}
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    workList({
                      confirmationResult: ConfirmationResult.Ng,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={
                    !workDto?.workingResult ||
                    workDto?.workingResult === WorkingResult.NotYet
                  }
                  data-cy="result-ng-button"
                >
                  {confirmationResults[ConfirmationResult.Ng].label}
                </ActionButton>
              </>
            ) : (
              <>
                <ActionButton
                  onClick={() => {
                    workList({
                      workingResult: WorkingResult.Perfect,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-perfect-button"
                >
                  {workingResults[WorkingResult.Perfect].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    workList({
                      workingResult: WorkingResult.Almost,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-almost-button"
                >
                  {workingResults[WorkingResult.Almost].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    workList({
                      workingResult: WorkingResult.Part,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-part-button"
                >
                  {workingResults[WorkingResult.Part].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    workList({
                      workingResult: WorkingResult.CouldNot,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-couldnot-button"
                >
                  {workingResults[WorkingResult.CouldNot].label}
                </ActionButton>

                <ActionButton
                  onClick={() => {
                    workList({
                      workingResult: WorkingResult.NotRequired,
                    });
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  data-cy="result-notrequired-button"
                >
                  {workingResults[WorkingResult.NotRequired].label}
                </ActionButton>
              </>
            )}
          </Grid>
          <Grid item>
            {(workProcess === WorkProcess.Repair ||
              workProcess === WorkProcess.StainRemoval ||
              workProcess === WorkProcess.Confirmation) && (
              <ActionButton
                onClick={backRegistWork}
                color="delete"
                endIcon={<CreateIcon />}
                data-cy="change-work-button"
              >
                作業情報変更
              </ActionButton>
            )}
            <ActionButton
              onClick={openBackConfirmDialog}
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              data-cy="back-button"
            >
              戻る
            </ActionButton>
          </Grid>
        </ButtonsArea>
      </WorkArea>
    </>
  );
};
export default RegistResultPage;
