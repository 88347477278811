import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setTitle,
  startLoading,
  failLoading,
  createErrorObject,
  endLoading,
} from 'modules/commonModule';
import ContentsArea from 'components/Admin/common/ContentsArea';
import {
  Box,
  Grid,
  makeStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core';
import ActionButton from 'components/Admin/common/ActionButton';
import { workingResults } from 'app/constants';
import { WorkType, WorkingResult } from 'api/works/works.dto';
import {
  ProgressDto,
  AggregateConditionsDto,
  ResultDto,
  ImplementationDto,
} from 'api/aggregate/aggregate.dto';
import {
  getResult,
  getProgress,
  getImplementation,
} from 'api/aggregate/aggregate.api';
import { colors } from 'components/common/Theme';
import moment from 'moment';
import PieGraph from './PieGraph';
import LineGraph from './LineGraph';
import BarGraph from './BarGraph';
import CustomTextField from '../common/CustomTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridFrom: {
      paddingRight: theme.spacing(1),
    },
    gridTo: {
      paddingLeft: theme.spacing(1),
    },
    pieChartLegend: {
      paddingLeft: theme.spacing(4),
      '&>div': {
        borderLeft: '4px solid',
        paddingLeft: theme.spacing(1),
        whiteSpace: 'nowrap',
      },
      '&>div:nth-of-type(1)': {
        borderColor: colors.workingResults.Perfect,
      },
      '&>div:nth-of-type(2)': {
        borderColor: colors.workingResults.Almost,
      },
      '&>div:nth-of-type(3)': {
        borderColor: colors.workingResults.Part,
      },
      '&>div:nth-of-type(4)': {
        borderColor: colors.workingResults.CouldNot,
      },
      '&>div:nth-of-type(5)': {
        borderColor: colors.workingResults.NotRequired,
      },
    },
  }),
);

const DashBoardPage: FC<{}> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [progressDto, setProgressDto] = useState<ProgressDto>();
  const [workResultDto, setWorkResultDto] = useState<ResultDto>();
  const [implementationDto, setImplementationDto] = useState<
    ImplementationDto
  >();
  const [conditions, setConditions] = useState<AggregateConditionsDto>({
    aggregateFrom: moment(
      new Date(new Date().getFullYear(), 0, 1),
    ).toISOString(),
    aggregateTo: moment(new Date()).toISOString(),
  });
  const [lineAreaWidth, setLineAreaWidth] = useState<number>(0);
  const lineAreaRef = useRef<HTMLInputElement | null>(null);

  // ページタイトル
  dispatch(setTitle('ダッシュボード'));

  /* 検索フォーム操作系
  -------------------------------------------------------------- */
  /**
   * 検索条件（日付フォーム）の選択値をstateにセット
   */
  const handleDateFromInput = useCallback(
    (name: string, value: string) => {
      // 日付フォームの未入力は許容しない
      if (value) {
        const date = moment(value)
          .startOf('day')
          .toISOString();
        setConditions({ ...conditions, [name]: date });
      }
    },
    [conditions],
  );

  const handleDateToInput = useCallback(
    (name: string, value: string) => {
      // 日付フォームの未入力は許容しない
      if (value) {
        const date = moment(value)
          .endOf('day')
          .toISOString();
        setConditions({ ...conditions, [name]: date });
      }
    },
    [conditions],
  );

  /**
   * 集計情報検索
   */
  const search = useCallback(
    (condition?: AggregateConditionsDto) => {
      (async () => {
        try {
          dispatch(startLoading());

          // 工程別 作業進捗状況を取得
          const progress = await getProgress(condition);
          setProgressDto(progress);

          // 工程別 作業結果状況を取得
          const workResult = await getResult(condition);
          setWorkResultDto(workResult);

          // 日別工程別 作業実施状況を取得
          const implementation = await getImplementation(condition);
          setImplementationDto(implementation);

          dispatch(endLoading());
        } catch (e) {
          dispatch(
            failLoading(
              createErrorObject('集計情報を取得できませんでした。', e),
            ),
          );
        }
      })();
    },
    [dispatch],
  );

  /**
   * 折れ線グラフエリアの横幅を取得
   * ここで取得した横幅を折れ線グラフの横幅として指定する
   */
  const setLineGraphWidth = useCallback(() => {
    const offsetWidth = lineAreaRef.current?.offsetWidth;

    if (offsetWidth) {
      setLineAreaWidth(offsetWidth);
    }
  }, []);

  /**
   * 画面幅の変動時に実行する処理
   */
  window.onresize = setLineGraphWidth;

  useEffect(() => {
    search(); // 注文情報の検索処理を実行
    setLineGraphWidth(); // 折れ線グラフコンポーネントへ指定するための横幅の取得
  }, [setLineGraphWidth, search]);

  return (
    <>
      <ContentsArea>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Box
              p={3}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={2}
              height="100%"
            >
              <Box display="inline-block">
                <Grid container alignItems="center" spacing={2}>
                  <Grid item container xs={11} justify="space-between">
                    <Grid item xs={6} className={classes.gridFrom}>
                      <CustomTextField
                        name="aggregateFrom"
                        label="集計対象期間"
                        value={moment(conditions.aggregateFrom).format(
                          'YYYY-MM-DD',
                        )}
                        type="date"
                        onChange={handleDateFromInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: moment(conditions.aggregateTo).format(
                            'YYYY-MM-DD',
                          ),
                          'data-cy': 'aggregateFrom-input',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridTo}>
                      <CustomTextField
                        name="aggregateTo"
                        value={moment(conditions.aggregateTo).format(
                          'YYYY-MM-DD',
                        )}
                        type="date"
                        onChange={handleDateToInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: moment(conditions.aggregateFrom).format(
                            'YYYY-MM-DD',
                          ),
                          max: '9999-12-31',
                          'data-cy': 'aggregateTo-input',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Box mt={1}>
                      <ActionButton
                        color="primary"
                        data-cy="search-button"
                        fullWidth
                        onClick={() => search(conditions)}
                      >
                        更新
                      </ActionButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} xl={7}>
            <Box
              p={3}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={1}
              height="100%"
            >
              <Box mb={4}>
                <Typography component="h2" variant="h4">
                  <Box component="span" fontWeight={600}>
                    作業進捗状況
                  </Box>
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={6} xl={3}>
                  <BarGraph inspectionProgress={progressDto?.inspection} />
                </Grid>
                <Grid item xs={6} xl={3}>
                  <BarGraph
                    workType={WorkType.LintRemoval}
                    workProgress={progressDto?.lintRemoval}
                  />
                </Grid>
                <Grid item xs={6} xl={3}>
                  <BarGraph
                    workType={WorkType.Repair}
                    workProgress={progressDto?.repair}
                  />
                </Grid>
                <Grid item xs={6} xl={3}>
                  <BarGraph
                    workType={WorkType.StainRemoval}
                    workProgress={progressDto?.stainRemoval}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={6} xl={5}>
            <Box
              px={3}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={2}
              height="100%"
            >
              <Grid container>
                <Grid item xs={4}>
                  <Box pt={3} mb={2}>
                    <Typography component="h2" variant="h4">
                      <Box component="span" fontWeight={600}>
                        作業結果状況
                      </Box>
                    </Typography>
                  </Box>
                  <Box className={classes.pieChartLegend}>
                    <Box my={1}>
                      {workingResults[WorkingResult.Perfect].label}
                    </Box>
                    <Box mb={1}>
                      {workingResults[WorkingResult.Almost].label}
                    </Box>
                    <Box mb={1}>{workingResults[WorkingResult.Part].label}</Box>
                    <Box mb={1}>
                      {workingResults[WorkingResult.CouldNot].label}
                    </Box>
                    <Box>{workingResults[WorkingResult.NotRequired].label}</Box>
                  </Box>
                </Grid>
                <Grid container item xs={8} justify="center">
                  <Grid item xs={12} lg={12}>
                    <Box mt={2}>
                      <PieGraph
                        workType={WorkType.LintRemoval}
                        workingResult={workResultDto?.lintRemoval}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieGraph
                      workType={WorkType.Repair}
                      workingResult={workResultDto?.repair}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieGraph
                      workType={WorkType.StainRemoval}
                      workingResult={workResultDto?.stainRemoval}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              p={3}
              borderRadius={5}
              bgcolor="#fff"
              boxShadow={2}
              height="100%"
            >
              <div ref={lineAreaRef}>
                <Box mb={4}>
                  <Typography component="h2" variant="h4">
                    <Box component="span" fontWeight={600}>
                      作業実施状況
                    </Box>
                  </Typography>
                </Box>
                <LineGraph
                  implementation={implementationDto}
                  lineAreaWidth={lineAreaWidth}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </ContentsArea>
    </>
  );
};

export default DashBoardPage;
