import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { useHistory } from 'react-router-dom';
import { clearError } from 'modules/commonModule';
import { uploadErrorLog } from 'utils/awsUtils';
import { Typography } from '@material-ui/core';
import CustomDialog from './CustomDialog';

const ErrorDialog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, errorCount, title } = useSelector(
    (state: RootState) => state.common,
  );
  const [errorObject, setErrorObject] = useState<{
    message: string;
    summary?: string;
    detail?: string;
    wasLogout?: boolean;
  }>();

  useEffect(() => {
    (async () => {
      if (error) {
        if (error.summary === 'not authenticated') {
          // 認証エラー
          setErrorObject({
            message: 'ログインされていません。もう一度ログインしてください。',
            summary: '一定時間経過で自動的にログアウトされます。',
            wasLogout: true,
          });

          return;
        }

        let errorId: string | undefined;
        if (errorCount <= 5) {
          // エラーが連続5回以下の場合、S3にエラーログをアップロードする
          errorId = await uploadErrorLog(title, error);
        }
        setErrorObject({
          message: error.message,
          summary: error.summary,
          detail: `${errorId}`,
        });
      } else {
        setErrorObject(undefined);
      }
    })();
  }, [error, errorCount, title]);

  const closeErrorDialog = useCallback(() => {
    if (errorObject?.wasLogout === true) {
      window.location.href = '/';

      return;
    }
    if (error?.isGoBack) {
      history.goBack();
    }
    dispatch(clearError());
  }, [dispatch, error, errorObject, history]);

  return (
    <CustomDialog
      isOpen={!!errorObject}
      setIsOpen={() => undefined}
      title="エラーが発生しました。"
      content={
        <>
          {errorObject?.message}
          <br />
          <br />
          <Typography variant="caption">
            {errorObject?.summary}
            <br />
            {errorObject?.detail}
          </Typography>
        </>
      }
      closeAction={closeErrorDialog}
    />
  );
};

export default ErrorDialog;
