import React, { FC, useCallback, useState } from 'react';
import {
  Grid,
  Box,
  Theme,
  makeStyles,
  createStyles,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { WorkProcess } from 'api/workHistories/workHistories.dto';
import { colors } from 'components/common/Theme';
import Header from 'components/Admin/common/Header';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      minWidth: '1080px',
    },
    leftArea: {
      minHeight: '100vh',
      width: '230px',
      flexBasis: '230px',
      transition: 'all .1s ease-in',
      backgroundColor: colors.admin.sideBar.main,
      position: 'fixed',
      left: 0,
    },
    closeLeftArea: {
      left: '-230px',
    },
    closeLeftAreaIcon: {
      transition: 'all .1s',
      transform: 'rotate(180deg)',
    },
    logoImage: {
      display: 'block',
      width: '100%',
    },
    navItem: {
      cursor: 'pointer',
      transition: 'all .1s ease-in',
      backgroundColor: colors.admin.sideBar.main,
      borderRadius: 0,
      display: 'block',
      '&:hover': {
        backgroundColor: colors.admin.sideBar.light,
      },
      '& p': {
        color: theme.palette.common.white,
        textAlign: 'left',
      },
    },
    rightArea: {
      minHeight: '100vh',
      width: 'calc(100% - 230px)',
      flexBasis: 'calc(100% - 230px)',
      transition: 'all .1s ease-in',
      marginLeft: 'auto',
    },
    openRightArea: {
      width: '100%',
      flexBasis: '100%',
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    iconButton: {
      position: 'absolute',
      top: '100px',
      right: '-35px',
      width: '35px',
      height: '50px',
      backgroundColor: colors.admin.sideBar.main,
      borderRadius: '0 5px 5px 0',
      '&:hover': {
        backgroundColor: colors.admin.sideBar.light,
      },
    },
    arrowIcon: {
      color: theme.palette.common.white,
    },
  }),
);

type LocationState = {
  workProcess: WorkProcess;
};
const ContentsArea: FC<{}> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [isOpenToggleArea, setIsOpenToggleArea] = useState(true);

  // 現在ページのディレクトリ名を取得
  const path = location.pathname.split('/');
  const dir = path[path.length - 1];

  /**
   * メニューリンク押下時の処理
   */
  const nextPage = useCallback(
    (param: string) => {
      history.push(`/admin/${param}`);
    },
    [history],
  );

  /**
   * サイドバー開閉処理
   */
  const toggleArea = useCallback(() => {
    setIsOpenToggleArea(!isOpenToggleArea);
  }, [isOpenToggleArea]);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        className={`${classes.leftArea} ${isOpenToggleArea ||
          classes.closeLeftArea}`}
        data-cy="leftArea"
      >
        <IconButton
          onClick={toggleArea}
          className={classes.iconButton}
          data-cy="toggle-button"
        >
          <ArrowBackIosOutlinedIcon
            className={`${classes.arrowIcon} ${isOpenToggleArea ||
              classes.closeLeftAreaIcon}`}
          />
        </IconButton>
        <Box textAlign="center" mt={8} mb={10} mx="auto" width="120px">
          <img
            src="/logo_rinavis.png"
            alt="Logo"
            className={classes.logoImage}
            data-cy="sideBar-logo-image"
          />
        </Box>
        <Box>
          {[
            {
              title: 'ダッシュボード',
              param: 'dashboard',
              dataCy: 'dashboard-button',
            },
            {
              title: '注文検索/作業検索',
              param: 'ordersearch',
              dataCy: 'ordersearch-button',
            },
            {
              title: '作業者(アプリユーザー)<br />登録･変更･削除',
              param: 'registeruser',
              dataCy: 'registeruser-button',
            },
            {
              title: 'メール関連設定',
              param: 'customermail',
              dataCy: 'customermail-button',
            },
          ].map(item => (
            <Box key={item.param}>
              <Button
                fullWidth
                onClick={() => nextPage(item.param)}
                className={`${classes.navItem} ${dir === item.param &&
                  classes.active}`}
                data-cy={item.dataCy}
              >
                <Box pt={1} pb={1} pl={3} textAlign="left">
                  <Typography component="p" variant="h5">
                    <Box component="span">
                      <span
                        // 文字列に含まれる<br>タグを認識させるため、文字列をHTMLに変換
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      />
                    </Box>
                  </Typography>
                </Box>
              </Button>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid
        item
        className={`${classes.rightArea} ${isOpenToggleArea ||
          classes.openRightArea}`}
        data-cy="rightArea"
      >
        <Header />
        <Box pl={5}>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default ContentsArea;
